import { CSSObject, Flex, Group, MantineTheme } from "@mantine/core";
import { LegendOrdinal } from "@visx/legend";
import { ScaleOrdinal } from "d3-scale";
import {
    ChartDimensions,
    INACTIVE_LEGEND_OPACITY,
    LegendDotWithLabel,
    LegendLineWithLabel,
} from "../utils";
import { SeriesType, TimeSeriesSeries } from "./time-series-chart";
import { ReactElement } from "react";

type TimeSeriesChartLegendProps<T extends string> = {
    dimensions: ChartDimensions;
    colorScale: ScaleOrdinal<T, string, never>;
    activeSeriesKeys: T[];
    allSeries: TimeSeriesSeries<T>[];
    toggleActiveSeries: (label: string) => void;
    justifyLegend: "start" | "center" | "end";
};

export const Legend = <T extends string>({
    colorScale,
    activeSeriesKeys,
    allSeries,
    toggleActiveSeries,
    justifyLegend,
}: TimeSeriesChartLegendProps<T>): ReactElement => {
    return (
        <Flex w="100%" mt="-2rem" justify={justifyLegend} px={8}>
            <LegendOrdinal
                scale={colorScale}
                labelFormat={(label): string => `${label.toUpperCase()}`}
            >
                {(labels): JSX.Element => (
                    <Group
                        sx={(theme: MantineTheme): CSSObject => ({
                            columnGap: theme.spacing.lg,
                            rowGap: "0.5rem",
                        })}
                    >
                        {labels.map((label, i) => {
                            const isActiveLabel = activeSeriesKeys.includes(label.datum);
                            const series = allSeries.find(({ key }) => key === label.datum);
                            const labelText: string = series?.label ?? "";
                            const seriesType: SeriesType = series?.seriesType ?? SeriesType.Bar;

                            const isLineSeries: boolean = seriesType === SeriesType.Line;

                            if (isLineSeries) {
                                return (
                                    <LegendLineWithLabel
                                        onClick={(): void => {
                                            toggleActiveSeries(label.datum);
                                        }}
                                        key={i}
                                        sx={{
                                            opacity: isActiveLabel ? 1 : INACTIVE_LEGEND_OPACITY,
                                            cursor: "pointer",
                                        }}
                                        text={labelText}
                                        color={label.value ?? "black"}
                                    />
                                );
                            }

                            return (
                                <LegendDotWithLabel
                                    onClick={(): void => {
                                        toggleActiveSeries(label.datum);
                                    }}
                                    key={i}
                                    sx={{
                                        opacity: isActiveLabel ? 1 : INACTIVE_LEGEND_OPACITY,
                                        cursor: "pointer",
                                    }}
                                    text={labelText}
                                    color={label.value ?? "black"}
                                />
                            );
                        })}
                    </Group>
                )}
            </LegendOrdinal>
        </Flex>
    );
};
