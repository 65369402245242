import { Box, Group, Text } from "@mantine/core";
import { LEGEND_FONT_SIZE } from "../utils";
import { ReactElement } from "react";
import { HeatmapGradient } from "./heatmap";

type HeatmapLegendProps = {
    gradient: HeatmapGradient;
    noDataColor: string;
    height?: number;
    width?: number;
};

const defaultHeight = 12;
const defaultWidth = 120;

export const HeatmapLegend = ({
    gradient,
    noDataColor,
    height = defaultHeight,
    width = defaultWidth,
}: HeatmapLegendProps): ReactElement => {
    return (
        <Group align="center" position="center" spacing="xl">
            <Group spacing="xs">
                <Text size={LEGEND_FONT_SIZE}>{gradient.startLabel}</Text>
                <Box
                    w={width}
                    h={height}
                    sx={{
                        background: `linear-gradient(to right, ${gradient.stops
                            .map(({ color, percent }) => `${color} ${percent}%`)
                            .join(",")})`,
                    }}
                ></Box>
                <Text size={LEGEND_FONT_SIZE}>{gradient.endLabel}</Text>
            </Group>
            <Group spacing="xs">
                <Text size={LEGEND_FONT_SIZE}>No data</Text>
                <Box w={height} h={height} sx={{ background: noDataColor }}></Box>
            </Group>
        </Group>
    );
};
