import { Group, Modal, Stack, Title, Text, Button, Anchor } from "@mantine/core";
import { Dispatch, SetStateAction } from "react";

type PremiumNagModalProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    onClose?: () => void;
};
export const PremiumNagModal = ({
    isOpen,
    setIsOpen,
    onClose,
}: PremiumNagModalProps): JSX.Element => {
    const onCloseInternal = (): void => {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    };
    return (
        <Modal
            size={640}
            centered={true}
            withCloseButton={false}
            padding="xl"
            opened={isOpen}
            onClose={onCloseInternal}
        >
            <Stack>
                <Title order={3} data-id="premium-nag-modal-title">
                    This feature is only available for CFE Suite Premium users
                </Title>

                <Text data-id="premium-nag-modal-text">
                    Upgrade now to gain access to this feature and many more. Contact us at{" "}
                    <Anchor href="mailto:sales@flexidao.com">sales@flexidao.com</Anchor> to request
                    upgrading to Premium.
                </Text>
                <Group position="right">
                    <Button
                        data-id="premium-nag-modal-button-ok"
                        uppercase
                        onClick={onCloseInternal}
                    >
                        Ok
                    </Button>
                </Group>
            </Stack>
        </Modal>
    );
};
