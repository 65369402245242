import {
    ContractType,
    TwentyFourSevenDto,
    twentyFourSevenComponents,
    twentyFourSevenPaths,
} from "@flexidao/dto";
import * as D from "schemawax";
import { isoTimestampDecoder } from "../utils";
import { contractTypeDecoder, matchingLogicDecoder } from "./misc";
import { InferApiFunctionReturn } from "../v2/helpers";
import { dateDecoder } from "@flexidao/helpers";

export const regionDecoder: D.Decoder<TwentyFourSevenDto.Region> = D.object({
    required: {
        regionId: D.string,
        name: D.string,
    },
});

export const regionArrayDecoder: D.Decoder<Array<TwentyFourSevenDto.Region>> =
    D.array(regionDecoder);
export const kpisDecoder: D.Decoder<TwentyFourSevenDto.Kpis> = D.object({
    required: {
        consumption_Wh: D.number,
        contractedCfe_Wh: D.number,
        contractedCfe_Percentage: D.number,
        gridMixCfe_Wh: D.number,
        gridMixCfe_Percentage: D.number,
        gridMixNonCfe_Wh: D.number,
        gridMixNonCfe_Percentage: D.number,
        excessCfe_Wh: D.number,
        excessCfe_Percentage: D.number,
        locationBasedEmissions_gCO2: D.number,
        marketBasedEmissions_gCO2: D.number,
    },
});
export const cfeCoverageValuesDecoder: D.Decoder<TwentyFourSevenDto.CfeCoverageValues> = D.object({
    required: {
        contractedCfe_Wh: D.number,
        gridMixCfe_Wh: D.number,
        gridMixNonCfe_Wh: D.number,
        excess_Wh: D.number,
        consumption_Wh: D.number,
    },
});

export const cfeCoverageDecoder: D.Decoder<TwentyFourSevenDto.CfeCoverage> = D.object({
    required: {
        startTime: D.string,
        endTime: D.string,
        values: cfeCoverageValuesDecoder,
    },
});

export const cfeCoverageArrayDecoder: D.Decoder<Array<TwentyFourSevenDto.CfeCoverage>> =
    D.array(cfeCoverageDecoder);
export const contractPerformanceDecoder: D.Decoder<TwentyFourSevenDto.ContractPerformance> =
    D.object({
        required: {
            startTime: D.string,
            endTime: D.string,
            name: D.string,
            contractType: contractTypeDecoder,
            contractedCfe_Wh: D.number,
            excess_Wh: D.number,
            compliantCFE: D.boolean,
            region: D.string,
        },
    });

export const contractPerformanceArrayDecoder: D.Decoder<
    Array<TwentyFourSevenDto.ContractPerformance>
> = D.array(contractPerformanceDecoder);

export const contractsPerformanceResponseDecoder: D.Decoder<TwentyFourSevenDto.ContractsPerformanceResponse> =
    D.object({
        required: {
            contracts: contractPerformanceArrayDecoder,
        },
    });

export const consumptionSiteGroupPerformanceDecoder: D.Decoder<
    twentyFourSevenComponents["schemas"]["ConsumptionSiteGroupPerformance"]
> = D.object({
    required: {
        consumptionSiteGroupId: D.string,
        consumptionSiteGroupName: D.string,
        consumptionWh: D.number,
        contractedCfeWh: D.number,
        gridMixCfeWh: D.number,
        gridMixNonCfeWh: D.number,
        excessCfeWh: D.number,
    },
});

export const consumptionSiteGroupsPerformanceResponseDecoder: D.Decoder<
    twentyFourSevenComponents["schemas"]["ConsumptionSiteGroupsPerformanceResponse"]
> = D.object({
    required: {
        consumptionSiteGroups: D.array(consumptionSiteGroupPerformanceDecoder),
    },
});

export const contractsResponseDecoder: D.Decoder<Array<TwentyFourSevenDto.BaseContract>> = D.array(
    D.object({
        required: {
            contractId: D.string,
            tenantId: D.string,
            name: D.string,
            startTime: isoTimestampDecoder,
            endTime: isoTimestampDecoder,
            contractType: contractTypeDecoder,
            matchingLogic: matchingLogicDecoder,
            percentageCoverage: D.number,
            compliantCfe: D.boolean,
            regionId: D.string,
        },
    }),
);

export const historicalPerformanceDecoder: D.Decoder<TwentyFourSevenDto.HistoricalFinancialPerformance> =
    D.object({
        required: {
            year: D.number,
            periodData: D.array(
                D.object({
                    required: {
                        period: dateDecoder,
                        totalContractedCfeProductionWh: D.nullable(D.number),
                        averageFixedPriceMwh: D.nullable(D.number),
                        averageMarketPriceMwh: D.nullable(D.number),
                        totalNetCashflow: D.nullable(D.number),
                    },
                }),
            ),
        },
    });

export const pendingWorkCountDecoder: D.Decoder<
    InferApiFunctionReturn<twentyFourSevenPaths, "/{tenantId}/pending-work">
> = D.boolean;

const siteDecoder: D.Decoder<twentyFourSevenComponents["schemas"]["Site"]> = D.object({
    required: {
        name: D.string,
        siteId: D.string,
    },
});

const contractDecoder: D.Decoder<twentyFourSevenComponents["schemas"]["BaseContract"]> = D.object({
    required: {
        contractId: D.string,
        tenantId: D.string,
        name: D.string,
        startTime: dateDecoder,
        endTime: dateDecoder,
        contractType: D.literalUnion(...Object.values(ContractType)),
        matchingLogic: D.literalUnion("pay-as-consumed", "pay-as-produced"),
        percentageCoverage: D.number,
        compliantCfe: D.boolean,
        regionId: D.string,
    },
});

export const getSitesByRegionResponseDecoder: D.Decoder<
    twentyFourSevenComponents["schemas"]["SitesByRegionResponse"]
> = D.array(siteDecoder);

const sitePerformanceDecoder: D.Decoder<twentyFourSevenComponents["schemas"]["SitePerformance"]> =
    D.object({
        required: {
            siteId: D.string,
            siteName: D.string,
            regionId: D.string,
            consumptionWh: D.number,
            contractedCfeWh: D.number,
            gridMixCfeWh: D.number,
            gridMixNonCfeWh: D.number,
            excessCfeWh: D.number,
            hourlyLocationBasedEmissions: D.number,
            hourlyMarketBasedEmissions: D.number,
        },
    });

export const getSitePerformanceByIdResponseDecoder: D.Decoder<
    twentyFourSevenComponents["schemas"]["SitePerformanceByIdResponse"]
> = D.object({ optional: { site: sitePerformanceDecoder } });

export const getSiteContractsResponseDecoder: D.Decoder<
    InferApiFunctionReturn<twentyFourSevenPaths, "/{tenantId}/sites/{siteId}/contracts", "get", 200>
> = D.object({
    required: {
        contractsAsConsumer: D.array(contractDecoder),
        contractsAsProducer: D.array(contractDecoder),
    },
});
