import { Module, SettingsPage } from "@flexidao/dto";
import { APP_PREFIX } from "./consts";

const basePath = `/${APP_PREFIX}/${Module.Settings}`;

type SettingsPaths = {
    [SettingsPage.UserProfile]: string;
    [SettingsPage.TenantInformation]: string;
    [SettingsPage.Users]: string;
    [SettingsPage.UserDetails]: (userId: string) => string;
    [SettingsPage.Sites]: string;
    [SettingsPage.EditSite]: (siteId: string) => string;
    [SettingsPage.CreateSite]: string;
    [SettingsPage.Contracts]: string;
    [SettingsPage.EditContract]: (contractId: string) => string;
    [SettingsPage.CreateContract]: string;
    [SettingsPage.ConsumptionSiteGroups]: string;
    [SettingsPage.CO2EmissionFactors]: string;
    [SettingsPage.EditCO2EmissionFactor]: (co2EmissionFactorId: string) => string;
    [SettingsPage.CreateCO2EmissionFactor]: string;
};

export const settingsPaths: SettingsPaths = {
    // BIM

    // Users
    [SettingsPage.UserProfile]: `${basePath}/${SettingsPage.UserProfile}`,
    [SettingsPage.TenantInformation]: `${basePath}/${SettingsPage.TenantInformation}`,
    users: `${basePath}/${SettingsPage.Users}`,
    [SettingsPage.UserDetails]: (userId) => `${basePath}/${SettingsPage.UserDetails}/${userId}`,

    // Sites
    [SettingsPage.Sites]: `${basePath}/${SettingsPage.Sites}`,
    [SettingsPage.EditSite]: (siteId) => `${basePath}/${SettingsPage.EditSite}/${siteId}`,
    [SettingsPage.CreateSite]: `${basePath}/${SettingsPage.CreateSite}`,

    // Contracts
    [SettingsPage.Contracts]: `${basePath}/${SettingsPage.Contracts}`,
    [SettingsPage.EditContract]: (contractId) =>
        `${basePath}/${SettingsPage.EditContract}/${contractId}`,
    [SettingsPage.CreateContract]: `${basePath}/${SettingsPage.CreateContract}`,

    // Groups
    [SettingsPage.ConsumptionSiteGroups]: `${basePath}/${SettingsPage.ConsumptionSiteGroups}`,

    // Grid Emissions
    // CO2 Emission Factors
    [SettingsPage.CO2EmissionFactors]: `${basePath}/${SettingsPage.CO2EmissionFactors}`,
    [SettingsPage.EditCO2EmissionFactor]: (co2EmissionFactorId) =>
        `${basePath}/${SettingsPage.EditCO2EmissionFactor}/${co2EmissionFactorId}`,
    [SettingsPage.CreateCO2EmissionFactor]: `${basePath}/${SettingsPage.CreateCO2EmissionFactor}`,
};
