import { PieChartItem } from "./piechart";
import Pie from "@visx/shape/lib/shapes/Pie";
import { Group } from "@visx/group";
import { useTooltip } from "@visx/tooltip";
import { ChartDimensions, ChartDisplayOptions } from "../utils";
import { ScaleOrdinal } from "d3-scale";
import React, { ReactElement, useCallback } from "react";
import { localPoint } from "@visx/event";
import { Legend } from "./legend";
import { Tooltip, PieChartTooltipData } from "./tooltip";

type PieChartTemplateProps = {
    dimensions: ChartDimensions;
    displayOptions: ChartDisplayOptions;
    data: PieChartItem[];
    radius: number;
    centerY: number;
    centerX: number;
    useDonut: boolean;
    useVerticalLayout: boolean;
    colorScale: ScaleOrdinal<string, string, never>;
};

export const PieChartTemplate = ({
    dimensions,
    data,
    radius,
    centerY,
    centerX,
    displayOptions,
    colorScale,
    useVerticalLayout,
    useDonut,
}: PieChartTemplateProps): ReactElement => {
    const { parentHeight, parentWidth, margin } = dimensions;
    const { tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
        useTooltip<PieChartTooltipData>();
    // tooltip handler
    const handleTooltip = useCallback(
        (event: React.MouseEvent<SVGRectElement>, data: PieChartItem) => {
            const { x, y } = localPoint(event) || { x: 0, y: 0 };
            showTooltip({
                tooltipData: { data: data },
                tooltipLeft: x,
                tooltipTop: y,
            });
        },
        [showTooltip],
    );

    // eslint-disable-next-line no-magic-numbers
    const innerRadius = useDonut ? (2 * radius) / 3 : 0;
    return (
        <div style={{ position: "relative" }}>
            <svg width={parentWidth} height={parentHeight}>
                <Group top={centerY + margin.top} left={centerX + margin.left}>
                    <Pie
                        data={data}
                        pieValue={(d): number => d.value}
                        outerRadius={radius}
                        innerRadius={innerRadius}
                        cornerRadius={0}
                    >
                        {(pie): JSX.Element[] => {
                            return pie.arcs.map((arc, index) => {
                                const { label } = arc.data;
                                const arcPath = pie.path(arc) ?? undefined;
                                const arcFill = colorScale(arc.data.key);

                                return (
                                    <g
                                        key={`arc-${label}-${index}`}
                                        onMouseMove={(
                                            event: React.MouseEvent<SVGRectElement>,
                                        ): void => handleTooltip(event, arc.data)}
                                        onMouseLeave={hideTooltip}
                                    >
                                        <path d={arcPath} fill={arcFill} />
                                    </g>
                                );
                            });
                        }}
                    </Pie>
                </Group>
            </svg>

            {displayOptions.showLegend && (
                <Legend
                    dimensions={dimensions}
                    colorScale={colorScale}
                    data={data}
                    useVerticalLayout={useVerticalLayout}
                />
            )}
            {displayOptions.showTooltip && (
                <Tooltip
                    tooltipLeft={tooltipLeft}
                    tooltipTop={tooltipTop}
                    tooltipData={tooltipData}
                />
            )}
        </div>
    );
};
