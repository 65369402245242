import { Box, Skeleton, Stack } from "@mantine/core";

export const NavMenuSkeleton = (): JSX.Element => {
    return (
        <Stack spacing={0}>
            <Box p="md">
                <Skeleton height={24} radius="sm" w="60%" />
            </Box>
            <Box p="md" pl="xl">
                <Skeleton height={24} radius="sm" w="70%" />
            </Box>
            <Box p="md" pl="xl">
                <Skeleton height={24} radius="sm" w="50%" />
            </Box>
            <Box p="md" pl="xl">
                <Skeleton height={24} radius="sm" w="90%" />
            </Box>
        </Stack>
    );
};
