import {
    BimDto,
    CfeConnectNavPage,
    CfeConnectPage,
    CfeDiagnosticNavPage,
    CfeDiagnosticPage,
    CfeInventoryNavPage,
    CfeInventoryPage,
    ContractType,
    MatchingLogic,
    Module,
    Page,
    ResourceAction,
    Service,
    SettingsNavPage,
    SettingsPage,
} from "@flexidao/dto";
import * as D from "schemawax";
import { isoTimestampDecoder } from "../utils";
import {
    contractEnergySourceDecoder,
    dataAccessDecoder,
    energySourceDecoder,
    siteTypeDecoder,
} from "./misc";

const tenantStatusDecoder = D.literalUnion(
    BimDto.TenantStatus.Approved,
    BimDto.TenantStatus.Cancelled,
    BimDto.TenantStatus.Pending,
    BimDto.TenantStatus.Rejected,
);

export const tenantDecoder: D.Decoder<BimDto.Tenant> = D.recursive(() =>
    D.object({
        required: {
            tenantId: D.string,
            name: D.string,
            description: D.string,
            status: tenantStatusDecoder,
            premium: D.boolean,
            contactEmail: D.string,
        },
        optional: {
            users: D.array(userDecoder),
            services: D.oneOf(D.array(flexidaoServiceDecoder), D.array(D.string)),
            procurementAccountId: D.nullable(D.string),
        },
    }),
);

const resourceActionDecoder = D.literalUnion(
    ResourceAction.Read,
    ResourceAction.Upsert,
    ResourceAction.Delete,
);

export const serviceIdDecoder: D.Decoder<Service> = D.string.andThen((s) => {
    if (Object.values(Service).includes(s as Service)) {
        return s as Service;
    }
    throw new Error(
        `Invalid service id '${s}'. Make sure this is defined in both DTO enum, in the webserver, and in the openapi schema from bim.`,
    );
});

export const flexidaoServiceDecoder: D.Decoder<BimDto.FlexidaoService> = D.recursive(() =>
    D.object({
        required: {
            flexidaoServiceId: serviceIdDecoder,
            name: D.string,
            description: D.string,
        },
        optional: {
            tenants: D.array(tenantDecoder),
        },
    }),
);

export const roleDecoder: D.Decoder<BimDto.Role> = D.recursive(() =>
    D.object({
        required: {
            roleId: D.string,
            name: D.string,
            tenant: D.oneOf(tenantDecoder, D.string),
        },
        optional: {
            users: D.array(userDecoder),
            permissions: D.array(D.oneOf(permissionDecoder, D.string)),
        },
    }),
);

export const permissionDecoder: D.Decoder<BimDto.Permission> = D.object({
    required: {
        permissionId: D.string,
        flexidaoService: D.oneOf(serviceIdDecoder, flexidaoServiceDecoder),
        resource: D.string,
        action: resourceActionDecoder,
        roles: D.oneOf(D.array(roleDecoder), D.array(D.string)),
    },
});

const roleOnUserDecoder: D.Decoder<BimDto.RoleOnUser> = D.object({
    required: {
        roleId: D.string,
        name: D.string,
    },
});

export const userDecoder: D.Decoder<BimDto.User> = D.object({
    required: {
        userId: D.string,
        enabled: D.boolean,
        auth0UserId: D.string,
        auth0UserEmail: D.string,
        roles: D.array(roleOnUserDecoder),
    },
    optional: {
        firstName: D.nullable(D.string),
        email: D.nullable(D.string),
        lastName: D.nullable(D.string),
    },
});

const selfTenantsOptionDecoder: D.Decoder<BimDto.AppUserTenantOption> = D.object({
    required: {
        tenantId: D.string,
        name: D.string,
        status: tenantStatusDecoder,
        premium: D.boolean,
    },
});

const cfeConnectPageDecoder: D.Decoder<CfeConnectPage> = D.literalUnion(
    CfeConnectPage.MeterDataDashboard,
    CfeConnectPage.MonitoringDashboard,
);
const cfeConnectNavPageDecoder: D.Decoder<CfeConnectNavPage> = D.literalUnion(
    CfeConnectPage.MeterDataDashboard,
    CfeConnectPage.MonitoringDashboard,
);
const cfeInventoryPageDecoder: D.Decoder<CfeInventoryPage> = D.literalUnion(
    CfeInventoryPage.TwentyFourSevenCfeDashboard,
    CfeInventoryPage.PpaFinancialDashboard,
    CfeInventoryPage.PortfolioOverview,
    CfeInventoryPage.ContractTracking,
    CfeInventoryPage.TransactionsAllocation,
    CfeInventoryPage.ReportingDashboard,
    CfeInventoryPage.AssignmentOverview,
    CfeInventoryPage.BiddingZoneAssignment,
    CfeInventoryPage.EditBiddingZoneAssignment,
    CfeInventoryPage.GlobalOverview,
);

const cfeInventoryNavPageDecoder: D.Decoder<CfeInventoryNavPage> = D.literalUnion(
    CfeInventoryPage.TwentyFourSevenCfeDashboard,
    CfeInventoryPage.PpaFinancialDashboard,
    CfeInventoryPage.PortfolioOverview,
    CfeInventoryPage.ContractTracking,
    CfeInventoryPage.ReportingDashboard,
    CfeInventoryPage.GlobalOverview,
);

const cfeDiagnosticPageDecoder: D.Decoder<CfeDiagnosticPage> = D.literalUnion(
    CfeDiagnosticPage.Estimations,
    CfeDiagnosticPage.NewEstimation,
    CfeDiagnosticPage.EditEstimation,
    CfeDiagnosticPage.EstimationResult,
);

const cfeDiagnosticNavPageDecoder: D.Decoder<CfeDiagnosticNavPage> = D.literalUnion(
    CfeDiagnosticPage.Estimations,
);

const settingsPageDecoder: D.Decoder<SettingsPage> = D.literalUnion(
    SettingsPage.UserProfile,
    SettingsPage.Users,
    SettingsPage.UserDetails,
    SettingsPage.TenantInformation,
    SettingsPage.Sites,
    SettingsPage.EditSite,
    SettingsPage.CreateSite,
    SettingsPage.ConsumptionSiteGroups,
    SettingsPage.Contracts,
    SettingsPage.EditContract,
    SettingsPage.CreateContract,
    SettingsPage.CO2EmissionFactors,
    SettingsPage.EditCO2EmissionFactor,
    SettingsPage.CreateCO2EmissionFactor,
);

const settingsNavPageDecoder: D.Decoder<SettingsNavPage> = D.literalUnion(
    SettingsPage.UserProfile,
    SettingsPage.Users,
    SettingsPage.TenantInformation,
    SettingsPage.Sites,
    SettingsPage.ConsumptionSiteGroups,
    SettingsPage.Contracts,
    SettingsPage.CO2EmissionFactors,
);
const pageIdDecoder: D.Decoder<Page> = D.oneOf(
    cfeConnectPageDecoder,
    cfeInventoryPageDecoder,
    cfeDiagnosticPageDecoder,
    settingsPageDecoder,
);

const moduleDecoder: D.Decoder<Module> = D.literalUnion(
    Module.CfeConnect,
    Module.CfeDiagnostic,
    Module.CfeInventory,
    Module.Settings,
);

const pageDecoder: D.Decoder<BimDto.Page> = D.object({
    required: {
        pageId: pageIdDecoder,
        name: D.string,
        moduleId: moduleDecoder,
        isNavItem: D.boolean,
    },
});

const tenantDisplayDecoder: D.Decoder<BimDto.TenantDisplay> = D.object({
    required: {
        homepage: D.nullable(pageDecoder),
        modules: D.object({
            required: {
                [Module.CfeConnect]: D.object({
                    required: {
                        isVisible: D.boolean,
                        landingPageId: D.nullable(cfeConnectNavPageDecoder),
                        visibleNavigationPageIds: D.array(cfeConnectPageDecoder),
                    },
                }),
                [Module.CfeDiagnostic]: D.object({
                    required: {
                        isVisible: D.boolean,
                        landingPageId: D.nullable(cfeDiagnosticNavPageDecoder),
                        visibleNavigationPageIds: D.array(cfeDiagnosticPageDecoder),
                    },
                }),
                [Module.CfeInventory]: D.object({
                    required: {
                        isVisible: D.boolean,
                        landingPageId: D.nullable(cfeInventoryNavPageDecoder),
                        visibleNavigationPageIds: D.array(cfeInventoryPageDecoder),
                    },
                }),
                [Module.Settings]: D.object({
                    required: {
                        isVisible: D.boolean,
                        landingPageId: D.nullable(settingsNavPageDecoder),
                        visibleNavigationPageIds: D.array(settingsPageDecoder),
                    },
                }),
            },
        }),
    },
});

const activeTenantDecoder: D.Decoder<BimDto.ActiveTenant> = D.object({
    required: {
        tenantId: D.string,
        name: D.string,
        status: tenantStatusDecoder,
        premium: D.boolean,
        services: D.array(flexidaoServiceDecoder),
        displaySettings: tenantDisplayDecoder,
    },
});

export const selfDecoder: D.Decoder<BimDto.AppUser> = D.object({
    required: {
        userId: D.string,
        enabled: D.boolean,
        auth0UserId: D.string,
        auth0UserEmail: D.string,
        activeTenant: activeTenantDecoder,
        tenants: D.array(selfTenantsOptionDecoder),
        roleIds: D.array(D.string),
    },
    optional: {
        firstName: D.nullable(D.string),
        email: D.nullable(D.string),
        lastName: D.nullable(D.string),
    },
});

export const getUsersDecoder: D.Decoder<BimDto.GetUsersResponse> = D.object({
    required: {
        totalPages: D.number,
        users: D.array(userDecoder),
    },
});

export const postAdminAuthorizeDecoder: D.Decoder<BimDto.AuthorizeActionResponse> = D.object({
    optional: {
        tenantId: D.string,
        roles: D.array(D.string),
    },
    required: {
        identity: D.literalUnion("userId", "serviceAccountId"),
        id: D.string,
        permissionMatches: D.array(
            D.object({
                required: {
                    permissionId: D.string,
                    oAuthScope: D.string,
                },
            }),
        ),
    },
});

export const energySourcesArrayDecoder: D.Decoder<Array<BimDto.EnergySource>> = D.array(
    contractEnergySourceDecoder,
);

export const countryDecoder: D.Decoder<BimDto.Country> = D.object({
    required: {
        countryId: D.string,
        name: D.string,
        enabled: D.boolean,
    },
});

export const regionDecoder: D.Decoder<BimDto.Region> = D.object({
    required: {
        regionId: D.string,
        name: D.string,
    },
});

export const biddingZoneDecoder: D.Decoder<BimDto.BiddingZone> = D.object({
    required: {
        zoneId: D.string,
        name: D.string,
        country: countryDecoder,
        timezones: D.array(D.string), //timeZoneDecoder,
        enabled: D.boolean,
        dataAccess: dataAccessDecoder,
        region: regionDecoder,
    },
});

export const siteDecoder: D.Decoder<BimDto.Site> = D.object({
    required: {
        siteId: D.string,
        name: D.string,
        tenantId: D.string,
        siteType: siteTypeDecoder,
        country: countryDecoder,
        biddingZone: biddingZoneDecoder,
        timezone: D.string, //timeZoneDecoder,
        consumptionSiteGroupId: D.nullable(D.string),
        installedCapacityW: D.nullable(D.number),
    },
    optional: {
        energySource: energySourceDecoder,
    },
});

export const sitesArrayDecoder: D.Decoder<Array<BimDto.Site>> = D.array(siteDecoder);

export const getAllSitesDecoder: D.Decoder<BimDto.GetSitesByTenantIdResponse> = D.object({
    required: {
        totalSites: D.number,
        sites: sitesArrayDecoder,
    },
});

const countryOptionDecoder: D.Decoder<BimDto.CountryOption> = D.object({
    required: {
        countryId: D.string,
        name: D.string,
    },
});

const biddingZoneOptionDecoder: D.Decoder<BimDto.BiddingZoneOption> = D.object({
    required: {
        zoneId: D.string,
        name: D.string,
    },
});

export const getSitesFiltersDecoder: D.Decoder<BimDto.GetSitesFiltersResponse> = D.object({
    required: {
        countries: D.array(countryOptionDecoder),
        biddingZones: D.array(biddingZoneOptionDecoder),
        siteTypes: D.array(siteTypeDecoder),
    },
});

export const biddingZoneInCountryDecoder: D.Decoder<BimDto.BiddingZoneInCountry> = D.object({
    required: {
        zoneId: D.string,
        name: D.string,
        enabled: D.boolean,
        timezones: D.array(D.string),
    },
});

export const countryWithZonesDecoder: D.Decoder<BimDto.CountryWithZones> = D.object({
    required: {
        countryId: D.string,
        name: D.string,
        enabled: D.boolean,
        biddingZones: D.array(biddingZoneInCountryDecoder),
    },
});

export const countriesWithZonesArrayDecoder: D.Decoder<Array<BimDto.CountryWithZones>> =
    D.array(countryWithZonesDecoder);

//Contracts

export const regionsArrayDecoder: D.Decoder<Array<BimDto.Region>> = D.array(regionDecoder);
//For now, we only support 2 contract types
export const contractTypeLimitedDecoder: D.Decoder<ContractType> = D.literalUnion(
    ContractType.PPA,
    ContractType.GreenTariff,
    ContractType.UnbundledEACs,
);

export const matchingLogicTypeDecoder: D.Decoder<MatchingLogic> = D.literalUnion(
    MatchingLogic.PayAsConsumed,
    MatchingLogic.PayAsProduced,
);

export const currencyDecoder: D.Decoder<BimDto.Currency> = D.object({
    required: {
        isoCode: D.string,
        name: D.string,
        order: D.number,
    },
});

export const contractDecoderPPA: D.Decoder<BimDto.ContractPPA> = D.object({
    required: {
        contractType: D.literalUnion(ContractType.PPA),
        contractId: D.string,
        tenantId: D.string,
        name: D.string,
        startTime: isoTimestampDecoder,
        endTime: isoTimestampDecoder,
        matchingLogic: matchingLogicTypeDecoder,
        percentageCoverage: D.number.andThen((percentage) => {
            if (percentage < 0 || percentage > 100) {
                throw new D.DecoderError(
                    `Percentage coverage must be between 0 and 100, but was ${percentage}`,
                );
            }
            return percentage;
        }),
        compliantCFE: D.boolean,
        region: D.object({
            required: {
                regionId: D.string,
                name: D.string,
            },
        }),
        consumptionSites: sitesArrayDecoder,
        productionSites: sitesArrayDecoder,
    },
    optional: {
        contractDetails: D.nullable(D.string),
    },
});

export const contractDecoderGreenTariff: D.Decoder<BimDto.ContractGreenTariff> = D.object({
    required: {
        contractType: D.literalUnion(ContractType.GreenTariff),
        contractId: D.string,
        tenantId: D.string,
        name: D.string,
        startTime: isoTimestampDecoder,
        endTime: isoTimestampDecoder,
        matchingLogic: matchingLogicTypeDecoder,
        percentageCoverage: D.number.andThen((percentage) => {
            if (percentage < 0 || percentage > 100) {
                throw new D.DecoderError(
                    `Percentage coverage must be between 0 and 100, but was ${percentage}`,
                );
            }
            return percentage;
        }),
        compliantCFE: D.boolean,
        region: D.object({
            required: {
                regionId: D.string,
                name: D.string,
            },
        }),
        consumptionSites: sitesArrayDecoder,
    },
    optional: {
        productionSites: sitesArrayDecoder,
        contractDetails: D.nullable(D.string),
    },
});

export const contractDecoderBundled: D.Decoder<BimDto.ContractBundled> = D.oneOf(
    contractDecoderPPA,
    contractDecoderGreenTariff,
);

export const currencyArrayDecoder: D.Decoder<Array<BimDto.Currency>> = D.array(currencyDecoder);

export const contractDecoderUnbundled: D.Decoder<BimDto.ContractUnbundled> = D.object({
    required: {
        contractType: D.literal(ContractType.UnbundledEACs),
        contractId: D.string,
        tenantId: D.string,
        name: D.string,
        startTime: isoTimestampDecoder,
        endTime: isoTimestampDecoder,
        region: D.object({
            required: {
                regionId: D.string,
                name: D.string,
            },
        }),
        currency: currencyDecoder,
        price: D.number,
        volumeMWh: D.number,
        counterpart: D.string,
    },
    optional: {
        contractDetails: D.nullable(D.string),
        startProduction: isoTimestampDecoder,
        endProduction: isoTimestampDecoder,
        productionCountries: D.array(D.string),
        energySources: D.array(energySourceDecoder),
    },
});

export const contractDecoder: D.Decoder<BimDto.Contract> = D.oneOf(
    contractDecoderBundled,
    contractDecoderUnbundled,
);

export const contractGreenTariffInputDecoder: D.Decoder<BimDto.ContractGreenTariffPayload> =
    D.object({
        required: {
            contractType: D.literalUnion(ContractType.GreenTariff),
            name: D.string,
            startTime: isoTimestampDecoder,
            endTime: isoTimestampDecoder,
            matchingLogic: matchingLogicTypeDecoder,
            percentageCoverage: D.number.andThen((percentage) => {
                if (percentage < 0 || percentage > 100) {
                    throw new D.DecoderError(
                        `Percentage coverage must be between 0 and 100, but was ${percentage}`,
                    );
                }
                return percentage;
            }),

            compliantCFE: D.boolean,
            selectAllConsumptionSites: D.boolean,
            region: D.string,
            productionSites: D.nullable(D.array(D.string)),
            consumptionSites: D.array(D.string),
            contractDetails: D.nullable(D.string),
        },
    });

export const contractPPAInputDecoder: D.Decoder<BimDto.ContractPPAPayload> = D.object({
    required: {
        contractType: D.literalUnion(ContractType.PPA),
        name: D.string,
        startTime: isoTimestampDecoder,
        endTime: isoTimestampDecoder,
        matchingLogic: matchingLogicTypeDecoder,
        percentageCoverage: D.number.andThen((percentage) => {
            if (percentage < 0 || percentage > 100) {
                throw new D.DecoderError(
                    `Percentage coverage must be between 0 and 100, but was ${percentage}`,
                );
            }
            return percentage;
        }),

        compliantCFE: D.boolean,
        selectAllConsumptionSites: D.boolean,
        region: D.string,
        productionSites: D.array(D.string).andThen((productionSites) => {
            if (productionSites.length === 0) {
                throw new D.DecoderError(`Production sites must be at least one`);
            }
            return productionSites;
        }),
        consumptionSites: D.array(D.string),
        contractDetails: D.nullable(D.string),
    },
});

export const contractBundledInputDecoder: D.Decoder<BimDto.ContractBundledPayload> = D.oneOf(
    contractPPAInputDecoder,
    contractGreenTariffInputDecoder,
);

export const contractUnbundledInputDecoder: D.Decoder<BimDto.ContractUnbundledPayload> = D.object({
    required: {
        contractType: D.literal(ContractType.UnbundledEACs),
        name: D.string,
        startTime: isoTimestampDecoder,
        endTime: isoTimestampDecoder,
        region: D.string,
        currency: D.string,
        price: D.number,
        volumeMWh: D.number,
        counterpart: D.string,
        energySources: D.nullable(D.array(energySourceDecoder)),
        productionCountries: D.nullable(D.array(D.string)),
        endProduction: D.nullable(isoTimestampDecoder),
        startProduction: D.nullable(isoTimestampDecoder),
        contractDetails: D.nullable(D.string),
    },
});

export const contractInputDecoder: D.Decoder<BimDto.ContractPayload> = D.oneOf(
    contractBundledInputDecoder,
    contractUnbundledInputDecoder,
);

export const contractsArrayDecoder: D.Decoder<Array<BimDto.Contract>> = D.array(contractDecoder);

export const getAllContractsDecoder: D.Decoder<BimDto.GetContractsResponse> = D.object({
    required: {
        totalContracts: D.number,
        contracts: contractsArrayDecoder,
    },
});

export const getContractsFiltersDecoder: D.Decoder<BimDto.GetContractsFiltersResponse> = D.object({
    required: {
        regions: regionsArrayDecoder,
        contractTypes: D.array(contractTypeLimitedDecoder),
        compliantCFE: D.array(D.boolean),
    },
});

export const siteSummaryDecoder: D.Decoder<BimDto.SiteSummary> = D.object({
    required: {
        siteId: D.string,
        name: D.string,
        regionId: D.string,
    },
});

export const regionSummaryDecoder: D.Decoder<BimDto.Region> = D.object({
    required: {
        regionId: D.string,
        name: D.string,
    },
});

export const siteSummaryArrayDecoder: D.Decoder<Array<BimDto.SiteSummary>> =
    D.array(siteSummaryDecoder);

export const regionSummaryArrayDecoder: D.Decoder<Array<BimDto.Region>> =
    D.array(regionSummaryDecoder);

export const getConsumptionSitesSummaryDecoder: D.Decoder<BimDto.ConsumptionSitesSummary> =
    D.object({
        required: {
            regions: regionSummaryArrayDecoder,
            consumptionSites: siteSummaryArrayDecoder,
        },
    });

export const baseConsumptionSiteGroupDecoder: D.Decoder<BimDto.BaseConsumptionSiteGroup> = D.object(
    {
        required: {
            consumptionSiteGroupId: D.string,
            tenantId: D.string,
            siteIds: D.array(D.string),
            name: D.string,
        },
    },
);

export const baseConsumptionSiteGroupArrayDecoder: D.Decoder<
    Array<BimDto.BaseConsumptionSiteGroup>
> = D.array(baseConsumptionSiteGroupDecoder);

export const consumptionSiteGroupDetailDecoder: D.Decoder<BimDto.ConsumptionSiteGroupDetail> =
    D.object({
        required: {
            consumptionSiteGroupId: D.string,
            tenantId: D.string,
            sites: D.array(
                D.object({
                    required: {
                        siteId: D.string,
                        name: D.string,
                        countryName: D.string,
                    },
                }),
            ),
            name: D.string,
        },
    });
