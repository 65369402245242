import { labelToDataId } from "@flexidao/ui-lib";
import {
    Button,
    CSSObject,
    Divider,
    Group,
    MantineTheme,
    Paper,
    Popover,
    Stack,
    Text,
    Title,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { ReactElement, useState } from "react";

type SelectFilterProps<T extends string | number> = {
    title: string;
    data: Array<{ label: string; value: T }>;
    disabled?: boolean;
    value: T | null;
    setValue: (val: T | null) => void;
    dataIdPrefix: string;
    icon?: ReactElement;
};

export const SelectFilter = <T extends string | number>({
    title,
    data,
    disabled = false,
    dataIdPrefix,
    value,
    setValue,
    icon = <IconChevronDown size={16} />,
}: SelectFilterProps<T>): ReactElement => {
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [provisionalValue, setProvisionalValue] = useState<T | null>(value ?? null);

    const confirmSelection = (): void => {
        setValue(provisionalValue);
        setFilterOpen(false);
    };

    return (
        <Popover
            opened={filterOpen}
            onChange={(opened: boolean): void => setFilterOpen(opened)}
            position="bottom-start"
            closeOnClickOutside
            closeOnEscape
        >
            <Popover.Target>
                <Paper
                    onClick={
                        disabled
                            ? undefined
                            : (): void => {
                                  setFilterOpen(!filterOpen);
                              }
                    }
                    px={12}
                    py={4}
                    bg={disabled ? "flexidaoGrey.1" : "white"}
                    sx={{
                        cursor: disabled ? "not-allowed" : "pointer",
                    }}
                    data-id={labelToDataId({
                        prefix: dataIdPrefix,
                        label: "target",
                    })}
                >
                    <Group position="apart" align="center">
                        <Stack spacing={2}>
                            <Title fz="12px" lh="14px" fw={600}>
                                {title}
                            </Title>
                            <Text fz="12px" lh="14px" size="sm" c="flexidaoGrey.8">
                                {value ? value : "Select"}
                            </Text>
                        </Stack>
                        {icon}
                    </Group>
                </Paper>
            </Popover.Target>

            <Popover.Dropdown
                p={0}
                sx={(theme: MantineTheme): CSSObject => ({
                    border: "none",
                    borderRadius: theme.radius.md,
                })}
            >
                <Paper
                    pt="md"
                    pb="sm"
                    px={0}
                    data-id={labelToDataId({
                        prefix: dataIdPrefix,
                        label: "dropdown",
                    })}
                >
                    <Stack>
                        <Text px="xl" weight={600}>
                            {title}
                        </Text>
                        <Divider />
                        <Group px="xl" spacing={0}>
                            {data.map((item) => (
                                <Button
                                    key={item.value}
                                    data-id={labelToDataId({
                                        prefix: `${dataIdPrefix}-select-item`,
                                        label: `${item.value}`,
                                    })}
                                    variant={provisionalValue === item.value ? "filled" : "subtle"}
                                    onClick={(): void => {
                                        setProvisionalValue(item.value);
                                    }}
                                >
                                    {item.label}
                                </Button>
                            ))}
                        </Group>
                        <Divider />
                        <Group px="xl" position="right">
                            <Button
                                data-id={labelToDataId({
                                    prefix: dataIdPrefix,
                                    label: "confirm-button",
                                })}
                                disabled={!provisionalValue || provisionalValue === value}
                                onClick={(): void => {
                                    confirmSelection();
                                }}
                                uppercase
                            >
                                Apply Selection
                            </Button>
                        </Group>
                    </Stack>
                </Paper>
            </Popover.Dropdown>
        </Popover>
    );
};
