import { lazy } from "react";

export const DummyLazyComponent = (): Promise<{
    default: () => JSX.Element;
}> =>
    new Promise<{ default: () => JSX.Element }>((resolve) => {
        return resolve({ default: () => <></> });
    });

export const dummyLazyImport = lazy(DummyLazyComponent);
