import { ReactElement } from "react";
import { Text } from "@mantine/core";
import { EmptyCell } from "./empty-cell";
import { getClassNameForSubRow } from "./utils";

type StringCellProps = {
    value: string | null;
    isSubRow?: boolean;
};

export const StringCell = ({ value, isSubRow = false }: StringCellProps): ReactElement => {
    if (value == null) {
        return <EmptyCell isSubRow={isSubRow} />;
    }

    return (
        <Text
            size={14}
            className={getClassNameForSubRow(isSubRow)}
            sx={{ wordBreak: "break-word" }}
        >
            {value}
        </Text>
    );
};
