import { Err } from "ts-results";
import { inspect } from "util";

// MONKEY PATCH that serializes properly ts-results nested errors
// when someone calls x.toString or String(x) when x is an ErrImpl of ts-results library
Err.prototype.toString = function (): string {
    return inspect(this, { showHidden: false, depth: 30 });
};
Err.prototype.flexidaoMonkeyPatch = true;

export * from "./api-client";
export * from "./clients";
export * from "./decoders";
export * from "./types";
export * from "./utils";
