import { ReactElement } from "react";
import { TooltipIconType, TooltipType } from "./types";
import { Center, Tooltip } from "@mantine/core";
import { IconAlertTriangle, IconInfoCircle } from "@tabler/icons-react";
import { labelToDataId, mantineTheme } from "@flexidao/ui-lib";
import {
    TOOLTIP_CLOSE_DELAY_MS,
    TOOLTIP_ICON_SIZE,
    TOOLTIP_MAX_WIDTH,
    TOOLTIP_OFFSET,
    TOOLTIP_TRANSITION_DURATION,
} from "./consts";

const getTooltipIcon = (tooltip: TooltipType): ReactElement => {
    const tooltipSize: number =
        "size" in tooltip && tooltip.size != null //
            ? tooltip.size
            : TOOLTIP_ICON_SIZE;

    switch (tooltip.iconType) {
        case TooltipIconType.Info:
            return (
                <IconInfoCircle size={tooltipSize} color={mantineTheme.colors?.flexidaoGreen![5]} />
            );
        case TooltipIconType.Warning:
            return (
                <IconAlertTriangle
                    size={tooltipSize}
                    color={mantineTheme.colors?.flexidaoOrange![5]}
                />
            );
        case TooltipIconType.Custom:
            return tooltip.icon;
    }
};

export type IconWithTooltipProps = {
    tooltip: TooltipType;
    dataId?: string;
};

export const IconWithTooltip = ({ tooltip, dataId }: IconWithTooltipProps): ReactElement => {
    return (
        <Tooltip
            label={tooltip.content}
            multiline
            maw={tooltip.maxWidth ?? TOOLTIP_MAX_WIDTH}
            transitionProps={{ duration: TOOLTIP_TRANSITION_DURATION, transition: "fade" }}
            closeDelay={TOOLTIP_CLOSE_DELAY_MS}
            offset={TOOLTIP_OFFSET}
            events={{
                hover: true,
                focus: false,
                touch: true,
            }}
            p="sm"
            data-id={labelToDataId({ prefix: dataId, label: "tooltip-content" })}
            position={tooltip.position}
            sx={{
                textAlign: "start !important" as "start",
                fontWeight: "normal !important" as "normal",
            }}
            withArrow
        >
            <Center
                sx={{
                    cursor: "pointer",
                }}
                data-id={labelToDataId({ prefix: dataId, label: "tooltip-icon" })}
                onClick={tooltip.onClick}
            >
                {getTooltipIcon(tooltip)}
            </Center>
        </Tooltip>
    );
};
