import { formatNumber, physicalRoundForElectricity } from "@flexidao/ui-lib";
import { Group, Text } from "@mantine/core";
import { ReactElement } from "react";
import { EmptyCell } from "./empty-cell";
import { getClassNameForSubRow } from "./utils";

type NumericCellProps = {
    value: number | null;
    numDecimals?: number;
    units?: string;
    enablePhysicalRound?: boolean;
    color?: string;
    weight?: "normal" | "semibold" | "bold";
    displaySpaceBetweenNumberAndUnits?: boolean;
    isSubRow?: boolean;
};

const getValueAndMagnitude = (
    value: number,
    numDecimals: number,
    enablePhysicalRound: boolean = false,
): [string, string] => {
    if (enablePhysicalRound) {
        return physicalRoundForElectricity(value, numDecimals);
    }

    const valueString: string = formatNumber(value, numDecimals);

    return [valueString, ""];
};

const getFontWeight = (weight: "normal" | "semibold" | "bold"): "bold" | "normal" | number => {
    if (weight === "bold") {
        return "bold";
    }

    if (weight === "semibold") {
        /* eslint-disable no-magic-numbers */
        return 600;
    }

    return "normal";
};

const getSpacing = (displaySpaceBetweenNumberAndUnits: boolean): number => {
    /* eslint-disable no-magic-numbers */
    return displaySpaceBetweenNumberAndUnits ? 4 : 2;
};

export const NumberCell = ({
    value,
    numDecimals = 0,
    units = "",
    enablePhysicalRound = false,
    color = "flexidaoGrey.9",
    weight = "normal",
    displaySpaceBetweenNumberAndUnits = true,
    isSubRow = false,
}: NumericCellProps): ReactElement => {
    if (value == null || isNaN(value)) {
        return (
            <Group
                sx={{
                    justifyContent: "flex-end",
                }}
            >
                <EmptyCell isSubRow={isSubRow} />
            </Group>
        );
    }

    const [valueString, magnitude]: [string, string] = getValueAndMagnitude(
        value,
        numDecimals,
        enablePhysicalRound,
    );
    const valueSplitArray: Array<string> = valueString.split(".");

    const integerPart: string = valueSplitArray[0]!;
    const decimalPart: string | null = valueSplitArray[1] ?? null;

    const fontWeight = getFontWeight(weight);
    const spacing: number = getSpacing(displaySpaceBetweenNumberAndUnits);

    return (
        <Group
            noWrap
            align="baseline"
            spacing={spacing}
            sx={{
                justifyContent: "flex-end",
            }}
            className={getClassNameForSubRow(isSubRow)}
        >
            <Group noWrap align="baseline" spacing={0}>
                <Text size={14} fw={fontWeight} c={color}>
                    {integerPart}
                </Text>
                {decimalPart && (
                    <Text size={11} fw={fontWeight} c={color}>
                        .{decimalPart}
                    </Text>
                )}
            </Group>

            {units != null && (
                <Text size={11} fw={fontWeight} c={color}>
                    {magnitude}
                    {units}
                </Text>
            )}
        </Group>
    );
};
