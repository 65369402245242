import { Group, Title } from "@mantine/core";
import { ReactElement } from "react";
import { IconWithTooltip, labelToDataId } from "@flexidao/ui-lib";
import { TooltipType } from "../icon-with-tooltip/types";

export type SectionTitleProps = {
    label: string;
    tooltip?: TooltipType;
    dataId?: string;
};

export const SectionTitle = ({ label, tooltip, dataId }: SectionTitleProps): ReactElement => {
    return (
        <Group spacing={4} data-id={dataId}>
            <Title order={3}>{label}</Title>
            {tooltip && (
                <IconWithTooltip
                    tooltip={tooltip}
                    dataId={labelToDataId({ prefix: dataId, label: "icon-with-tooltip" })}
                />
            )}
        </Group>
    );
};
