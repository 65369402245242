import { TooltipType } from "@flexidao/ui-lib";
import { MRT_Column, MRT_Header, MRT_TableInstance } from "mantine-react-table";
import { ReactNode } from "react";
import { RowData } from "../types";

export enum FilterType {
    Text = "text",
    Select = "select",
    MultiSelect = "multi-select",
}

export enum ColumnAlign {
    Start = "start",
    End = "end",
    Center = "center",
}

export enum ColumnType {
    Number = "number",
    Division = "division",
    String = "string",
    Date = "date",
    Datetime = "datetime",
    Boolean = "boolean",
    Custom = "custom",
}

export type ColumnFilterOption = {
    value: string;
    label: string;
};

export type ColumnFilterProps =
    | {
          filterType: FilterType.Text;
      }
    | {
          filterType: FilterType.Select;
          options: Array<ColumnFilterOption>;
      }
    | {
          filterType: FilterType.MultiSelect;
          options: Array<ColumnFilterOption>;
      };

export type DivisionColumnType = {
    value: number | null;
    total: number | null;
    units?: string;
    color?: string;
    numDecimals?: number;
};
export type NumberColumnType = {
    value: number | null;
    numDecimals?: number;
    weight?: "normal" | "semibold" | "bold";
    color?: string;
    units?: string;
    enablePhysicalRound?: boolean;
    displaySpaceBetweenNumberAndUnits?: boolean;
};
export type DatetimeColumnType = {
    date: Date | null;
    timezone?: string;
    timezoneLabel?: string;
};
export type DateColumnType = {
    date: Date | null;
    timezone?: string;
};
export type StringColumnType = string | null;
export type BooleanColumnType = boolean | null;
export type CustomColumnType = ReactNode;
export type RowExtraDataColumnType<T> = T;

export type Column<T extends RowData> = {
    columnType: ColumnType;
    accessorKey: Extract<keyof T, string>;
    header: string;
    textAlign?: ColumnAlign;
    headerTooltip?: TooltipType;
    enableSorting?: boolean;
    filterProps?: ColumnFilterProps;
    visible?: boolean;
    size?: number;
    minSize?: number;
    maxSize?: number;
    grow?: boolean;
    enableClickToCopy?: boolean;
    Footer?:
        | ReactNode
        | ((props: {
              column: MRT_Column<T>;
              footer: MRT_Header<T>;
              table: MRT_TableInstance<T>;
          }) => ReactNode);
};
