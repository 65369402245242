import { FOUR_WEEKS_MS, ONE_YEAR_MS } from "../consts";
import { formatDateTime, formatDate, formatDateMonth } from "./format-date";

export const getDateFormatterForPeriod = (
    dateRange: [Date, Date],
): ((date: Date, timezone?: string) => string) => {
    const periodInMs = dateRange[1].getTime() - dateRange[0].getTime();

    let dateFormatter;
    if (periodInMs <= FOUR_WEEKS_MS) {
        dateFormatter = formatDateTime;
    } else if (periodInMs > FOUR_WEEKS_MS && periodInMs <= ONE_YEAR_MS) {
        dateFormatter = formatDate;
    } else {
        dateFormatter = formatDateMonth;
    }
    return dateFormatter;
};
