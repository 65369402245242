import { FetchError, isFetchError } from "@flexidao/api-client";
import { NotificationProps, notifications } from "@mantine/notifications";
import { UseQueryResult } from "@tanstack/react-query";
import { ReactElement } from "react";

export type UseHandleQuerySkeletonFnArgs = {
    isLoading: boolean;
    isFetching: boolean;
    isPreviousData: boolean;
};

type UseHandleQueryProps<T> = {
    query: UseQueryResult<T | FetchError>;
    errorNotification?: NotificationProps;
    skeleton: ReactElement | ((args: UseHandleQuerySkeletonFnArgs) => ReactElement);
    content: (data: T) => ReactElement;
    error: ReactElement;
};

const isJSXElement = (value: any): value is ReactElement =>
    typeof value === "object" && value !== null && "$$typeof" in value;

export const ErrorHandlingQuery = <T>({
    query,
    children,
    error,
    errorNotification,
}: {
    query: UseQueryResult<T | FetchError>;
    errorNotification?: NotificationProps;
    children: (data: T) => ReactElement;
    error: ReactElement;
}): ReactElement => {
    if (query.isError || isFetchError(query.data) || !query.data) {
        console.error("Query error", query.data);
        if (errorNotification) {
            notifications.show(errorNotification);
        }
        return error;
    }
    return children(query.data);
};

export function useHandleQuery<T>({
    content,
    error,
    query,
    skeleton,
    errorNotification,
}: UseHandleQueryProps<T>): ReactElement {
    if (skeleton && (query.isLoading || query.isFetching)) {
        if (isJSXElement(skeleton)) {
            return skeleton;
        }
        return skeleton(query);
    }
    if (
        query.isError ||
        isFetchError(query.data) ||
        query.data === undefined ||
        query.data === null
    ) {
        console.error("Query error", query.data);
        if (errorNotification) {
            notifications.show(errorNotification);
        }
        return error;
    }
    return content(query.data);
}

type UseHandleQueryForTableProps<T> = {
    query: UseQueryResult<T | FetchError>;
    errorNotification?: NotificationProps;
    skeleton: ReactElement | ((args: UseHandleQuerySkeletonFnArgs) => ReactElement);
    content: (args: { data: T; showLoadingOverlay: boolean }) => ReactElement;
    error: ReactElement;
};
export function useHandleQueryForTable<T>({
    content,
    error,
    query,
    skeleton,
    errorNotification,
}: UseHandleQueryForTableProps<T>): ReactElement {
    if (skeleton && query.isLoading) {
        if (isJSXElement(skeleton)) {
            return skeleton;
        }
        return skeleton(query);
    }

    if (
        query.isError ||
        isFetchError(query.data) ||
        query.data === undefined ||
        query.data === null
    ) {
        console.error("Query error", query.data);
        if (errorNotification) {
            notifications.show(errorNotification);
        }
        return error;
    }

    return content({
        data: query.data,
        showLoadingOverlay: query.isFetching && query.isPreviousData,
    });
}

type UseHandleQueriesProps<T extends ReadonlyArray<any>> = {
    queries: T;
    skeleton: ReactElement;
    errorNotification?: NotificationProps;
    content: (
        data: [
            ...{
                [K in keyof T]: T[K] extends UseQueryResult<infer S | FetchError, unknown>
                    ? S
                    : never;
            },
        ],
    ) => ReactElement;
    error: ReactElement;
};

export const useHandleQueries = <T extends ReadonlyArray<any>>({
    queries,
    skeleton,
    content,
    error,
}: UseHandleQueriesProps<T>): ReactElement => {
    if (
        queries.some((query) => query.data === undefined && (query.isLoading || query.isFetching))
    ) {
        return skeleton;
    }

    const isQueryWithoutData = <T>(query: { data: T }): boolean =>
        query.data === null || query.data === undefined;

    if (
        queries.some((query) => query.isError) ||
        queries.some((query) => isFetchError(query.data)) ||
        queries.some((query) => isQueryWithoutData(query))
    ) {
        const errs: ReactElement[] = [];
        for (const query of queries) {
            if (isFetchError(query.data)) {
                const { kind, payload } = query.data;
                console.error(`useHandleQueries - '${kind}' FetchError\n`, payload);
                errs.push(error);
            }
            if (query.isError) {
                console.error(`useHandleQueries - generic error\n`, query.error);
                errs.push(error);
            }
            if (isQueryWithoutData(query)) {
                console.error("useHandleQueries - Unexpected no data for query.");
                errs.push(error);
            }
        }
        return error;
    }
    const queriesData = queries.map((query) => query.data);
    return content(queriesData as [...{ [K in keyof T]: T[K] }]);
};
