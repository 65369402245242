export const ONE_HUNDRED = 100;
export const ONE_THOUSAND = 1_000;
export const ONE_MILLION = 1_000_000;
export const ONE_BILLION = 1_000_000_000;
export const ONE_TRILLION = 1_000_000_000_000;

export const DAYS_IN_YEAR = 365;
export const HOURS_IN_DAY = 24;
export const DAYS_IN_WEEK = 7;
export const DAYS_IN_TWO_WEEKS = 14;
export const WEEKS_IN_MONTH = 4;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_MINUTE = 60;

export const SECOND_MS = ONE_THOUSAND;
export const MINUTE_MS = SECONDS_IN_MINUTE * SECOND_MS;
export const HOUR_MS = MINUTES_IN_HOUR * MINUTE_MS;
export const DAY_MS = HOURS_IN_DAY * HOUR_MS;
export const ONE_WEEK_MS = DAYS_IN_WEEK * DAY_MS;
export const TWO_WEEKS_MS = DAYS_IN_TWO_WEEKS * DAY_MS;

export const FOUR_WEEKS_MS = WEEKS_IN_MONTH * ONE_WEEK_MS;
export const ONE_YEAR_MS = DAYS_IN_YEAR * DAY_MS;

export const TWO_YEARS = 2;
export const TWO_YEAR_MS = TWO_YEARS * ONE_YEAR_MS;

export const NAVBAR_WIDTH = 240;
export const H1_FONT_SIZE_PX = 30;
export const H2_FONT_SIZE_PX = 24;
export const H3_FONT_SIZE_PX = 20;
export const H4_FONT_SIZE_PX = 18;

export const ENERGY_NUM_DECIMALS = 2;
export const PERCENTAGE_NUM_DECIMALS = 1;

export const getCurrentYear = (): number => new Date().getFullYear();
const firstMonthOfTheYear = 0;
const lastMonthOfTheYear = 11;
const firstDayOfJanuary = 1;
const lastDayOfDecember = 31;

export const getFirstDayOfCurrentYear = (): Date =>
    new Date(getCurrentYear(), firstMonthOfTheYear, firstDayOfJanuary, 0, 0, 0, 0);

export const getLastDayOfCurrentYear = (): Date =>
    new Date(getCurrentYear(), lastMonthOfTheYear, lastDayOfDecember, 0, 0, 0, 0);

export const getFirstDayOfLastYear = (): Date =>
    new Date(getCurrentYear() - 1, firstMonthOfTheYear, firstDayOfJanuary, 0, 0, 0, 0);

export const getLastDayOfLastYear = (): Date =>
    new Date(getCurrentYear() - 1, lastMonthOfTheYear, lastDayOfDecember, 0, 0, 0, 0);

export const getCurrentDate = (): Date => {
    const currentDate: Date = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return currentDate;
};

export const get7DaysAgo = (): Date => {
    const currentDate: Date = getCurrentDate();

    // Add 1 extra day to get the last 7 days, because the current day is included in the range
    const sevenDaysAgo: Date = new Date(currentDate.getTime() - ONE_WEEK_MS + DAY_MS);
    sevenDaysAgo.setHours(0, 0, 0, 0);

    return sevenDaysAgo;
};

export const getStartOfYearUTC = (year: number): Date => new Date(Date.UTC(year, 0, 1, 0, 0, 0, 0));
export const getLastDayOfYearUTC = (year: number): Date =>
    new Date(Date.UTC(year, lastMonthOfTheYear, lastDayOfDecember, 0, 0, 0, 0));
