import { IconWithTooltip, formatNumber, labelToDataId } from "@flexidao/ui-lib";
import { Group, Skeleton, Stack, Text, Title } from "@mantine/core";
import { ReactElement } from "react";
import { TooltipType } from "../../icon-with-tooltip/types";

export type KpiSectionLargeProps = {
    label: string;
    value: number | null;
    numDecimals: number;
    unit: string | null;
    tooltip?: TooltipType;
    dataId?: string;
    showSkeleton?: boolean;
};

export const KpiSectionLarge = ({
    label,
    value,
    numDecimals,
    unit,
    tooltip,
    dataId,
    showSkeleton,
}: KpiSectionLargeProps): ReactElement => {
    const valueString: string = formatNumber(value, numDecimals);
    const valueSplitArray: Array<string> = valueString.split(".");

    const integerPart: string = valueSplitArray[0]!;
    const decimalPart: string | null = valueSplitArray[1] ?? null;

    const unitSize = unit === "%" ? "18px" : "10px";
    const unitLineHeight = unit === "%" ? "23px" : "12px";

    return (
        <Stack spacing={0} px="xl" py={0} data-id={dataId}>
            <Group spacing={4}>
                <Title order={2} weight={600} size="14px" lh="16px">
                    {label}
                </Title>
                {tooltip && (
                    <IconWithTooltip
                        tooltip={tooltip}
                        data-id={labelToDataId({ prefix: dataId, label: "tooltip-content" })}
                    />
                )}
            </Group>

            {showSkeleton ? (
                <Skeleton height={30} width={200} />
            ) : (
                <Group spacing={2} align="baseline">
                    <Group spacing={0} align="baseline">
                        <Text weight="bold" ff="Lexend Deca" size="24px" lh="30px">
                            {integerPart}
                        </Text>

                        {decimalPart !== null && (
                            <Text weight="bold" ff="Lexend Deca" size="18px" lh="23px">
                                .{decimalPart}
                            </Text>
                        )}
                    </Group>

                    {value !== null && unit !== null && (
                        <Text weight="bold" size={unitSize} lh={unitLineHeight}>
                            {unit}
                        </Text>
                    )}
                </Group>
            )}
        </Stack>
    );
};
