import {
    ContractType,
    TwentyFourSevenDto,
    twentyFourSevenPaths,
    twentyFourSevenComponents,
    DataGranularity,
} from "@flexidao/dto";
import { ApiClient } from "../api-client";
import { twentyFourSevenDecoders } from "../decoders";
import { ApiResponse, ApiResultResponse, OverloadApiResponse } from "../types";
import { buildQueries, crash } from "../utils";
import { InferApiFunctionParams, InferApiFunctionReturn } from "../v2/helpers";
import {
    getSiteContractsResponseDecoder,
    getSitePerformanceByIdResponseDecoder,
    getSitesByRegionResponseDecoder,
} from "../decoders/twenty-four-seven";
import { convertDateUTCToISOString } from "@flexidao/helpers";

export type GetRegionsArgs = {
    getAccessToken: () => Promise<string>;
    tenantId: string;
};

export type GetHistoricalFinancialPerformanceByTenantIdArgs = {
    getAccessToken: () => Promise<string>;
    tenantId: string;
    year: number;
    contractIds?: Array<string>;
    granularity: DataGranularity.Monthly | DataGranularity.Daily;
};

export type GetKpisArgs = {
    getAccessToken: () => Promise<string>;
    tenantId: string;
    startTime: string;
    endTime: string;
    regionIds: Array<string>;
};

export type GetCfeCoverageArgs = {
    getAccessToken: () => Promise<string>;
    tenantId: string;
    startTime: string;
    endTime: string;
    regionIds: Array<string>;
};

export type GetContractsPerformanceArgs = {
    getAccessToken: () => Promise<string>;
    tenantId: string;
    startTime: string;
    endTime: string;
    regionIds: Array<string>;
};

type GetPendingWorkCountByTenantIdParams = InferApiFunctionParams<
    twentyFourSevenPaths,
    "/{tenantId}/pending-work"
>;
type GetPendingWorkCountByTenantIdReturn = InferApiFunctionReturn<
    twentyFourSevenPaths,
    "/{tenantId}/pending-work",
    "get"
>;

export type GetSitesByRegionsByTenantIdParams = InferApiFunctionParams<
    twentyFourSevenPaths,
    "/{tenantId}/consumption-sites-by-regions",
    "get"
>;

type GetSitesByRegionsByTenantIdReturn = InferApiFunctionReturn<
    twentyFourSevenPaths,
    "/{tenantId}/consumption-sites-by-regions",
    "get",
    200
>;

export type GetSitePerformanceByIdByTenantIdParams = InferApiFunctionParams<
    twentyFourSevenPaths,
    "/{tenantId}/site-performance-by-id",
    "get"
>;

type GetSitePerformanceByIdByTenantIdReturn = InferApiFunctionReturn<
    twentyFourSevenPaths,
    "/{tenantId}/site-performance-by-id",
    "get",
    200
>;

type GetSitesBySiteIdContractsByTenantIdReturn = InferApiFunctionReturn<
    twentyFourSevenPaths,
    "/{tenantId}/sites/{siteId}/contracts",
    "get",
    200
>;

export type GetSitesBySiteIdContractsByTenantIdParams = InferApiFunctionParams<
    twentyFourSevenPaths,
    "/{tenantId}/sites/{siteId}/contracts",
    "get"
>;

type GetCfeCoverageBySiteByTenantIdReturn = InferApiFunctionReturn<
    twentyFourSevenPaths,
    "/{tenantId}/cfe-coverage-by-site/{siteId}",
    "get",
    200
>;

export type GetCfeCoverageBySiteByTenantIdParams = InferApiFunctionParams<
    twentyFourSevenPaths,
    "/{tenantId}/cfe-coverage-by-site/{siteId}",
    "get"
>;

export type GetConsumptionSiteGroupsPerformanceArgs = {
    getAccessToken: () => Promise<string>;
} & twentyFourSevenPaths["/{tenantId}/consumption-site-groups-performance"]["get"]["parameters"]["query"] &
    twentyFourSevenPaths["/{tenantId}/consumption-site-groups-performance"]["get"]["parameters"]["path"];

export type GetContractsArgs = {
    getAccessToken: () => Promise<string>;
    tenantId: string;
    year?: number;
    contractType?: ContractType;
};

export type OverloadTwentyFourSevenClient = {
    getRegionsByTenantId: (
        args: GetRegionsArgs,
    ) => OverloadApiResponse<Array<TwentyFourSevenDto.Region>>;
    getKpisByTenantId: (args: GetKpisArgs) => OverloadApiResponse<TwentyFourSevenDto.Kpis>;
    getCfeCoverageByTenantId: (
        args: GetCfeCoverageArgs,
    ) => OverloadApiResponse<Array<TwentyFourSevenDto.CfeCoverage>>;
    getContractsPerformanceByTenantId: (
        args: GetContractsPerformanceArgs,
    ) => OverloadApiResponse<TwentyFourSevenDto.ContractsPerformanceResponse>;
    getConsumptionSiteGroupsPerformanceByTenantId: (
        args: GetConsumptionSiteGroupsPerformanceArgs,
    ) => OverloadApiResponse<
        twentyFourSevenComponents["schemas"]["ConsumptionSiteGroupsPerformanceResponse"]
    >;
    getContractsByTenantId: (
        args: GetContractsArgs,
    ) => OverloadApiResponse<Array<TwentyFourSevenDto.BaseContract>>;
    getHistoricalFinancialPerformanceByTenantId: (
        args: GetHistoricalFinancialPerformanceByTenantIdArgs,
    ) => OverloadApiResponse<TwentyFourSevenDto.HistoricalFinancialPerformance>;
    getPendingWorkByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetPendingWorkCountByTenantIdParams,
    ) => OverloadApiResponse<GetPendingWorkCountByTenantIdReturn>;
    getConsumptionSitesByRegionsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetSitesByRegionsByTenantIdParams,
    ) => OverloadApiResponse<GetSitesByRegionsByTenantIdReturn>;
    getSitePerformanceByIdByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetSitePerformanceByIdByTenantIdParams,
    ) => OverloadApiResponse<GetSitePerformanceByIdByTenantIdReturn>;
    getSitesBySiteIdContractsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetSitesBySiteIdContractsByTenantIdParams,
    ) => OverloadApiResponse<GetSitesBySiteIdContractsByTenantIdReturn>;
    getCfeCoverageBySiteBySiteIdByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetCfeCoverageBySiteByTenantIdParams,
    ) => OverloadApiResponse<GetCfeCoverageBySiteByTenantIdReturn>;
} & ApiClient<twentyFourSevenPaths>;

export type TwentyFourSevenClient = {
    getRegionsByTenantId: (args: GetRegionsArgs) => ApiResponse<Array<TwentyFourSevenDto.Region>>;
    getKpisByTenantId: (args: GetKpisArgs) => ApiResponse<TwentyFourSevenDto.Kpis>;
    getCfeCoverageByTenantId: (
        args: GetCfeCoverageArgs,
    ) => ApiResponse<Array<TwentyFourSevenDto.CfeCoverage>>;
    getContractsPerformanceByTenantId: (
        args: GetContractsPerformanceArgs,
    ) => ApiResponse<TwentyFourSevenDto.ContractsPerformanceResponse>;
    getConsumptionSiteGroupsPerformanceByTenantId: (
        args: GetConsumptionSiteGroupsPerformanceArgs,
    ) => ApiResponse<
        twentyFourSevenComponents["schemas"]["ConsumptionSiteGroupsPerformanceResponse"]
    >;
    getContractsByTenantId: (
        args: GetContractsArgs,
    ) => ApiResponse<Array<TwentyFourSevenDto.BaseContract>>;
    getHistoricalFinancialPerformanceByTenantId: (
        args: GetHistoricalFinancialPerformanceByTenantIdArgs,
    ) => ApiResponse<TwentyFourSevenDto.HistoricalFinancialPerformance>;
    getPendingWorkByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetPendingWorkCountByTenantIdParams,
    ) => ApiResponse<GetPendingWorkCountByTenantIdReturn>;
    getConsumptionSitesByRegionsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetSitesByRegionsByTenantIdParams,
    ) => ApiResponse<GetSitesByRegionsByTenantIdReturn>;
    getSitePerformanceByIdByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetSitePerformanceByIdByTenantIdParams,
    ) => ApiResponse<GetSitePerformanceByIdByTenantIdReturn>;
    getSitesBySiteIdContractsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetSitesBySiteIdContractsByTenantIdParams,
    ) => ApiResponse<GetSitesBySiteIdContractsByTenantIdReturn>;
    getCfeCoverageBySiteBySiteIdByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetCfeCoverageBySiteByTenantIdParams,
    ) => ApiResponse<GetCfeCoverageBySiteByTenantIdReturn>;
} & ApiClient<twentyFourSevenPaths>;

export type ResultTwentyFourSevenClient = {
    getRegionsByTenantId: (
        args: GetRegionsArgs,
    ) => ApiResultResponse<Array<TwentyFourSevenDto.Region>>;
    getKpisByTenantId: (args: GetKpisArgs) => ApiResultResponse<TwentyFourSevenDto.Kpis>;
    getCfeCoverageByTenantId: (
        args: GetCfeCoverageArgs,
    ) => ApiResultResponse<Array<TwentyFourSevenDto.CfeCoverage>>;
    getContractsPerformanceByTenantId: (
        args: GetContractsPerformanceArgs,
    ) => ApiResultResponse<TwentyFourSevenDto.ContractsPerformanceResponse>;
    getConsumptionSiteGroupsPerformanceByTenantId: (
        args: GetConsumptionSiteGroupsPerformanceArgs,
    ) => ApiResultResponse<
        twentyFourSevenComponents["schemas"]["ConsumptionSiteGroupsPerformanceResponse"]
    >;
    getContractsByTenantId: (
        args: GetContractsArgs,
    ) => ApiResultResponse<Array<TwentyFourSevenDto.BaseContract>>;
    getHistoricalFinancialPerformanceByTenantId: (
        args: GetHistoricalFinancialPerformanceByTenantIdArgs,
    ) => ApiResultResponse<TwentyFourSevenDto.HistoricalFinancialPerformance>;
    getPendingWorkByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetPendingWorkCountByTenantIdParams,
    ) => ApiResultResponse<GetPendingWorkCountByTenantIdReturn>;
    getConsumptionSitesByRegionsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetSitesByRegionsByTenantIdParams,
    ) => ApiResultResponse<GetSitesByRegionsByTenantIdReturn>;
    getSitePerformanceByIdByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetSitePerformanceByIdByTenantIdParams,
    ) => ApiResultResponse<GetSitePerformanceByIdByTenantIdReturn>;
    getSitesBySiteIdContractsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetSitesBySiteIdContractsByTenantIdParams,
    ) => ApiResultResponse<GetSitesBySiteIdContractsByTenantIdReturn>;
    getCfeCoverageBySiteBySiteIdByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetCfeCoverageBySiteByTenantIdParams,
    ) => ApiResultResponse<GetCfeCoverageBySiteByTenantIdReturn>;
} & ApiClient<twentyFourSevenPaths>;

export function getTwentyFourSevenClient(config: { useRetries?: boolean }): TwentyFourSevenClient;
export function getTwentyFourSevenClient(config: {
    useResult: boolean;
}): ResultTwentyFourSevenClient;
export function getTwentyFourSevenClient(config: {
    useResult?: boolean;
    useRetries?: boolean;
}): OverloadTwentyFourSevenClient {
    let url: string = "";

    let useMockData = false;
    if (typeof window !== "undefined") {
        url = window.location.origin;
    } else if (typeof process !== "undefined") {
        if (process.env.MOCK_TWENTY_FOUR_SEVEN == "true") {
            useMockData = true;
        }
        if (!process.env.TWENTY_FOUR_SEVEN_URL) {
            return crash("Missing TWENTY_FOUR_SEVEN_URL environment variable.");
        } else {
            url = process.env.TWENTY_FOUR_SEVEN_URL;
        }
    } else {
        return crash("No window or process, where the hell are we??!");
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    buildQueries({ ...config, useMockData: useMockData }, url);

    const { get } = buildQueries({ ...config, useMockData: useMockData }, url);

    return {
        getRegionsByTenantId: async ({
            getAccessToken,
            tenantId,
        }): ReturnType<TwentyFourSevenClient["getRegionsByTenantId"]> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/twenty-four-seven/${tenantId}/regions`,
                decoder: twentyFourSevenDecoders.regionArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getKpisByTenantId: async ({
            getAccessToken,
            tenantId,
            startTime,
            endTime,
            regionIds,
        }): OverloadApiResponse<TwentyFourSevenDto.Kpis> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/twenty-four-seven/${tenantId}/kpis`,
                decoder: twentyFourSevenDecoders.kpisDecoder,
                params: { startTime, endTime, regionIds },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getCfeCoverageByTenantId: async ({
            getAccessToken,
            tenantId,
            startTime,
            endTime,
            regionIds,
        }): OverloadApiResponse<Array<TwentyFourSevenDto.CfeCoverage>> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/twenty-four-seven/${tenantId}/cfe-coverage`,
                decoder: twentyFourSevenDecoders.cfeCoverageArrayDecoder,
                params: { startTime, endTime, regionIds },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getCfeCoverageBySiteBySiteIdByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId, siteId },
                    query: { endTime, startTime },
                },
            },
        ): ReturnType<TwentyFourSevenClient["getCfeCoverageBySiteBySiteIdByTenantId"]> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/twenty-four-seven/${tenantId}/cfe-coverage-by-site/${siteId}`,
                decoder: twentyFourSevenDecoders.cfeCoverageArrayDecoder,
                params: {
                    startTime: convertDateUTCToISOString(startTime),
                    endTime: convertDateUTCToISOString(endTime),
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractsPerformanceByTenantId: async ({
            getAccessToken,
            tenantId,
            startTime,
            endTime,
            regionIds,
        }): OverloadApiResponse<TwentyFourSevenDto.ContractsPerformanceResponse> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/twenty-four-seven/${tenantId}/contracts-performance`,
                decoder: twentyFourSevenDecoders.contractsPerformanceResponseDecoder,
                params: { startTime, endTime, regionIds },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getConsumptionSiteGroupsPerformanceByTenantId: async ({
            getAccessToken,
            tenantId,
            startTime,
            endTime,
            regionIds,
        }): OverloadApiResponse<
            twentyFourSevenComponents["schemas"]["ConsumptionSiteGroupsPerformanceResponse"]
        > => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/twenty-four-seven/${tenantId}/consumption-site-groups-performance`,
                decoder: twentyFourSevenDecoders.consumptionSiteGroupsPerformanceResponseDecoder,
                params: {
                    startTime: startTime.toISOString(),
                    endTime: endTime.toISOString(),
                    regionIds: [...(regionIds ?? [])],
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getContractsByTenantId: async ({
            getAccessToken,
            tenantId,
            year,
            contractType,
        }): OverloadApiResponse<Array<TwentyFourSevenDto.BaseContract>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/twenty-four-seven/${tenantId}/contracts`,
                decoder: twentyFourSevenDecoders.contractsResponseDecoder,
                params: {
                    ...(year && { year }),
                    ...(contractType && { contractType }),
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getHistoricalFinancialPerformanceByTenantId: async ({
            tenantId,
            getAccessToken,
            year,
            contractIds,
            granularity,
        }): OverloadApiResponse<TwentyFourSevenDto.HistoricalFinancialPerformance> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/twenty-four-seven/${tenantId}/historical-financial-performance`,
                decoder: twentyFourSevenDecoders.historicalPerformanceDecoder,
                params: {
                    year,
                    ...(contractIds && { contractIds }),
                    granularity,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getPendingWorkByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                },
            },
        ): ReturnType<TwentyFourSevenClient["getPendingWorkByTenantId"]> => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/twenty-four-seven/${tenantId}/pending-work`,
                decoder: twentyFourSevenDecoders.pendingWorkCountDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getConsumptionSitesByRegionsByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                    query,
                },
            },
        ): ReturnType<TwentyFourSevenClient["getConsumptionSitesByRegionsByTenantId"]> => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/twenty-four-seven/${tenantId}/consumption-sites-by-regions`,
                params: { regionIds: query?.regionIds ? [...query.regionIds] : undefined },
                decoder: getSitesByRegionResponseDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getSitePerformanceByIdByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                    query: { endTime, startTime, siteId },
                },
            },
        ): ReturnType<TwentyFourSevenClient["getSitePerformanceByIdByTenantId"]> => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/twenty-four-seven/${tenantId}/site-performance-by-id`,
                params: {
                    startTime: convertDateUTCToISOString(startTime),
                    endTime: convertDateUTCToISOString(endTime),
                    siteId,
                },
                decoder: getSitePerformanceByIdResponseDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getSitesBySiteIdContractsByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { siteId, tenantId },
                },
            },
        ): ReturnType<TwentyFourSevenClient["getSitesBySiteIdContractsByTenantId"]> => {
            const accessToken = await getAccessToken();
            return await get({
                path: `/api/twenty-four-seven/${tenantId}/sites/${siteId}/contracts`,
                decoder: getSiteContractsResponseDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        putAdminEventsTrigger: async () => Promise.reject("Not for internal or UI usage"),
        getAdminInspectIndicators: async () => Promise.reject("Not for internal or UI usage"),
        getAdminInspectPendingWork: async () => Promise.reject("Not for internal or UI usage"),
        getSyncContractedBySiteIdByTenantId: async () =>
            Promise.reject("Not for internal or UI usage"),
        postAdminBackgroundWorkerTrigger: async () =>
            Promise.reject("Not for internal or UI usage"),
        getAdminContractsByContractId: async () => Promise.reject("Not for internal or UI usage"),
        getAdminSitesBySiteId: async () => Promise.reject("Not for internal or UI usage"),
        getAdminByTenantIdConsumptionSiteGroupsByConsumptionSiteGroupId: async () =>
            Promise.reject("Not for internal or UI usage"),
        postContractPricesByTenantId: async () => Promise.reject("Not for internal or UI usage"),
        postProcessFinancialPerformanceByTenantId: async () =>
            Promise.reject("Not for internal or UI usage"),
        getAdminTenantsByTenantId: async () => Promise.reject("Not for internal or UI usage"),
    };
}
