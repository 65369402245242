import { ReactElement, ReactNode } from "react";
import { FloatingPosition } from "@mantine/core/lib/Floating";

export enum TooltipIconType {
    Info = "info",
    Warning = "warning",
    Custom = "custom",
}

export type InfoTooltip = {
    iconType: TooltipIconType.Info;
    content: ReactNode;
    maxWidth?: number;
    size?: number;
    position?: FloatingPosition;
    onClick?: () => void;
};

export type WarningTooltip = {
    iconType: TooltipIconType.Warning;
    content: ReactNode;
    maxWidth?: number;
    size?: number;
    position?: FloatingPosition;
    onClick?: () => void;
};

export type CustomTooltip = {
    iconType: TooltipIconType.Custom;
    content: ReactNode;
    maxWidth?: number;
    icon: ReactElement;
    position?: FloatingPosition;
    onClick?: () => void;
};

export type TooltipType = InfoTooltip | WarningTooltip | CustomTooltip;
