import { Box, Group } from "@mantine/core";
import { LegendOrdinal } from "@visx/legend";
import { ScaleOrdinal } from "d3-scale";
import { ChartDimensions, INACTIVE_LEGEND_OPACITY, LegendDotWithLabel } from "../utils";
import { StackedAreasSeries } from "./stacked-areas";
import { ReactElement } from "react";
import { halve } from "../../../utils";

type StackedAreasLegendProps<T> = {
    dimensions: ChartDimensions;
    colorScale: ScaleOrdinal<string, string, never>;
    series: StackedAreasSeries<T>[];
    toggleActiveSeries: (label: string) => void;
};

const isActive = <T,>(labelCode: string, series: StackedAreasSeries<T>[]): boolean =>
    series.some(({ key, active }) => key === labelCode && active);

export const Legend = <T,>({
    colorScale,
    series,
    toggleActiveSeries,
    dimensions: { margin },
}: StackedAreasLegendProps<T>): ReactElement => {
    return (
        <Box pos="absolute" w="100%" bottom={halve(margin.bottom)}>
            <LegendOrdinal
                scale={colorScale}
                labelFormat={(label): string => `${label.toUpperCase()}`}
            >
                {(labels): JSX.Element => (
                    <Group spacing="lg">
                        {labels.map((label, i) => {
                            const isActiveLabel = isActive(label.datum, series);
                            const labelText =
                                series.find(({ key }) => key === label.datum)?.label ?? "";

                            return (
                                <LegendDotWithLabel
                                    key={i}
                                    onClick={(): void => {
                                        toggleActiveSeries(label.datum);
                                    }}
                                    sx={{
                                        opacity: isActiveLabel ? 1 : INACTIVE_LEGEND_OPACITY,
                                        cursor: "pointer",
                                    }}
                                    text={labelText}
                                    color={label.value ?? "black"}
                                />
                            );
                        })}
                    </Group>
                )}
            </LegendOrdinal>
        </Box>
    );
};
