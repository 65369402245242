import { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { readFileSync } from "fs";

function join(...args: string[]): string {
    let parts: string[] = [];
    for (const arg of args) {
        parts = parts.concat(arg.split("/"));
    }
    const newParts: string[] = [];
    for (let i = 0, l = parts.length; i < l; i++) {
        const part = parts[i];
        if (!part || part === ".") continue;
        if (part === "..") newParts.pop();
        else newParts.push(part);
    }
    if (parts[0] === "") newParts.unshift("");
    return newParts.join("/") || (newParts.length ? "/" : ".");
}

export const mockAxiosClient = {
    get: (url: string): unknown => {
        const file = readFileSync(join(__dirname, `./${url}/get.json`));
        const mockedData = JSON.parse(file.toString());
        const result: AxiosResponse = {
            data: mockedData.data,
            status: mockedData.status,
            statusText: mockedData.statusText,
            headers: mockedData.headers,
            config: {} as InternalAxiosRequestConfig,
        };

        return result;
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars
    post: (url: string, _body: any): unknown => {
        const file = readFileSync(join(__dirname, `./${url}/post.json`));
        const mockedData = JSON.parse(file.toString());
        const result: AxiosResponse = {
            data: mockedData.data,
            status: mockedData.status,
            statusText: mockedData.statusText,
            headers: mockedData.headers,
            config: {} as InternalAxiosRequestConfig,
        };

        return result;
    },
};
