import { formatNumber } from "@flexidao/ui-lib";
import { Group, Skeleton, Text } from "@mantine/core";
import { ReactElement } from "react";

export type KpiSectionSmallProps = {
    label: string;
    value: number | null;
    numDecimals: number;
    unit: string | null;
    dataId?: string;
    showSkeleton?: boolean;
};

export const KpiSectionSmall = ({
    label,
    value,
    numDecimals,
    unit,
    dataId,
    showSkeleton,
}: KpiSectionSmallProps): ReactElement => {
    const valueString: string = formatNumber(value, numDecimals);

    return (
        <Group position="apart" align="flex-start" px="xl" py={8} mih={40} data-id={dataId}>
            <Text size="sm" weight={600}>
                {label}
            </Text>

            {showSkeleton ? (
                <Skeleton height={21} width={100} />
            ) : (
                <Text size="sm" weight={600}>
                    {valueString} {value !== null && unit !== null && unit}
                </Text>
            )}
        </Group>
    );
};
