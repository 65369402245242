import { useAppUser } from "./app-user";

/**
 * Hook to determine if the active tenant is a premium tenant using the user from BIM Context
 * @returns Whether the active tenant is a premium tenant
 */
export const useIsPremiumTenant = (): boolean => {
    const { user } = useAppUser();
    if (!user) {
        return false;
    }
    return user.activeTenant.premium;
};
