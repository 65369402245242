import { ReactNode } from "react";

type BooleanDisplayComponentProps = {
    content: ReactNode;
    fallback?: ReactNode;
    skeleton?: ReactNode;
    condition: boolean;
    isLoading?: boolean;
};
/**
 * Contains logic for displaying `content`/`fallback`/`skeleton` components depending on `condition`.
 */
export const BooleanDisplayComponent = ({
    content,
    fallback = null,
    skeleton = null,
    isLoading = false,
    condition,
}: BooleanDisplayComponentProps): JSX.Element => {
    if (isLoading) {
        return <>{skeleton}</>;
    }
    return condition ? <>{content}</> : <>{fallback}</>;
};
