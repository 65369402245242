import { CSSObject, Group, MantineNumberSize, Paper, Sx, Text } from "@mantine/core";
import { TickFormatter } from "@visx/axis";
import { ScaleInput, ScaleTypeToD3Scale } from "@visx/scale";
import { TooltipWithBounds } from "@visx/tooltip";
import { timeFormat, utcFormat } from "d3-time-format";
import { ReactNode } from "react";
import { DAY_MS } from "../../consts";
import {
    formatDate,
    formatDateHour,
    formatDateTime,
    formatMonth,
    physicalRoundForElectricity,
} from "@flexidao/ui-lib/utils";
import { labelToDataId } from "@flexidao/ui-lib/components";
import { DataGranularity } from "@flexidao/dto";

export const INACTIVE_LEGEND_OPACITY = 0.3;

const fullTimeFormatSpecifier = "%H:%M:%S";

export const getDayHourDateRangeFormat = (startTime: Date, endTime: Date): string => {
    return (
        utcFormat("%d/%m/%Y %H:%M:%S")(startTime) + " - " + utcFormat("%d/%m/%Y %H:%M:%S")(endTime)
    );
};
export const getDayDateRangeFormat = (startTime: Date, endTime: Date): string => {
    return utcFormat("%d/%m/%Y")(startTime) + " - " + utcFormat("%d/%m/%Y")(endTime);
};

export const getOnlyHourRangeFormat = (startTime: Date, endTime: Date): string => {
    return (
        utcFormat(fullTimeFormatSpecifier)(startTime) +
        " - " +
        utcFormat(fullTimeFormatSpecifier)(endTime)
    );
};

export const dateHourTickFormatter = (
    tick: Date | { valueOf(): number },
    index: number,
    values: {
        value: Date | { valueOf(): number };
        index: number;
    }[],
): string => {
    const length = values.length;
    if (index === 0) return "";
    if (index === length - 1) return "24h";

    const ms = tick instanceof Date ? tick : new Date(tick.valueOf());
    return timeFormat("%H h")(ms);
};

export const hourTickFormatter = (tick: Date | { valueOf(): number }, index: number): string => {
    if (index === 0) return "";

    const ms = tick instanceof Date ? tick : new Date(tick.valueOf());
    return timeFormat("%d %b %H h")(ms);
};
export const dayTickFormatter = (tick: Date | { valueOf(): number }, _: number): string => {
    //if (index === 0) return "";

    const ms = tick instanceof Date ? tick : new Date(tick.valueOf());
    return timeFormat("%d %b")(ms);
};

// eslint-disable-next-line no-magic-numbers
const THREE_DAYS_MS = 3 * DAY_MS;

export const getXTickFormatter =
    (minStartDate: Date | null, maxEndDate: Date | null, granularity?: DataGranularity) =>
    (date_: Date | { valueOf(): number }): string => {
        if (!minStartDate || !maxEndDate) {
            return "-";
        }

        const minStartTimeMs: number = minStartDate.getTime() ?? 0;
        const maxEndTimeMs: number = maxEndDate.getTime() ?? 0;

        const isLessThan1Day: boolean = maxEndTimeMs - minStartTimeMs < DAY_MS;
        const isLessThan3Days: boolean = maxEndTimeMs - minStartTimeMs < THREE_DAYS_MS;

        const date: Date = date_ instanceof Date ? date_ : new Date(date_.valueOf());

        if ((granularity == null || granularity === DataGranularity.Hourly) && isLessThan1Day) {
            return formatDateTime(date, "UTC");
        }

        if ((granularity == null || granularity === DataGranularity.Hourly) && isLessThan3Days) {
            return formatDateHour(date, "UTC");
        }

        return formatDate(date, "UTC");
    };

export const getTooltipDateFormatter =
    (granularity: DataGranularity) =>
    (date_: Date | { valueOf(): number }): string => {
        const date: Date = date_ instanceof Date ? date_ : new Date(date_.valueOf());

        switch (granularity) {
            case DataGranularity.Hourly:
                return formatDateTime(date, "UTC");
            case DataGranularity.Daily:
                return formatDate(date, "UTC");
            case DataGranularity.Monthly:
                return formatMonth(date, "UTC");
        }
    };

export const dateTickFormatter =
    (minStartDate: Date, maxEndDate: Date): TickFormatter<ScaleInput<ScaleTypeToD3Scale["time"]>> =>
    (tick: Date | { valueOf(): number }): string => {
        const ms = tick instanceof Date ? tick : new Date(tick.valueOf());
        const isLessThan3Days = maxEndDate.getTime() - minStartDate.getTime() < THREE_DAYS_MS;
        if (isLessThan3Days) {
            return timeFormat("%d %b %H h")(ms);
        } else {
            return timeFormat("%d %b")(ms);
        }
    };

export const wattsTickFormatter: TickFormatter<ScaleInput<ScaleTypeToD3Scale["linear"]>> = (
    tick: number | { valueOf(): number },
): string => {
    const v = typeof tick === "number" ? tick : tick.valueOf();
    const decimals = 2;
    const [value, magnitude] = physicalRoundForElectricity(v, decimals);
    return value + " " + magnitude + "W";
};

export const energyTickFormatter: TickFormatter<ScaleInput<ScaleTypeToD3Scale["linear"]>> = (
    tick: number | { valueOf(): number },
): string => {
    const v = typeof tick === "number" ? tick : tick.valueOf();
    const [value, magnitude] = physicalRoundForElectricity(v, 0);
    return value + " " + magnitude + "Wh";
};

const LAST_DAY_JANUARY = 31;
const LAST_DAY_FEBRUARY = 59;
const LAST_DAY_MARCH = 90;
const LAST_DAY_APRIL = 120;
const LAST_DAY_MAY = 151;
const LAST_DAY_JUNE = 181;
const LAST_DAY_JULY = 211;
const LAST_DAY_AUGUST = 242;
const LAST_DAY_SEPTEMBER = 272;
const LAST_DAY_OCTOBER = 303;
const LAST_DAY_NOVEMBER = 333;

export const heatmapDaysToMonthTickFormatter = (tick_: number | { valueOf(): number }): string => {
    const tick = typeof tick_ === "number" ? tick_ : tick_.valueOf();

    if (tick >= 0 && tick <= LAST_DAY_JANUARY) {
        return "Jan";
    } else if (tick > LAST_DAY_JANUARY && tick <= LAST_DAY_FEBRUARY) {
        return "Feb";
    } else if (tick > LAST_DAY_FEBRUARY && tick <= LAST_DAY_MARCH) {
        return "Mar";
    } else if (tick > LAST_DAY_MARCH && tick <= LAST_DAY_APRIL) {
        return "Apr";
    } else if (tick > LAST_DAY_APRIL && tick <= LAST_DAY_MAY) {
        return "May";
    } else if (tick > LAST_DAY_MAY && tick <= LAST_DAY_JUNE) {
        return "Jun";
    } else if (tick > LAST_DAY_JUNE && tick <= LAST_DAY_JULY) {
        return "Jul";
    } else if (tick > LAST_DAY_JULY && tick <= LAST_DAY_AUGUST) {
        return "Aug";
    } else if (tick > LAST_DAY_AUGUST && tick <= LAST_DAY_SEPTEMBER) {
        return "Sep";
    } else if (tick > LAST_DAY_SEPTEMBER && tick <= LAST_DAY_OCTOBER) {
        return "Oct";
    } else if (tick > LAST_DAY_OCTOBER && tick <= LAST_DAY_NOVEMBER) {
        return "Nov";
    } else {
        return "Dec";
    }
};

export const AM_LABEL_HOUR = 6;
export const PM_LABEL_HOUR = 18;

export const heatmapPartOfTheDayTickFormatter = (tick: number | { valueOf(): number }): string => {
    if (tick === AM_LABEL_HOUR) {
        return "AM";
    }
    if (tick === PM_LABEL_HOUR) {
        return "PM";
    }
    return "";
};

export type ChartMargin = {
    top: number;
    right: number;
    bottom: number;
    left: number;
};

export type ChartDimensions = {
    margin: ChartMargin;
    parentHeight: number;
    parentWidth: number;
    innerHeight: number;
    innerWidth: number;
};

export type ChartDisplayOptions = {
    showAxes: {
        left: boolean;
        right: boolean;
        bottom: boolean;
    };
    showTooltip: boolean;
    showLegend: boolean;
    justifyLegend: "start" | "center" | "end";
    showGrid: boolean;
};

export const defaultChartDisplayOptions: ChartDisplayOptions = {
    showAxes: {
        left: true,
        bottom: true,
        right: false,
    },
    showTooltip: true,
    showLegend: true,
    justifyLegend: "start",
    showGrid: false,
};
export const AXIS_FONT_SIZE = 12;
export const LEGEND_FONT_SIZE: MantineNumberSize = 10;

type LegendDotWithLabelProps = {
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    sx?: Sx;
    text: string;
    color: string;
};
export const LegendDotWithLabel = ({
    onClick,
    text,
    color,
    sx = {},
}: LegendDotWithLabelProps): JSX.Element => {
    return (
        <Group
            key={`legend-${text.replace(" ", "-")}`}
            onClick={onClick}
            sx={sx}
            spacing={4}
            data-id={labelToDataId({
                prefix: "legend-item-dot",
                label: text,
            })}
        >
            <svg width={12} height={12}>
                <circle fill={color} r={6} cx={6} cy={6} />
            </svg>
            <Text fz={LEGEND_FONT_SIZE}>{text}</Text>
        </Group>
    );
};

type LegendLineWithLabelProps = {
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    sx?: Sx;
    text: string;
    color: string;
};
export const LegendLineWithLabel = ({
    onClick,
    text,
    color,
    sx = {},
}: LegendLineWithLabelProps): JSX.Element => {
    return (
        <Group
            key={`legend-${text.replace(" ", "-")}`}
            onClick={onClick}
            sx={sx}
            spacing={4}
            data-id={labelToDataId({
                prefix: "histogram-chart--legend-item-line",
                label: text,
            })}
        >
            <svg width={12} height={12}>
                <rect fill={color} height={2} width={3} x={0} y={5} rx={1} />
                <rect fill={color} height={2} width={4} x={4} y={5} rx={1} />
                <rect fill={color} height={2} width={3} x={9} y={5} rx={1} />
            </svg>
            <Text fz={LEGEND_FONT_SIZE}>{text}</Text>
        </Group>
    );
};

type TooltipContainerProps = {
    children: ReactNode;
    tooltipTop: number;
    tooltipLeft: number;
    pad?: boolean;
};

const TOOLTIP_TOP_OFFSET = 12;
const TOOLTIP_LEFT_OFFSET = 2;
const TOOLTIP_MIN_WIDTH = 120;
const TOOLTIP_Z_INDEX = 101;

export const TooltipContainer = ({
    children,
    tooltipTop,
    tooltipLeft,
    pad = true,
}: TooltipContainerProps): JSX.Element => {
    return (
        <TooltipWithBounds
            key={Math.random()}
            top={tooltipTop - TOOLTIP_TOP_OFFSET}
            left={tooltipLeft + TOOLTIP_LEFT_OFFSET}
            style={{
                position: "absolute",
                minWidth: 60,
                minHeight: 80,
                pointerEvents: "none",
            }}
        >
            <Paper
                sx={(theme): CSSObject => ({
                    backgroundColor: theme.white,
                    padding: pad ? theme.spacing.lg : 0,
                    zIndex: TOOLTIP_Z_INDEX,
                })}
                shadow="xl"
                miw={TOOLTIP_MIN_WIDTH}
                data-id="chart--tooltip"
            >
                {children}
            </Paper>
        </TooltipWithBounds>
    );
};

export const getDefaultRightAxisTickLabelProps = (
    axisColor: string,
    axisFontSize: number,
): {
    fill: string;
    fontSize: number;
    textAnchor: string;
    dy: string;
    dx: string;
} => {
    return {
        fill: axisColor,
        fontSize: axisFontSize,
        textAnchor: "start",
        dy: "0.33em",
        dx: "0.5em",
    };
};
