import { BimDto, bimPaths } from "@flexidao/dto";
import { ApiClient } from "../api-client";
import {
    baseConsumptionSiteGroupArrayDecoder,
    baseConsumptionSiteGroupDecoder,
    consumptionSiteGroupDetailDecoder,
    contractDecoder,
    countriesWithZonesArrayDecoder,
    currencyArrayDecoder,
    deleteResponseDecoder,
    energySourcesArrayDecoder,
    featureFlagDecoder,
    getAllContractsDecoder,
    getAllSitesDecoder,
    getConsumptionSitesSummaryDecoder,
    getContractsFiltersDecoder,
    getSitesFiltersDecoder,
    getUsersDecoder,
    noContentDecoder,
    postAdminAuthorizeDecoder,
    regionsArrayDecoder,
    selfDecoder,
    siteDecoder,
    sitesArrayDecoder,
    siteSummaryArrayDecoder,
    tenantDecoder,
    userDecoder,
} from "../decoders";

import { ApiResponse, ApiResultResponse, OverloadApiResponse } from "../types";
import { buildQueries, crash } from "../utils";

type GetUsersByTenantIdArgs = {
    getAccessToken: () => Promise<string>;
    tenantId: string;
    page: number;
    search?: string;
};

type GetSitesByTenantIdArgs = {
    getAccessToken: () => Promise<string>;
    tenantId: string;
    pageIndex?: number;
    pageSize?: number;
    searchQuery?: string;
    orderBy?: string;
    orderDirection?: "asc" | "desc";
    filters?: Record<string, string | boolean>;
};

type GetSitesFiltersByTenantIdArgs = {
    getAccessToken: () => Promise<string>;
    tenantId: string;
};

type GetContractsByTenantIdArgs = {
    getAccessToken: () => Promise<string>;
    tenantId: string;
    pageIndex?: number;
    pageSize?: number;
    searchQuery?: string;
    orderBy?: string;
    orderDirection?: "asc" | "desc";
    filters?: Record<string, string>;
};

type GetContractsFiltersByTenantIdArgs = {
    getAccessToken: () => Promise<string>;
    tenantId: string;
};

type PatchActiveTenantArgs = {
    getAccessToken: () => Promise<string>;
    tenantId: string;
};

export type BimClient = {
    // Tenants
    getTenantByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => ApiResponse<BimDto.Tenant>;

    // Authorization/Self
    postAdminAuthorize: (
        bimKey: string,
        body: BimDto.AuthorizeActionPayload,
    ) => ApiResponse<BimDto.AuthorizeActionResponse>;
    getAppUser: (getAccessToken: () => Promise<string>) => ApiResponse<BimDto.AppUser>;
    patchAppUserActiveTenant: (args: PatchActiveTenantArgs) => ApiResponse<BimDto.AppUser>;

    // Users
    getUsersByTenantId: (args: GetUsersByTenantIdArgs) => ApiResponse<BimDto.GetUsersResponse>;
    deleteUsersByUserIdByTenantId: (
        getAccessToken: () => Promise<string>,
        userId: string,
        tenantId: string,
    ) => ApiResponse<{ success: boolean }>;
    getUsersByUserIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        userId: string,
    ) => ApiResponse<BimDto.User>;

    // Sites
    getSitesConsumptionByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => ApiResponse<BimDto.ConsumptionSitesSummary>;
    getSitesProductionByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => ApiResponse<Array<BimDto.SiteSummary>>;
    getSitesByTenantId: (
        args: GetSitesByTenantIdArgs,
    ) => ApiResponse<BimDto.GetSitesByTenantIdResponse>;
    getSitesFiltersByTenantId: (
        args: GetSitesFiltersByTenantIdArgs,
    ) => ApiResponse<BimDto.GetSitesFiltersResponse>;
    getSitesBySiteIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        siteId: string,
    ) => ApiResponse<BimDto.Site>;
    postSitesByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        body: BimDto.UpsertSitePayload,
    ) => ApiResponse<Array<BimDto.Site>>;
    putSitesBySiteIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        siteId: string,
        body: BimDto.UpsertSitePayload,
    ) => ApiResponse<BimDto.Site>;
    deleteSitesBySiteIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        siteId: string,
    ) => ApiResponse<undefined>;

    // Contracts
    getContractsByTenantId: (
        args: GetContractsByTenantIdArgs,
    ) => ApiResponse<BimDto.GetContractsResponse>;
    getContractsFiltersByTenantId: (
        params: GetContractsFiltersByTenantIdArgs,
    ) => ApiResponse<BimDto.GetContractsFiltersResponse>;
    getContractsByContractIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        siteId: string,
    ) => ApiResponse<BimDto.Contract>;
    postContractsByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        body: BimDto.ContractPayload,
    ) => ApiResponse<BimDto.Contract>;
    putContractsByContractIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        contractId: string,
        body: BimDto.ContractPayload,
    ) => ApiResponse<BimDto.Contract>;
    deleteContractsByContractIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        contractId: string,
    ) => ApiResponse<undefined>;

    // Misc
    getPublicFeatureFlags: (getAccessToken: () => Promise<string>) => ApiResponse<Array<string>>;
    getPublicCountries: (
        getAccessToken: () => Promise<string>,
    ) => ApiResponse<Array<BimDto.CountryWithZones>>;
    getPublicEnergySources: (
        getAccessToken: () => Promise<string>,
    ) => ApiResponse<Array<BimDto.EnergySource>>;
    getPublicCurrencies: (
        getAccessToken: () => Promise<string>,
    ) => ApiResponse<Array<BimDto.Currency>>;
    getPublicRegions: (getAccessToken: () => Promise<string>) => ApiResponse<Array<BimDto.Region>>;

    // Consumption Site Group
    getConsumptionSiteGroupByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => ApiResponse<Array<BimDto.BaseConsumptionSiteGroup>>;
    postConsumptionSiteGroupByTenantId: (
        getAccessToken: () => Promise<string>,
        name: string,
        tenantId: string,
    ) => ApiResponse<BimDto.BaseConsumptionSiteGroup>;
    getConsumptionSiteGroupByConsumptionSiteGroupIdByTenantId: (
        getAccessToken: () => Promise<string>,
        consumptionSiteGroupId: string,
        tenantId: string,
    ) => ApiResponse<BimDto.ConsumptionSiteGroupDetail>;
    deleteConsumptionSiteGroupByConsumptionSiteGroupIdByTenantId: (
        getAccessToken: () => Promise<string>,
        consumptionSiteGroupId: string,
        tenantId: string,
    ) => ApiResponse<void>;
    patchConsumptionSiteGroupByConsumptionSiteGroupIdByTenantId: (
        getAccessToken: () => Promise<string>,
        name: string,
        consumptionSiteGroupId: string,
        tenantId: string,
    ) => ApiResponse<BimDto.BaseConsumptionSiteGroup>;
} & ApiClient<bimPaths>;

export type ResultBimClient = {
    getTenantByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => ApiResultResponse<BimDto.Tenant>;
    getTenants: (getAccessToken: () => Promise<string>) => ApiResultResponse<Array<BimDto.Tenant>>;

    // Authorization/Self
    postAdminAuthorize: (
        bimKey: string,
        body: BimDto.AuthorizeActionPayload,
    ) => ApiResultResponse<BimDto.AuthorizeActionResponse>;
    getAppUser: (getAccessToken: () => Promise<string>) => ApiResultResponse<BimDto.AppUser>;
    patchAppUserActiveTenant: (args: PatchActiveTenantArgs) => ApiResultResponse<BimDto.AppUser>;

    // Users
    getUsersByTenantId: (
        args: GetUsersByTenantIdArgs,
    ) => ApiResultResponse<BimDto.GetUsersResponse>;
    deleteUsersByUserIdByTenantId: (
        getAccessToken: () => Promise<string>,
        userId: string,
        tenantId: string,
    ) => ApiResultResponse<{ success: boolean }>;
    getUsersByUserIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        userId: string,
    ) => ApiResultResponse<BimDto.User>;

    // Sites
    getSitesConsumptionByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => ApiResultResponse<BimDto.ConsumptionSitesSummary>;
    getSitesProductionByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => ApiResultResponse<Array<BimDto.SiteSummary>>;
    getSitesByTenantId: (
        args: GetSitesByTenantIdArgs,
    ) => ApiResultResponse<BimDto.GetSitesByTenantIdResponse>;
    getSitesFiltersByTenantId: (
        args: GetSitesFiltersByTenantIdArgs,
    ) => ApiResultResponse<BimDto.GetSitesFiltersResponse>;
    getSitesBySiteIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        siteId: string,
    ) => ApiResultResponse<BimDto.Site>;
    postSitesByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        body: BimDto.UpsertSitePayload,
    ) => ApiResultResponse<Array<BimDto.Site>>;
    putSitesBySiteIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        siteId: string,
        body: BimDto.UpsertSitePayload,
    ) => ApiResultResponse<BimDto.Site>;
    deleteSitesBySiteIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        siteId: string,
    ) => ApiResultResponse<undefined>;

    // Contracts
    getContractsByTenantId: (
        args: GetContractsByTenantIdArgs,
    ) => ApiResultResponse<BimDto.GetContractsResponse>;
    getContractsFiltersByTenantId: (
        params: GetContractsFiltersByTenantIdArgs,
    ) => ApiResultResponse<BimDto.GetContractsFiltersResponse>;
    getContractsByContractIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        siteId: string,
    ) => ApiResultResponse<BimDto.Contract>;
    postContractsByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        body: BimDto.ContractPayload,
    ) => ApiResultResponse<BimDto.Contract>;
    putContractsByContractIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        contractId: string,
        body: BimDto.ContractPayload,
    ) => ApiResultResponse<BimDto.Contract>;
    deleteContractsByContractIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        contractId: string,
    ) => ApiResultResponse<undefined>;

    // Misc
    getPublicFeatureFlags: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<string>>;
    getPublicCountries: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<BimDto.CountryWithZones>>;
    getPublicEnergySources: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<BimDto.EnergySource>>;
    getPublicCurrencies: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<BimDto.Currency>>;
    getPublicRegions: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<BimDto.Region>>;

    // Consumption Site Group
    getConsumptionSiteGroupByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => ApiResultResponse<Array<BimDto.BaseConsumptionSiteGroup>>;
    postConsumptionSiteGroupByTenantId: (
        getAccessToken: () => Promise<string>,
        name: string,
        tenantId: string,
    ) => ApiResultResponse<BimDto.BaseConsumptionSiteGroup>;
    getConsumptionSiteGroupByConsumptionSiteGroupIdByTenantId: (
        getAccessToken: () => Promise<string>,
        consumptionSiteGroupId: string,
        tenantId: string,
    ) => ApiResultResponse<BimDto.ConsumptionSiteGroupDetail>;
    deleteConsumptionSiteGroupByConsumptionSiteGroupIdByTenantId: (
        getAccessToken: () => Promise<string>,
        consumptionSiteGroupId: string,
        tenantId: string,
    ) => ApiResultResponse<void>;
    patchConsumptionSiteGroupByConsumptionSiteGroupIdByTenantId: (
        getAccessToken: () => Promise<string>,
        name: string,
        consumptionSiteGroupId: string,
        tenantId: string,
    ) => ApiResultResponse<BimDto.BaseConsumptionSiteGroup>;
} & ApiClient<bimPaths>;

export type OverloadBimClient = {
    getTenantByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => OverloadApiResponse<BimDto.Tenant>;

    // Authorization/Self
    postAdminAuthorize: (
        bimKey: string,
        body: BimDto.AuthorizeActionPayload,
    ) => OverloadApiResponse<BimDto.AuthorizeActionResponse>;
    getAppUser: (getAccessToken: () => Promise<string>) => OverloadApiResponse<BimDto.AppUser>;
    patchAppUserActiveTenant: (args: PatchActiveTenantArgs) => OverloadApiResponse<BimDto.AppUser>;

    // Users
    getUsersByTenantId: (
        args: GetUsersByTenantIdArgs,
    ) => OverloadApiResponse<BimDto.GetUsersResponse>;
    deleteUsersByUserIdByTenantId: (
        getAccessToken: () => Promise<string>,
        userId: string,
        tenantId: string,
    ) => OverloadApiResponse<{ success: boolean }>;
    getUsersByUserIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        userId: string,
    ) => OverloadApiResponse<BimDto.User>;

    // Sites
    getSitesConsumptionByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => OverloadApiResponse<BimDto.ConsumptionSitesSummary>;
    getSitesProductionByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => OverloadApiResponse<Array<BimDto.SiteSummary>>;
    getSitesByTenantId: (
        args: GetSitesByTenantIdArgs,
    ) => OverloadApiResponse<BimDto.GetSitesByTenantIdResponse>;
    getSitesFiltersByTenantId: (
        args: GetSitesFiltersByTenantIdArgs,
    ) => OverloadApiResponse<BimDto.GetSitesFiltersResponse>;
    getSitesBySiteIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        siteId: string,
    ) => OverloadApiResponse<BimDto.Site>;
    postSitesByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        body: BimDto.UpsertSitePayload,
    ) => OverloadApiResponse<Array<BimDto.Site>>;
    putSitesBySiteIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        siteId: string,
        body: BimDto.UpsertSitePayload,
    ) => OverloadApiResponse<BimDto.Site>;
    deleteSitesBySiteIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        siteId: string,
    ) => OverloadApiResponse<undefined>;

    // Contracts
    getContractsByTenantId: (
        args: GetContractsByTenantIdArgs,
    ) => OverloadApiResponse<BimDto.GetContractsResponse>;
    getContractsFiltersByTenantId: (
        params: GetContractsFiltersByTenantIdArgs,
    ) => OverloadApiResponse<BimDto.GetContractsFiltersResponse>;
    getContractsByContractIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        siteId: string,
    ) => OverloadApiResponse<BimDto.Contract>;
    postContractsByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        body: BimDto.ContractPayload,
    ) => OverloadApiResponse<BimDto.Contract>;
    putContractsByContractIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        contractId: string,
        body: BimDto.ContractPayload,
    ) => OverloadApiResponse<BimDto.Contract>;
    deleteContractsByContractIdByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
        contractId: string,
    ) => OverloadApiResponse<undefined>;

    // Misc
    getPublicFeatureFlags: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<string>>;
    getPublicCountries: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<BimDto.CountryWithZones>>;
    getPublicEnergySources: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<BimDto.EnergySource>>;
    getPublicCurrencies: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<BimDto.Currency>>;
    getPublicRegions: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<BimDto.Region>>;

    // Consumption Site Group
    getConsumptionSiteGroupByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => OverloadApiResponse<Array<BimDto.BaseConsumptionSiteGroup>>;
    postConsumptionSiteGroupByTenantId: (
        getAccessToken: () => Promise<string>,
        name: string,
        tenantId: string,
    ) => OverloadApiResponse<BimDto.BaseConsumptionSiteGroup>;
    getConsumptionSiteGroupByConsumptionSiteGroupIdByTenantId: (
        getAccessToken: () => Promise<string>,
        consumptionSiteGroupId: string,
        tenantId: string,
    ) => OverloadApiResponse<BimDto.ConsumptionSiteGroupDetail>;
    deleteConsumptionSiteGroupByConsumptionSiteGroupIdByTenantId: (
        getAccessToken: () => Promise<string>,
        consumptionSiteGroupId: string,
        tenantId: string,
    ) => OverloadApiResponse<void>;
    patchConsumptionSiteGroupByConsumptionSiteGroupIdByTenantId: (
        getAccessToken: () => Promise<string>,
        name: string,
        consumptionSiteGroupId: string,
        tenantId: string,
    ) => OverloadApiResponse<BimDto.BaseConsumptionSiteGroup>;
} & ApiClient<bimPaths>;

export function getBimClient(config: { useRetries?: boolean }): BimClient;
export function getBimClient(config: { useResult: boolean }): ResultBimClient;
export function getBimClient(config: {
    useResult?: boolean;
    useRetries?: boolean;
}): Omit<
    OverloadBimClient,
    | "getAdminUsers"
    | "getAdminSynchronizeTenants"
    | "getAdminSynchronizeContracts"
    | "getAdminSynchronizeSites"
    | "getAdminSynchronizeConsumptionSiteGroups"
> {
    let url: string = "";

    let useMockData = false;
    if (typeof window !== "undefined") {
        url = window.location.origin;
    } else if (typeof process !== "undefined") {
        if (process.env.MOCK_BIM == "true") {
            useMockData = true;
        }
        if (!process.env.BIM_URL) {
            return crash("Missing BIM_URL environment variable.");
        } else {
            url = process.env.BIM_URL;
        }
    } else {
        return crash("No window or process, where the hell are we??!");
    }
    const { get, post, del, put, patch } = buildQueries({ ...config, useMockData }, url);

    return {
        // Tenants
        getTenantByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
        ): OverloadApiResponse<BimDto.Tenant> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/${tenantId}/tenant`,
                decoder: tenantDecoder,
                headers: { Authorization: `Bearer ${accessToken}` },
            });
        },

        // Webhooks - These should not be called internally or by UI but must be here to prevent type errors
        postWebhookGoogleMarketplaceSignUp: async () =>
            Promise.reject("Not for internal or UI usage"),
        getWebhookGoogleMarketplaceLogin: async () =>
            Promise.reject("Not for internal or UI usage"),
        getWebhookGoogleAuthCallback: async () => Promise.reject("Not for internal or UI usage"),
        postWebhookAuth0PostRegistrationAction: async () =>
            Promise.reject("Not for internal or UI usage"),
        postWebhookAuth0PostLoginAction: async () => Promise.reject("Not for internal or UI usage"),

        // Admin - Not for internal or UI usage
        postAdminGcpMarketplaceResetTestAccount: async () =>
            Promise.reject("Not for internal or UI usage"),
        postAdminApproveEntitlement: async () => Promise.reject("Not for internal or UI usage"),
        postAdminApproveEntitlementPlanChange: async () =>
            Promise.reject("Not for internal or UI usage"),
        postAdminRejectEntitlement: async () => Promise.reject("Not for internal or UI usage"),
        postAdminRejectEntitlementPlanChange: async () =>
            Promise.reject("Not for internal or UI usage"),
        postAdminTenantUser: async () => Promise.reject("Not for internal or UI usage"),
        postAdminTenant: async () => Promise.reject("Not for internal or UI usage"),
        deleteAdminTenantByTenantId: async () => Promise.reject("Not for internal or UI usage"),
        putAdminTenantByTenantIdCancel: async () => Promise.reject("Not for internal or UI usage"),
        putAdminTenantByTenantIdReject: async () => Promise.reject("Not for internal or UI usage"),
        putAdminTenantByTenantIdApprove: async () => Promise.reject("Not for internal or UI usage"),
        getSyncContractsByTenantId: async () => Promise.reject("Not for internal or UI usage"),
        getSyncSitesByTenantId: async () => Promise.reject("Not for internal or UI usage"),
        getAdminPage: async () => Promise.reject("Not for internal or UI usage"),
        getAdminTenantDisplayByTenantId: async () => Promise.reject("Not for internal or UI usage"),
        putAdminTenantDisplayByTenantId: async () => Promise.reject("Not for internal or UI usage"),
        getAdminTenantByTenantId: async () => Promise.reject("Not for internal or UI usage"),
        getAdminContractsByContractId: async () => Promise.reject("Not for internal or UI usage"),
        postAdminTestsBadEventsEventInWrongTopic: async () =>
            Promise.reject("Not for internal or UI usage"),
        postAdminTestsBadEventsPoisonedEvent: async () =>
            Promise.reject("Not for internal or UI usage"),
        getAdminSitesBySiteId: async () => Promise.reject("Not for internal or UI usage"),
        getAdminByTenantIdConsumptionSiteGroupByConsumptionSiteGroupId: async () =>
            Promise.reject("Not for internal or UI usage"),

        // Users
        getUsersByTenantId: async ({
            getAccessToken,
            tenantId,
            page,
            search,
        }): OverloadApiResponse<BimDto.GetUsersResponse> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/${tenantId}/users`,
                decoder: getUsersDecoder,
                params: {
                    page,
                    ...(search && { search }),
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        deleteUsersByUserIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            userId: string,
            tenantId: string,
        ): OverloadApiResponse<{ success: boolean }> => {
            const accessToken = await getAccessToken();
            return del({
                path: `/api/bim/${tenantId}/users/${userId}`,
                decoder: deleteResponseDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getUsersByUserIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            userId: string,
        ): OverloadApiResponse<BimDto.User> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/${tenantId}/users/${userId}`,
                decoder: userDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        // App User
        getAppUser: async (getAccessToken): OverloadApiResponse<BimDto.AppUser> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/app-user`,
                decoder: selfDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        patchAppUserActiveTenant: async ({
            getAccessToken,
            tenantId,
        }: PatchActiveTenantArgs): OverloadApiResponse<BimDto.AppUser> => {
            const accessToken = await getAccessToken();
            return patch({
                path: `/api/bim/app-user/active-tenant`,
                decoder: selfDecoder,
                data: { tenantId },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        // BIM Authorization
        postAdminAuthorize: (
            bimKey: string,
            body: BimDto.AuthorizeActionPayload,
        ): OverloadApiResponse<BimDto.AuthorizeActionResponse> => {
            return post({
                path: `/api/bim/admin/authorize`,
                decoder: postAdminAuthorizeDecoder,
                data: body,
                headers: {
                    "x-auth-key": bimKey,
                },
            });
        },

        // Misc
        getPublicFeatureFlags: async (getAccessToken): OverloadApiResponse<Array<string>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/public/feature-flags`,
                decoder: featureFlagDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getPublicCountries: async (
            getAccessToken,
        ): OverloadApiResponse<Array<BimDto.CountryWithZones>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/public/countries`,
                decoder: countriesWithZonesArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getPublicRegions: async (getAccessToken): OverloadApiResponse<Array<BimDto.Region>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/public/regions`,
                decoder: regionsArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getPublicEnergySources: async (
            getAccessToken,
        ): OverloadApiResponse<Array<BimDto.EnergySource>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/public/energy-sources`,
                decoder: energySourcesArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        // Sites
        getSitesByTenantId: async ({
            getAccessToken,
            tenantId,
            pageIndex,
            pageSize,
            searchQuery,
            orderBy,
            orderDirection,
            filters,
        }: GetSitesByTenantIdArgs): OverloadApiResponse<BimDto.GetSitesByTenantIdResponse> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/${tenantId}/sites`,
                decoder: getAllSitesDecoder,
                params: {
                    ...(pageIndex != null && { pageIndex }),
                    ...(pageSize != null && { pageSize }),
                    ...(searchQuery != null && { searchQuery }),
                    ...(orderBy != null && { orderBy }),
                    ...(orderDirection != null && { orderDirection }),
                    ...(filters != null && { filters }),
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getSitesFiltersByTenantId: async ({
            getAccessToken,
            tenantId,
        }: GetSitesFiltersByTenantIdArgs): OverloadApiResponse<BimDto.GetSitesFiltersResponse> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/${tenantId}/sites-filters`,
                decoder: getSitesFiltersDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getSitesBySiteIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            siteId: string,
        ): OverloadApiResponse<BimDto.Site> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/${tenantId}/sites/${siteId}`,
                decoder: siteDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        postSitesByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            body: BimDto.UpsertSitePayload,
        ): OverloadApiResponse<Array<BimDto.Site>> => {
            const accessToken = await getAccessToken();
            return post({
                path: `/api/bim/${tenantId}/sites`,
                decoder: sitesArrayDecoder,
                data: body,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        putSitesBySiteIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            siteId: string,
            body: BimDto.UpsertSitePayload,
        ): OverloadApiResponse<BimDto.Site> => {
            const accessToken = await getAccessToken();
            return put({
                path: `/api/bim/${tenantId}/sites/${siteId}`,
                decoder: siteDecoder,
                data: body,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        deleteSitesBySiteIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            siteId: string,
        ): OverloadApiResponse<undefined> => {
            const accessToken = await getAccessToken();
            return del({
                path: `/api/bim/${tenantId}/sites/${siteId}`,
                decoder: noContentDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        // Contracts
        getContractsByTenantId: async ({
            getAccessToken,
            tenantId,
            pageIndex,
            pageSize,
            searchQuery,
            orderBy,
            orderDirection,
            filters,
        }: GetContractsByTenantIdArgs): OverloadApiResponse<BimDto.GetContractsResponse> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/${tenantId}/contracts`,
                decoder: getAllContractsDecoder,
                params: {
                    ...(pageIndex != null && { pageIndex }),
                    ...(pageSize != null && { pageSize }),
                    ...(searchQuery != null && { searchQuery }),
                    ...(orderBy != null && { orderBy }),
                    ...(orderDirection != null && { orderDirection }),
                    ...(filters != null && { filters }),
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractsFiltersByTenantId: async ({
            getAccessToken,
            tenantId,
        }: GetContractsFiltersByTenantIdArgs): OverloadApiResponse<BimDto.GetContractsFiltersResponse> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/${tenantId}/contracts-filters`,
                decoder: getContractsFiltersDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractsByContractIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            contractId: string,
        ): OverloadApiResponse<BimDto.Contract> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/${tenantId}/contracts/${contractId}`,
                decoder: contractDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        postContractsByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            body: BimDto.ContractPayload,
        ): OverloadApiResponse<BimDto.Contract> => {
            const accessToken = await getAccessToken();
            return post({
                path: `/api/bim/${tenantId}/contracts`,
                decoder: contractDecoder,
                data: body,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        putContractsByContractIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            contractId: string,
            body: BimDto.ContractPayload,
        ): OverloadApiResponse<BimDto.Contract> => {
            const accessToken = await getAccessToken();
            return put({
                path: `/api/bim/${tenantId}/contracts/${contractId}`,
                decoder: contractDecoder,
                data: body,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        deleteContractsByContractIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
            contractId: string,
        ): OverloadApiResponse<undefined> => {
            const accessToken = await getAccessToken();
            return del({
                path: `/api/bim/${tenantId}/contracts/${contractId}`,
                decoder: noContentDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getSitesConsumptionByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
        ): OverloadApiResponse<BimDto.ConsumptionSitesSummary> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/${tenantId}/sites/consumption`,
                decoder: getConsumptionSitesSummaryDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getSitesProductionByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
        ): OverloadApiResponse<Array<BimDto.SiteSummary>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/${tenantId}/sites/production`,
                decoder: siteSummaryArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        // Consumption Site Group
        getConsumptionSiteGroupByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
        ): OverloadApiResponse<Array<BimDto.BaseConsumptionSiteGroup>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/${tenantId}/consumption-site-group`,
                decoder: baseConsumptionSiteGroupArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        postConsumptionSiteGroupByTenantId: async (
            getAccessToken: () => Promise<string>,
            name: string,
            tenantId: string,
        ): OverloadApiResponse<BimDto.BaseConsumptionSiteGroup> => {
            const accessToken = await getAccessToken();
            return post({
                path: `/api/bim/${tenantId}/consumption-site-group`,
                decoder: baseConsumptionSiteGroupDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                data: { name },
            });
        },
        getConsumptionSiteGroupByConsumptionSiteGroupIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            consumptionSiteGroupId: string,
            tenantId: string,
        ): OverloadApiResponse<BimDto.ConsumptionSiteGroupDetail> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/${tenantId}/consumption-site-group/${consumptionSiteGroupId}`,
                decoder: consumptionSiteGroupDetailDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        deleteConsumptionSiteGroupByConsumptionSiteGroupIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            consumptionSiteGroupId: string,
            tenantId: string,
        ): OverloadApiResponse<void> => {
            const accessToken = await getAccessToken();
            return del({
                path: `/api/bim/${tenantId}/consumption-site-group/${consumptionSiteGroupId}`,
                decoder: noContentDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        patchConsumptionSiteGroupByConsumptionSiteGroupIdByTenantId: async (
            getAccessToken: () => Promise<string>,
            name: string,
            consumptionSiteGroupId: string,
            tenantId: string,
        ): OverloadApiResponse<BimDto.BaseConsumptionSiteGroup> => {
            const accessToken = await getAccessToken();
            return patch({
                path: `/api/bim/${tenantId}/consumption-site-group/${consumptionSiteGroupId}`,
                decoder: baseConsumptionSiteGroupDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                data: { name },
            });
        },
        getPublicCurrencies: async (
            getAccessToken: () => Promise<string>,
        ): OverloadApiResponse<Array<BimDto.Currency>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/bim/public/currencies`,
                decoder: currencyArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
    };
}
