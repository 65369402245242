export const labelToDataId = ({
    prefix,
    label,
    separator = "--",
}: {
    prefix?: string;
    label: string;
    separator?: string;
}): string => {
    const dataIdBody: string = label.toLowerCase().replace(/\s+/g, "-");
    const dataIdPrefix: string | null = prefix ? prefix.toLowerCase().replace(/\s+/g, "-") : null;

    return dataIdPrefix ? `${dataIdPrefix}${separator}${dataIdBody}` : dataIdBody;
};
