import { scaleOrdinal } from "@visx/scale";
import {
    ChartDimensions,
    ChartDisplayOptions,
    ChartMargin,
    defaultChartDisplayOptions,
} from "../utils";
import ParentSize from "@visx/responsive/lib/components/ParentSizeModern";
import { PieChartTemplate } from "./template";
import { MantineTheme, useMantineTheme } from "@mantine/core";
import { ReactElement } from "react";
import { halve } from "../../../utils";

export type PieChartItem = {
    key: string;
    label: string;
    value: number;
    color: string;
};

export type PieChartControllerProps = {
    dimensions: ChartDimensions;
    axisColor: string;
    useVerticalLayout: boolean;
    useDonut: boolean;
    displayOptions: ChartDisplayOptions;
    data: Array<PieChartItem>;
};

export const PieChartController = ({
    dimensions,
    data,
    ...rest
}: PieChartControllerProps): ReactElement | null => {
    const { parentWidth, innerWidth, innerHeight } = dimensions;
    const minWidthToRender = 10;
    if (parentWidth < minWidthToRender) {
        return null;
    }
    const radius = halve(Math.max(innerWidth, innerHeight));
    const centerY = halve(Math.max(innerWidth, innerHeight));
    const centerX = halve(Math.max(innerWidth, innerHeight));

    const colorScale = scaleOrdinal({
        domain: data.map((d) => d.key),
        range: data.map((d) => d.color),
    });

    return (
        <PieChartTemplate
            dimensions={dimensions}
            data={data}
            radius={radius}
            centerY={centerY}
            centerX={centerX}
            colorScale={colorScale}
            {...rest}
        />
    );
};
type PieChartProps = {
    data: Array<PieChartItem>;
    margin?: ChartMargin;
    displayOptions?: ChartDisplayOptions;
    useDonut?: boolean;
};
export const PieChart = ({
    margin = { top: 0, right: 0, bottom: 0, left: 0 },
    displayOptions = defaultChartDisplayOptions,
    useDonut = false,
    ...props
}: PieChartProps): ReactElement => {
    const theme: MantineTheme = useMantineTheme();
    const axisColor: string = theme.colors.flexidaoGrey[5];
    return (
        <ParentSize>
            {({ width: parentWidth, height: parentHeight }): JSX.Element => {
                const useVerticalLayout: boolean = parentHeight >= parentWidth;
                if (useVerticalLayout) {
                    margin.bottom = 100;
                } else {
                    margin.right = 200;
                }
                const innerWidth = parentWidth - margin.left - margin.right;
                const innerHeight = parentHeight - margin.top - margin.bottom;
                return (
                    <PieChartController
                        dimensions={{ innerHeight, innerWidth, parentHeight, parentWidth, margin }}
                        axisColor={axisColor}
                        displayOptions={displayOptions}
                        useVerticalLayout={useVerticalLayout}
                        useDonut={useDonut}
                        {...props}
                    />
                );
            }}
        </ParentSize>
    );
};
