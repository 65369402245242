import { Paper, Stack } from "@mantine/core";
import { ReactElement } from "react";
import { labelToDataId } from "../../utils";
import { KpiSectionCustom } from "./kpi-sections/kpi-section-custom";
import { KpiSectionDivider } from "./kpi-sections/kpi-section-divider";
import { KpiSectionLarge } from "./kpi-sections/kpi-section-large";
import { KpiSectionSmall } from "./kpi-sections/kpi-section-small";
import { KpiSectionSpace } from "./kpi-sections/kpi-section-space";
import { KpiSectionTitle } from "./kpi-sections/kpi-section-title";
import { KpiSection, KpiSectionType } from "./types";

const sectionToJsx = ({
    section,
    index,
    dataIdPrefix,
}: {
    section: KpiSection;
    index: number;
    dataIdPrefix: string;
}): ReactElement => {
    switch (section.sectionType) {
        case KpiSectionType.Title:
            return (
                <KpiSectionTitle
                    key={index}
                    dataId={labelToDataId({ prefix: dataIdPrefix, label: section.title })}
                    title={section.title}
                />
            );
        case KpiSectionType.Small:
            return (
                <KpiSectionSmall
                    key={index}
                    dataId={labelToDataId({ prefix: dataIdPrefix, label: section.label })}
                    label={section.label}
                    value={section.value}
                    numDecimals={section.numDecimals}
                    unit={section.unit}
                    showSkeleton={section.showSkeleton}
                />
            );
        case KpiSectionType.Large:
            return (
                <KpiSectionLarge
                    key={index}
                    dataId={labelToDataId({ prefix: dataIdPrefix, label: section.label })}
                    label={section.label}
                    value={section.value}
                    numDecimals={section.numDecimals}
                    unit={section.unit}
                    tooltip={section.tooltip}
                    showSkeleton={section.showSkeleton}
                />
            );
        case KpiSectionType.Custom:
            return (
                <KpiSectionCustom
                    key={index}
                    dataId={labelToDataId({ prefix: dataIdPrefix, label: `custom-${index}` })}
                    component={section.component}
                />
            );
        case KpiSectionType.Divider:
            return (
                <KpiSectionDivider
                    key={index}
                    dataId={labelToDataId({ prefix: dataIdPrefix, label: `divider-${index}` })}
                    verticalPadding={section.verticalPadding}
                />
            );
        case KpiSectionType.Space:
            return (
                <KpiSectionSpace
                    key={index}
                    dataId={labelToDataId({
                        prefix: dataIdPrefix,
                        label: `space-${index}-${section.height}px`,
                    })}
                    height={section.height}
                />
            );
    }
};

type KpiProps = {
    dataId?: string;
    sections: Array<KpiSection>;
};
export const Kpi = ({ sections, dataId = "kpi" }: KpiProps): ReactElement => {
    // If the last section is a small KPI, the bottom padding should be 0
    const paddingBottom =
        sections &&
        sections.length > 0 &&
        sections[sections.length - 1]?.sectionType === KpiSectionType.Small
            ? 0
            : "md";

    return (
        <Paper pt="md" pb={paddingBottom} px={0} data-id={dataId}>
            <Stack spacing={0}>
                {sections.map((section, i) =>
                    sectionToJsx({
                        section,
                        index: i,
                        dataIdPrefix: dataId,
                    }),
                )}
            </Stack>
        </Paper>
    );
};
