import { AuthPage } from "@flexidao/dto";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserInitializedComponent } from "../../conditional-components";
import { useTenantDisplay } from "../../hooks/tenant-display";
import { authPaths } from "../../paths";
import { AppLoading } from "../atom";

const Redirect = (): JSX.Element => {
    const navigate = useNavigate();
    const displaySettings = useTenantDisplay();
    const homepage = displaySettings.homepage;
    useEffect(() => {
        const path = homepage
            ? `/app/${homepage.moduleId}/${homepage.pageId}`
            : authPaths[AuthPage.NoDisplayOptions];
        navigate(path);
    });
    return <></>;
};

export const HomepageRedirect = (): JSX.Element => (
    <UserInitializedComponent skeleton={<AppLoading />} content={<Redirect />} />
);
