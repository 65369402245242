import { CfeConnectPage, Module } from "@flexidao/dto";
import { APP_PREFIX } from "./consts";

const basePath = `/${APP_PREFIX}/${Module.CfeConnect}`;

type CfeConnectPaths = {
    [CfeConnectPage.MeterDataDashboard]: string;
    [CfeConnectPage.MonitoringDashboard]: string;
};

export const cfeConnectPaths: CfeConnectPaths = {
    // Meter Data
    [CfeConnectPage.MeterDataDashboard]: `${basePath}/${CfeConnectPage.MeterDataDashboard}`,
    [CfeConnectPage.MonitoringDashboard]: `${basePath}/${CfeConnectPage.MonitoringDashboard}`,
};
