import { ONE_SECOND_MS } from "@flexidao/helpers";
import {
    Anchor,
    AppShell,
    Button,
    CSSObject,
    Container,
    Group,
    MantineProvider,
    Paper,
    Stack,
    Text,
    Title,
} from "@mantine/core";
import { IconExternalLink, IconReload } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { mantineTheme } from "../../";

const RELOAD_SECONDS = 30;

export const Bim502 = (): JSX.Element => {
    const [countdown, setCountdown] = useState<number>(RELOAD_SECONDS);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, ONE_SECOND_MS);

        if (countdown === 0) {
            window.location.reload();
        }

        return () => clearInterval(intervalId);
    }, [countdown]);

    return (
        <MantineProvider withGlobalStyles withNormalizeCSS theme={{ ...mantineTheme }}>
            <AppShell
                sx={(theme): CSSObject => ({
                    background: theme.colors.gray[1],
                })}
            >
                <Container size="lg" h="100%">
                    <Stack align="center" justify="center" h="100%">
                        <Paper>
                            <Stack>
                                <Title order={1} fz="2rem" color="red">
                                    502 - Authorization Service Down
                                </Title>
                                <Text>
                                    We aren't getting any response from our authorization service.
                                </Text>
                                <Text>
                                    Its possible it's being updated and will soon be back online, so
                                    this page will automatically refresh in {countdown} seconds.
                                </Text>
                                <Text>
                                    If problems persist, please contact us or try again later.
                                </Text>
                                <Group mt="md">
                                    <Button
                                        rightIcon={<IconReload size="1rem" />}
                                        onClick={(): void => window.location.reload()}
                                        uppercase
                                    >
                                        Refresh now
                                    </Button>
                                    <Anchor
                                        href="https://flexidao.atlassian.net/servicedesk/customer/portal/1"
                                        target="_blank"
                                    >
                                        <Button
                                            rightIcon={<IconExternalLink size="1rem" />}
                                            uppercase
                                            color="flexidaoBlue"
                                        >
                                            Contact us
                                        </Button>
                                    </Anchor>
                                </Group>
                            </Stack>
                        </Paper>
                    </Stack>
                </Container>
            </AppShell>
        </MantineProvider>
    );
};
