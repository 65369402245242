import { ScaleInput, scaleLinear, scaleOrdinal, scaleTime, ScaleTypeToD3Scale } from "@visx/scale";
import { timeParse } from "d3-time-format";
import ParentSize from "@visx/responsive/lib/components/ParentSizeModern";
import { StackedAreasTemplate } from "./template";
import {
    ChartDimensions,
    ChartDisplayOptions,
    ChartMargin,
    defaultChartDisplayOptions,
} from "../utils";
import { ReactElement, useState } from "react";
import { TickFormatter } from "@visx/axis";
import { MantineTheme, useMantineTheme } from "@mantine/core";

export type StackedAreasReading<T = Record<string, number>> = {
    date: string;
    values: T;
};

const getDate = <T,>(d: StackedAreasReading<T>, dateFormat: string): number =>
    (timeParse(dateFormat)(d.date) as Date).valueOf();

export type StackedAreasControllerProps<T> = {
    dimensions: ChartDimensions;
    events?: boolean;
    axisColor: string;
    series: StackedAreasSeries<T>[];
    data: StackedAreasReading<T>[];
    dateFormat?: string;
    displayOptions: ChartDisplayOptions;
    xTickFormatter: TickFormatter<ScaleInput<ScaleTypeToD3Scale["time"]>>;
    yTickFormatter: TickFormatter<ScaleInput<ScaleTypeToD3Scale["linear"]>>;
};

export type StackedAreasSeries<T> = {
    key: keyof T;
    label: string;
    fill: string;
    active: boolean;
};

const combineValues = <T,>(series: Array<keyof T>, { values }: StackedAreasReading): number => {
    let total = 0;
    for (const [key, value] of Object.entries(values)) {
        if (series.includes(key as keyof T)) {
            total += value;
        }
    }
    return total;
};
export const ChartController = <T extends Record<string, number>>({
    dimensions,
    events = false,
    axisColor,
    series: inputSeries,
    data,
    dateFormat = "%Y-%m-%d",
    displayOptions,
    xTickFormatter,
    yTickFormatter,
}: StackedAreasControllerProps<T>): ReactElement | null => {
    const { innerWidth, parentWidth, innerHeight } = dimensions;
    const [series, setActiveSeries] = useState<Array<StackedAreasSeries<T>>>(inputSeries);

    const minWidthToRender = 10;
    if (parentWidth < minWidthToRender) {
        return null;
    }

    const toggleActiveSeries = (label: string): void => {
        setActiveSeries((prevState: Array<StackedAreasSeries<T>>) =>
            prevState.map((series) =>
                series.key === label ? { ...series, active: !series.active } : series,
            ),
        );
    };
    const activeSeriesKeys: Array<keyof T> = series
        .filter(({ active }) => active)
        .map(({ key }) => key);

    const dates = data.map((d) => getDate(d, dateFormat));
    const xScale = scaleTime<number>({
        range: [0, innerWidth],
        domain: [Math.min(...dates), Math.max(...dates)],
    });
    const totals = data.map((d) => combineValues(activeSeriesKeys, d));
    const yScale = scaleLinear<number>({
        domain: [0, Math.max(...totals)],
        range: [innerHeight, 0],
    });

    const colorScale = scaleOrdinal<string, string>({
        domain: series.map(({ key }) => key) as Array<string>,
        range: series.map(({ fill }) => fill),
    });
    return (
        <StackedAreasTemplate
            dimensions={dimensions}
            series={series}
            data={data}
            dateFormat={dateFormat}
            xScale={xScale}
            yScale={yScale}
            colorScale={colorScale}
            events={events}
            axisColor={axisColor}
            displayOptions={displayOptions}
            toggleActiveSeries={toggleActiveSeries}
            xTickFormatter={xTickFormatter}
            yTickFormatter={yTickFormatter}
        />
    );
};

export type StackedAreasProps<T> = {
    margin?: ChartMargin;
    events?: boolean;
    series: Array<StackedAreasSeries<T>>;
    data: Array<StackedAreasReading<T>>;
    dateFormat?: string;
    displayOptions?: ChartDisplayOptions;
    xTickFormatter: TickFormatter<ScaleInput<ScaleTypeToD3Scale["time"]>>;
    yTickFormatter: TickFormatter<ScaleInput<ScaleTypeToD3Scale["linear"]>>;
};

export const StackedAreaChart = <T extends Record<string, number>>({
    margin = { top: 0, right: 0, bottom: 100, left: 50 },
    displayOptions = defaultChartDisplayOptions,
    ...props
}: StackedAreasProps<T>): ReactElement => {
    const theme: MantineTheme = useMantineTheme();
    const axisColor: string = theme.colors.flexidaoGrey[5];
    return (
        <ParentSize>
            {({ width: parentWidth, height: parentHeight }): JSX.Element => {
                const innerWidth = parentWidth - margin.left - margin.right;
                const innerHeight = parentHeight - margin.top - margin.bottom;
                return (
                    <ChartController
                        dimensions={{ innerHeight, innerWidth, parentHeight, parentWidth, margin }}
                        axisColor={axisColor}
                        displayOptions={displayOptions}
                        {...props}
                    />
                );
            }}
        </ParentSize>
    );
};
