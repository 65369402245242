import { ReportHubDto, reportHubPaths } from "@flexidao/dto";
import * as D from "schemawax";
import { ApiClient, InferParams } from "../api-client";
import { featureFlagDecoder, reportHubDecoders } from "../decoders";
import { reportHubDecoders as reportHubDecodersV2 } from "../v2/decoders";

import {
    allocationFiltersDecoder,
    countryAssignmentResponseDecoder,
    postAllocationsByTenantIdResponseDecoder,
    postAssignmentResponse,
    reportingKpisDecoder,
} from "../decoders/report-hub";
import { paths } from "../../../dto/src/schemas/report-hub-api-schema";
import { ApiResponse, ApiResultResponse, OverloadApiResponse } from "../types";
import { buildQueries, crash } from "../utils";
import { InferApiFunctionParams, InferApiFunctionReturn } from "../v2/helpers";

export type BaseArgs = {
    getAccessToken: () => Promise<string>;
    tenantId: string;
};

export type GetReportHubKpisArgs = BaseArgs & {
    year?: number;
    regionIds?: Array<string> | null;
};

export type GetEacContractTrackingOverviewArgs = BaseArgs & {
    year?: number;
    regionIds?: Array<string> | null;
    page?: number;
};

export type GetEacContractTrackingRegionsArgs = BaseArgs;

export type GetEacContractTrackingSitesArgs = BaseArgs & {
    regionIds?: Array<string> | null;
    year?: number;
};

export type GetEacContractTrackingContractsArgs = BaseArgs & {
    regionIds?: Array<string> | null;
    year?: number;
};

export type GetEacContractTrackingMonthlyAggregatedArgs = BaseArgs & {
    year?: number;
    contract?: string;
    site?: string;
    regionIds?: Array<string> | null;
};

export type GetAllocationsByTenantIdArgs = BaseArgs & {
    page?: number;
    perPage?: number;
    orderBy?: ReportHubDto.GetAllocationsOrderBy;
    orderDirection?: "asc" | "desc";
    transactionIdFilter?: Array<string>;
    productionSiteNameFilter?: Array<string | null>;
    allocatedToNameFilter?: Array<string | null>;
};

export type GetContractsByContractIdByTenantIdArgs = BaseArgs & {
    contractId: string;
};

export type GetContractsByContractIdReportingSettingsByTenantIdArgs = BaseArgs & {
    contractId: string;
};

export type PutContractsByContractIdByTenantIdArgs = BaseArgs & {
    contractId: string;
    cancellationParty: number | null;
    beneficiaries: Array<number>;
    receivesEacs: boolean;
};

export type PutContractsByContractIdReportingSettingsByTenantIdArgs = BaseArgs &
    ReportHubDto.UpdateContractReportingSettingsPayload & {
        contractId: string;
    };

export type GetContractIdsReceivingEacsByTenantIdArgs = BaseArgs;

export type GetContractsReceivingEacsByTenantIdArgs = BaseArgs;

export type GetGlobalOverviewKpisArgs = BaseArgs & {
    year: number;
    countryIds?: Array<string> | null;
};
export type GetGlobalOverviewCountriesCoverageArgs = BaseArgs & {
    year: number;
    countryIds?: Array<string> | null;
};
export type GetGlobalOverviewCountriesArgs = BaseArgs;
export type GetGlobalOverviewReportingPeriodsArgs = BaseArgs;

export type GetAssignmentBiddingZoneAssignmentByBiddingZoneIdByTenantIdParams =
    InferApiFunctionParams<
        reportHubPaths,
        "/{tenantId}/assignment/bidding-zone-assignment/{biddingZoneId}"
    >;

export type GetAssignmentBiddingZoneAssignmentByBiddingZoneIdByTenantIdReturn =
    InferApiFunctionReturn<
        reportHubPaths,
        "/{tenantId}/assignment/bidding-zone-assignment/{biddingZoneId}"
    >;

export type GetAssignmentBiddingZoneAssignmentByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/assignment/bidding-zone-assignment"
>;

export type GetAssignmentBiddingZoneAssignmentByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/assignment/bidding-zone-assignment"
>;

export type GetAssignmentTrackingInstrumentAssignmentByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/assignment/tracking-instrument-assignment"
>;

export type GetAssignmentTrackingInstrumentAssignmentByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/assignment/tracking-instrument-assignment"
>;

export type GetAssignmentTrackingInstrumentStateByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/assignment/tracking-instrument-state"
>;

export type GetAssignmentTrackingInstrumentStateByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/assignment/tracking-instrument-state"
>;

export type GetAssignmentReportingPeriodByYearByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/assignment/reporting-period/{year}"
>;

export type GetAssignmentReportingPeriodByYearByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/assignment/reporting-period/{year}"
>;

export type PostAssignmentTrackingInstrumentAssignmentByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/assignment/tracking-instrument-assignment",
    "post"
>;

export type PostAssignmentTrackingInstrumentAssignmentByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/assignment/tracking-instrument-assignment",
    "post",
    201
>;

export type GetPublicCountriesParams = InferApiFunctionParams<reportHubPaths, "/public/countries">;

export type GetPublicCountriesReturn = InferApiFunctionReturn<reportHubPaths, "/public/countries">;

export type GetTotalPendingAllocationsParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/allocations/total-pending-allocations"
>;

export type GetTotalPendingAllocationsReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/allocations/total-pending-allocations"
>;

export type GetAssignmentTrackingInstrumentsAvailableFiltersByTenantIdParams =
    InferApiFunctionParams<
        reportHubPaths,
        "/{tenantId}/assignment/tracking-instruments/available-filters"
    >;

export type GetAssignmentTrackingInstrumentsAvailableFiltersByTenantIdReturn =
    InferApiFunctionReturn<
        reportHubPaths,
        "/{tenantId}/assignment/tracking-instruments/available-filters"
    >;

export type GetAssignmentByConsumptionGroupsByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/assignment-by-consumption-groups"
>;

export type GetAssignmentByConsumptionGroupsByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/assignment-by-consumption-groups"
>;

export type GetConsumptionSiteGroupsByTenantIdParams = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/consumption-site-groups"
>;

export type GetConsumptionSiteGroupsByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/consumption-site-groups"
>;

export type PostAllocationsByTenantIdBody = InferApiFunctionParams<
    reportHubPaths,
    "/{tenantId}/allocations",
    "post"
>;

export type PostAllocationsByTenantIdReturn = InferApiFunctionReturn<
    reportHubPaths,
    "/{tenantId}/allocations",
    "post",
    201
>;

export type OverloadReportHubClient = {
    getPublicFeatureFlags: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<string>>;
    getEacContractTrackingKpisByTenantId: (
        args: GetReportHubKpisArgs,
    ) => OverloadApiResponse<ReportHubDto.ContractTrackingKpis>;
    getEacContractTrackingOverviewByTenantId: (
        args: GetEacContractTrackingOverviewArgs,
    ) => OverloadApiResponse<ReportHubDto.ContractTrackingOverview>;
    getEacContractTrackingMonthlyAggregatedByTenantId: (
        args: GetEacContractTrackingMonthlyAggregatedArgs,
    ) => OverloadApiResponse<ReportHubDto.ContractTrackingMonthlyAggregated>;
    getEacContractTrackingRegionsByTenantId: (
        args: GetEacContractTrackingRegionsArgs,
    ) => OverloadApiResponse<ReportHubDto.GetRegions>;
    getEacContractTrackingContractsByTenantId: (
        args: GetEacContractTrackingContractsArgs,
    ) => OverloadApiResponse<ReportHubDto.GetContracts>;
    getEacContractTrackingSitesByTenantId: (
        args: GetEacContractTrackingSitesArgs,
    ) => OverloadApiResponse<ReportHubDto.GetSites>;
    getAllocationsByTenantId: (
        args: GetAllocationsByTenantIdArgs,
    ) => OverloadApiResponse<ReportHubDto.GetAllocationsByTenantIdResponse>;
    postAllocationsByTenantId: (
        getAccessToken: () => Promise<string>,
        params: PostAllocationsByTenantIdBody,
    ) => OverloadApiResponse<PostAllocationsByTenantIdReturn>;
    getContractsReceivingEacsByTenantId: (
        args: GetContractsReceivingEacsByTenantIdArgs,
    ) => OverloadApiResponse<ReportHubDto.GetContractsReceivingEacsResponse>;
    getContractsByContractIdByTenantId: (
        args: GetContractsByContractIdByTenantIdArgs,
    ) => OverloadApiResponse<ReportHubDto.BaseContract>;
    getContractsByContractIdReportingSettingsByTenantId: (
        args: GetContractsByContractIdReportingSettingsByTenantIdArgs,
    ) => OverloadApiResponse<ReportHubDto.ContractReportingSettings>;
    putContractsByContractIdByTenantId: (
        args: PutContractsByContractIdByTenantIdArgs,
    ) => OverloadApiResponse<ReportHubDto.BaseContract>;
    putContractsByContractIdReportingSettingsByTenantId: (
        args: PutContractsByContractIdReportingSettingsByTenantIdArgs,
    ) => OverloadApiResponse<ReportHubDto.ContractReportingSettings>;
    getContractIdsReceivingEacsByTenantId: (
        args: GetContractIdsReceivingEacsByTenantIdArgs,
    ) => OverloadApiResponse<Array<string>>;
    getEacCodesByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => OverloadApiResponse<Array<ReportHubDto.EacCode>>;
    getAssignmentCountryAssignmentByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        countryId: string;
        year: number;
        page: number | null;
        sortField: ReportHubDto.CountryAssignmentsSortField | null;
        sortOrder: "ASC" | "DESC" | null;
        groups: Array<string>;
    }) => OverloadApiResponse<ReportHubDto.CountryAssignmentsResponse>;
    getAssignmentKpisByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
        groups: Array<string>;
    }) => OverloadApiResponse<ReportHubDto.GetReportingKpisResponse>;

    getAssignmentBiddingZoneAssignmentByBiddingZoneIdByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentBiddingZoneAssignmentByBiddingZoneIdByTenantIdParams,
    ) => OverloadApiResponse<GetAssignmentBiddingZoneAssignmentByBiddingZoneIdByTenantIdReturn>;

    getAssignmentBiddingZoneAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentBiddingZoneAssignmentByTenantIdParams,
    ) => OverloadApiResponse<GetAssignmentBiddingZoneAssignmentByTenantIdReturn>;

    getAssignmentTrackingInstrumentAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentAssignmentByTenantIdParams,
    ) => OverloadApiResponse<GetAssignmentTrackingInstrumentAssignmentByTenantIdReturn>;

    getAssignmentTrackingInstrumentStateByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentStateByTenantIdParams,
    ) => OverloadApiResponse<GetAssignmentTrackingInstrumentStateByTenantIdReturn>;

    postAssignmentTrackingInstrumentAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: PostAssignmentTrackingInstrumentAssignmentByTenantIdParams,
    ) => OverloadApiResponse<PostAssignmentTrackingInstrumentAssignmentByTenantIdReturn>;

    getAssignmentReportingPeriodByYearByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentReportingPeriodByYearByTenantIdParams,
    ) => OverloadApiResponse<GetAssignmentReportingPeriodByYearByTenantIdReturn>;

    getPublicCountries: (
        getAccessToken: () => Promise<string>,
        args: GetPublicCountriesParams,
    ) => OverloadApiResponse<GetPublicCountriesReturn>;

    getAllocationsTotalPendingAllocationsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetTotalPendingAllocationsParams,
    ) => OverloadApiResponse<GetTotalPendingAllocationsReturn>;

    getAssignmentTrackingInstrumentsAvailableFiltersByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentsAvailableFiltersByTenantIdParams,
    ) => OverloadApiResponse<GetAssignmentTrackingInstrumentsAvailableFiltersByTenantIdReturn>;

    getAssignmentByConsumptionGroupsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentByConsumptionGroupsByTenantIdParams,
    ) => OverloadApiResponse<GetAssignmentByConsumptionGroupsByTenantIdReturn>;

    getAllocationFiltersByTenantId: (
        args: BaseArgs,
    ) => OverloadApiResponse<ReportHubDto.AllocationFilters>;

    getConsumptionSiteGroupsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetConsumptionSiteGroupsByTenantIdParams,
    ) => OverloadApiResponse<GetConsumptionSiteGroupsByTenantIdReturn>;

    getGlobalOverviewKpisByTenantId: (
        args: GetGlobalOverviewKpisArgs,
    ) => OverloadApiResponse<ReportHubDto.GlobalOverviewKpis>;
    getGlobalOverviewCountriesCoverageByTenantId: (
        args: GetGlobalOverviewCountriesCoverageArgs,
    ) => OverloadApiResponse<ReportHubDto.GlobalOverviewCountriesCoverage>;
    getGlobalOverviewCountriesByTenantId: (
        args: GetGlobalOverviewCountriesArgs,
    ) => OverloadApiResponse<ReportHubDto.GetCountries>;
    getGlobalOverviewReportingPeriodsByTenantId: (
        args: GetGlobalOverviewReportingPeriodsArgs,
    ) => OverloadApiResponse<ReportHubDto.GetReportingPeriods>;
} & ApiClient<reportHubPaths>;

export type ReportHubClient = {
    getPublicFeatureFlags: (getAccessToken: () => Promise<string>) => ApiResponse<Array<string>>;
    getEacContractTrackingKpisByTenantId: (
        args: GetReportHubKpisArgs,
    ) => ApiResponse<ReportHubDto.ContractTrackingKpis>;
    getEacContractTrackingOverviewByTenantId: (
        args: GetEacContractTrackingOverviewArgs,
    ) => ApiResponse<ReportHubDto.ContractTrackingOverview>;
    getEacContractTrackingMonthlyAggregatedByTenantId: (
        args: GetEacContractTrackingMonthlyAggregatedArgs,
    ) => ApiResponse<ReportHubDto.ContractTrackingMonthlyAggregated>;
    getEacContractTrackingRegionsByTenantId: (
        args: GetEacContractTrackingRegionsArgs,
    ) => ApiResponse<ReportHubDto.GetRegions>;
    getEacContractTrackingContractsByTenantId: (
        args: GetEacContractTrackingContractsArgs,
    ) => ApiResponse<ReportHubDto.GetContracts>;
    getEacContractTrackingSitesByTenantId: (
        args: GetEacContractTrackingSitesArgs,
    ) => ApiResponse<ReportHubDto.GetSites>;
    getAllocationsByTenantId: (
        args: GetAllocationsByTenantIdArgs,
    ) => ApiResponse<ReportHubDto.GetAllocationsByTenantIdResponse>;
    postAllocationsByTenantId: (
        getAccessToken: () => Promise<string>,
        params: PostAllocationsByTenantIdBody,
    ) => ApiResponse<PostAllocationsByTenantIdReturn>;
    getContractsReceivingEacsByTenantId: (
        args: GetContractsReceivingEacsByTenantIdArgs,
    ) => OverloadApiResponse<ReportHubDto.GetContractsReceivingEacsResponse>;
    getContractsByContractIdByTenantId: (
        args: GetContractsByContractIdByTenantIdArgs,
    ) => ApiResponse<ReportHubDto.BaseContract>;
    getContractsByContractIdReportingSettingsByTenantId: (
        args: GetContractsByContractIdReportingSettingsByTenantIdArgs,
    ) => ApiResponse<ReportHubDto.ContractReportingSettings>;
    putContractsByContractIdByTenantId: (
        args: PutContractsByContractIdByTenantIdArgs,
    ) => ApiResponse<ReportHubDto.BaseContract>;
    putContractsByContractIdReportingSettingsByTenantId: (
        args: PutContractsByContractIdReportingSettingsByTenantIdArgs,
    ) => ApiResponse<ReportHubDto.ContractReportingSettings>;
    getContractIdsReceivingEacsByTenantId: (
        args: GetContractIdsReceivingEacsByTenantIdArgs,
    ) => ApiResponse<Array<string>>;
    getEacCodesByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => ApiResponse<Array<ReportHubDto.EacCode>>;
    getAssignmentCountryAssignmentByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        countryId: string;
        year: number;
        page: number | null;
        sortField: ReportHubDto.CountryAssignmentsSortField | null;
        sortOrder: "ASC" | "DESC" | null;
        groups: Array<string>;
    }) => ApiResponse<ReportHubDto.CountryAssignmentsResponse>;
    getAssignmentKpisByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
        groups: Array<string>;
    }) => ApiResponse<ReportHubDto.GetReportingKpisResponse>;

    getAssignmentBiddingZoneAssignmentByBiddingZoneIdByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentBiddingZoneAssignmentByBiddingZoneIdByTenantIdParams,
    ) => ApiResponse<GetAssignmentBiddingZoneAssignmentByBiddingZoneIdByTenantIdReturn>;

    getAssignmentBiddingZoneAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentBiddingZoneAssignmentByTenantIdParams,
    ) => ApiResponse<GetAssignmentBiddingZoneAssignmentByTenantIdReturn>;

    getAssignmentTrackingInstrumentAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentAssignmentByTenantIdParams,
    ) => ApiResponse<GetAssignmentTrackingInstrumentAssignmentByTenantIdReturn>;

    getAssignmentTrackingInstrumentStateByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentStateByTenantIdParams,
    ) => ApiResponse<GetAssignmentTrackingInstrumentStateByTenantIdReturn>;

    postAssignmentTrackingInstrumentAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: PostAssignmentTrackingInstrumentAssignmentByTenantIdParams,
    ) => ApiResponse<PostAssignmentTrackingInstrumentAssignmentByTenantIdReturn>;

    getAssignmentReportingPeriodByYearByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentReportingPeriodByYearByTenantIdParams,
    ) => ApiResponse<GetAssignmentReportingPeriodByYearByTenantIdReturn>;

    getPublicCountries: (
        getAccessToken: () => Promise<string>,
        args: GetPublicCountriesParams,
    ) => ApiResponse<GetPublicCountriesReturn>;

    getAllocationsTotalPendingAllocationsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetTotalPendingAllocationsParams,
    ) => ApiResponse<GetTotalPendingAllocationsReturn>;

    getAssignmentTrackingInstrumentsAvailableFiltersByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentsAvailableFiltersByTenantIdParams,
    ) => ApiResponse<GetAssignmentTrackingInstrumentsAvailableFiltersByTenantIdReturn>;

    getAssignmentByConsumptionGroupsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentByConsumptionGroupsByTenantIdParams,
    ) => ApiResponse<GetAssignmentByConsumptionGroupsByTenantIdReturn>;

    getAllocationFiltersByTenantId: (args: BaseArgs) => ApiResponse<ReportHubDto.AllocationFilters>;

    getConsumptionSiteGroupsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetConsumptionSiteGroupsByTenantIdParams,
    ) => ApiResponse<GetConsumptionSiteGroupsByTenantIdReturn>;

    getGlobalOverviewKpisByTenantId: (
        args: GetGlobalOverviewKpisArgs,
    ) => ApiResponse<ReportHubDto.GlobalOverviewKpis>;
    getGlobalOverviewCountriesCoverageByTenantId: (
        args: GetGlobalOverviewCountriesCoverageArgs,
    ) => ApiResponse<ReportHubDto.GlobalOverviewCountriesCoverage>;
    getGlobalOverviewCountriesByTenantId: (
        args: GetGlobalOverviewCountriesArgs,
    ) => ApiResponse<ReportHubDto.GetCountries>;
    getGlobalOverviewReportingPeriodsByTenantId: (
        args: GetGlobalOverviewReportingPeriodsArgs,
    ) => ApiResponse<ReportHubDto.GetReportingPeriods>;
} & ApiClient<reportHubPaths>;

export type ResultReportHubClient = {
    getPublicFeatureFlags: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<string>>;
    getEacContractTrackingKpisByTenantId: (
        args: GetReportHubKpisArgs,
    ) => ApiResultResponse<ReportHubDto.ContractTrackingKpis>;
    getEacContractTrackingOverviewByTenantId: (
        args: GetEacContractTrackingOverviewArgs,
    ) => ApiResultResponse<ReportHubDto.ContractTrackingOverview>;
    getEacContractTrackingMonthlyAggregatedByTenantId: (
        args: GetEacContractTrackingMonthlyAggregatedArgs,
    ) => ApiResultResponse<ReportHubDto.ContractTrackingMonthlyAggregated>;
    getEacContractTrackingRegionsByTenantId: (
        args: GetEacContractTrackingRegionsArgs,
    ) => ApiResultResponse<ReportHubDto.GetRegions>;
    getEacContractTrackingContractsByTenantId: (
        args: GetEacContractTrackingContractsArgs,
    ) => ApiResultResponse<ReportHubDto.GetContracts>;
    getEacContractTrackingSitesByTenantId: (
        args: GetEacContractTrackingSitesArgs,
    ) => ApiResultResponse<ReportHubDto.GetSites>;
    getAllocationsByTenantId: (
        args: GetAllocationsByTenantIdArgs,
    ) => ApiResultResponse<ReportHubDto.GetAllocationsByTenantIdResponse>;
    postAllocationsByTenantId: (
        getAccessToken: () => Promise<string>,
        params: PostAllocationsByTenantIdBody,
    ) => OverloadApiResponse<PostAllocationsByTenantIdReturn>;
    getContractsReceivingEacsByTenantId: (
        args: GetContractsReceivingEacsByTenantIdArgs,
    ) => OverloadApiResponse<ReportHubDto.GetContractsReceivingEacsResponse>;
    getContractsByContractIdByTenantId: (
        args: GetContractsByContractIdByTenantIdArgs,
    ) => ApiResultResponse<ReportHubDto.BaseContract>;
    getContractsByContractIdReportingSettingsByTenantId: (
        args: GetContractsByContractIdReportingSettingsByTenantIdArgs,
    ) => ApiResultResponse<ReportHubDto.ContractReportingSettings>;
    putContractsByContractIdByTenantId: (
        args: PutContractsByContractIdByTenantIdArgs,
    ) => ApiResultResponse<ReportHubDto.BaseContract>;
    putContractsByContractIdReportingSettingsByTenantId: (
        args: PutContractsByContractIdReportingSettingsByTenantIdArgs,
    ) => ApiResultResponse<ReportHubDto.ContractReportingSettings>;
    getContractIdsReceivingEacsByTenantId: (
        args: GetContractIdsReceivingEacsByTenantIdArgs,
    ) => ApiResultResponse<Array<string>>;
    getEacCodesByTenantId: (
        getAccessToken: () => Promise<string>,
        tenantId: string,
    ) => ApiResultResponse<Array<ReportHubDto.EacCode>>;
    getAssignmentCountryAssignmentByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        countryId: string;
        year: number;
        page: number | null;
        sortField: ReportHubDto.CountryAssignmentsSortField | null;
        sortOrder: "ASC" | "DESC" | null;
        groups: Array<string>;
    }) => ApiResultResponse<ReportHubDto.CountryAssignmentsResponse>;
    getAssignmentKpisByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
        groups: Array<string>;
    }) => ApiResultResponse<ReportHubDto.GetReportingKpisResponse>;

    getAssignmentBiddingZoneAssignmentByBiddingZoneIdByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentBiddingZoneAssignmentByBiddingZoneIdByTenantIdParams,
    ) => ApiResultResponse<GetAssignmentBiddingZoneAssignmentByBiddingZoneIdByTenantIdReturn>;

    getAssignmentBiddingZoneAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentBiddingZoneAssignmentByTenantIdParams,
    ) => ApiResultResponse<GetAssignmentBiddingZoneAssignmentByTenantIdReturn>;

    getAssignmentTrackingInstrumentAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentAssignmentByTenantIdParams,
    ) => ApiResultResponse<GetAssignmentTrackingInstrumentAssignmentByTenantIdReturn>;

    getAssignmentTrackingInstrumentStateByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentStateByTenantIdParams,
    ) => ApiResultResponse<GetAssignmentTrackingInstrumentStateByTenantIdReturn>;

    postAssignmentTrackingInstrumentAssignmentByTenantId: (
        getAccessToken: () => Promise<string>,
        args: PostAssignmentTrackingInstrumentAssignmentByTenantIdParams,
    ) => ApiResultResponse<PostAssignmentTrackingInstrumentAssignmentByTenantIdReturn>;

    getAssignmentReportingPeriodByYearByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentReportingPeriodByYearByTenantIdParams,
    ) => ApiResultResponse<GetAssignmentReportingPeriodByYearByTenantIdReturn>;

    getPublicCountries: (
        getAccessToken: () => Promise<string>,
        args: GetPublicCountriesParams,
    ) => ApiResultResponse<GetPublicCountriesReturn>;

    getAllocationsTotalPendingAllocationsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetTotalPendingAllocationsParams,
    ) => ApiResultResponse<GetTotalPendingAllocationsReturn>;

    getAssignmentTrackingInstrumentsAvailableFiltersByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentTrackingInstrumentsAvailableFiltersByTenantIdParams,
    ) => ApiResultResponse<GetAssignmentTrackingInstrumentsAvailableFiltersByTenantIdReturn>;

    getAssignmentByConsumptionGroupsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetAssignmentByConsumptionGroupsByTenantIdParams,
    ) => ApiResultResponse<GetAssignmentByConsumptionGroupsByTenantIdReturn>;
    getAllocationFiltersByTenantId: (
        args: BaseArgs,
    ) => ApiResultResponse<ReportHubDto.AllocationFilters>;
    getConsumptionSiteGroupsByTenantId: (
        getAccessToken: () => Promise<string>,
        args: GetConsumptionSiteGroupsByTenantIdParams,
    ) => ApiResultResponse<GetConsumptionSiteGroupsByTenantIdReturn>;
    getGlobalOverviewKpisByTenantId: (
        args: GetGlobalOverviewKpisArgs,
    ) => ApiResultResponse<ReportHubDto.GlobalOverviewKpis>;
    getGlobalOverviewCountriesCoverageByTenantId: (
        args: GetGlobalOverviewCountriesCoverageArgs,
    ) => ApiResultResponse<ReportHubDto.GlobalOverviewCountriesCoverage>;
    getGlobalOverviewCountriesByTenantId: (
        args: GetGlobalOverviewCountriesArgs,
    ) => ApiResultResponse<ReportHubDto.GetCountries>;
    getGlobalOverviewReportingPeriodsByTenantId: (
        args: GetGlobalOverviewReportingPeriodsArgs,
    ) => ApiResultResponse<ReportHubDto.GetReportingPeriods>;
} & ApiClient<reportHubPaths>;

export function getReportHubClient(config: { useRetries?: boolean }): ReportHubClient;
export function getReportHubClient(config: { useResult: boolean }): ResultReportHubClient;
export function getReportHubClient(config: {
    useResult?: boolean;
    useRetries?: boolean;
}): Omit<
    OverloadReportHubClient,
    "getAdminByTenantIdConsumptionSiteGroupsByConsumptionSiteGroupId"
> {
    let url: string = "";

    let useMockData = false;
    if (typeof window !== "undefined") {
        url = window.location.origin;
    } else if (typeof process !== "undefined") {
        if (process.env.MOCK_REPORT_HUB == "true") {
            useMockData = true;
        }
        if (!process.env.REPORT_HUB_URL) {
            return crash("Missing REPORT_HUB_URL environment variable.");
        } else {
            url = process.env.REPORT_HUB_URL;
        }
    } else {
        return crash("No window or process, where the hell are we??!");
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    buildQueries({ ...config, useMockData: useMockData }, url);

    const { get, put, post } = buildQueries({ ...config, useMockData: useMockData }, url);

    const filterObjectFieldsByUndefined = <T extends object>(obj: T): Partial<T> =>
        Object.entries(obj).reduce(
            (acc, [key, value]) => (value === undefined ? acc : { ...acc, [key]: value }),
            {},
        );

    const filterObjectFieldsByUndefinedOrNull = <T extends object>(obj: T): Partial<T> =>
        Object.entries(obj).reduce(
            (acc, [key, value]) => (value == null ? acc : { ...acc, [key]: value }),
            {},
        );

    return {
        getEacContractTrackingKpisByTenantId: async ({
            getAccessToken,
            tenantId,
            year,
            regionIds,
        }): OverloadApiResponse<ReportHubDto.ContractTrackingKpis> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/eac-contract-tracking/kpis`,
                decoder: reportHubDecoders.getEacContractTrackingKpisDecoder,
                params: filterObjectFieldsByUndefinedOrNull({ year, regionIds }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getEacContractTrackingOverviewByTenantId: async ({
            getAccessToken,
            tenantId,
            year,
            regionIds,
            page,
        }): OverloadApiResponse<ReportHubDto.ContractTrackingOverview> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/eac-contract-tracking/overview`,
                decoder: reportHubDecoders.getEacContractTrackingOverviewDecoder,
                params: filterObjectFieldsByUndefinedOrNull({ year, regionIds, page }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getEacContractTrackingMonthlyAggregatedByTenantId: async ({
            getAccessToken,
            tenantId,
            year,
            regionIds,
            contract,
            site,
        }): OverloadApiResponse<ReportHubDto.ContractTrackingMonthlyAggregated> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/eac-contract-tracking/monthly-aggregated`,
                decoder: reportHubDecoders.getEacContractTrackingMonthlyAggregatedDecoder,
                params: filterObjectFieldsByUndefinedOrNull({ year, regionIds, contract, site }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getEacContractTrackingRegionsByTenantId: async ({
            getAccessToken,
            tenantId,
        }): OverloadApiResponse<ReportHubDto.GetRegions> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/eac-contract-tracking/regions`,
                decoder: reportHubDecoders.getRegions,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getEacContractTrackingContractsByTenantId: async ({
            getAccessToken,
            tenantId,
            regionIds,
            year,
        }): OverloadApiResponse<ReportHubDto.GetContracts> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/eac-contract-tracking/contracts`,
                params: filterObjectFieldsByUndefinedOrNull({ regionIds, year }),
                decoder: reportHubDecoders.getContracts,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getEacContractTrackingSitesByTenantId: async ({
            getAccessToken,
            tenantId,
            regionIds,
            year,
        }): OverloadApiResponse<ReportHubDto.GetSites> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/eac-contract-tracking/sites`,
                params: filterObjectFieldsByUndefinedOrNull({ regionIds, year }),
                decoder: reportHubDecoders.getSites,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAllocationsByTenantId: async ({
            getAccessToken,
            tenantId,
            page,
            perPage,
            orderBy,
            orderDirection,
            transactionIdFilter,
            productionSiteNameFilter,
            allocatedToNameFilter,
        }): OverloadApiResponse<ReportHubDto.GetAllocationsByTenantIdResponse> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/allocations`,
                decoder: reportHubDecoders.getAllocationsByTenantIdResponseDecoder,
                params: filterObjectFieldsByUndefined({
                    page,
                    perPage,
                    orderBy,
                    orderDirection,
                    transactionIdFilter,
                    productionSiteNameFilter,
                    allocatedToNameFilter,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        postAllocationsByTenantId: async (
            getAccessToken: () => Promise<string>,
            {
                body,
                params: {
                    path: { tenantId },
                },
            },
        ): ReturnType<ReportHubClient["postAllocationsByTenantId"]> => {
            const accessToken = await getAccessToken();
            return post({
                path: `/api/report-hub/${tenantId}/allocations`,
                decoder: postAllocationsByTenantIdResponseDecoder,
                data: body,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        putAdminEventsTrigger: async () => Promise.reject("Not for internal or UI usage"),

        getContractsReceivingEacsByTenantId: async ({
            getAccessToken,
            tenantId,
        }: GetContractsReceivingEacsByTenantIdArgs): OverloadApiResponse<ReportHubDto.GetContractsReceivingEacsResponse> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/contracts/receiving-eacs`,
                decoder: reportHubDecoders.contractOptionsArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractsByContractIdByTenantId: async ({
            getAccessToken,
            tenantId,
            contractId,
        }: GetContractsByContractIdByTenantIdArgs): OverloadApiResponse<ReportHubDto.BaseContract> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/contracts/${contractId}`,
                decoder: reportHubDecoders.baseContractDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractsByContractIdReportingSettingsByTenantId: async ({
            getAccessToken,
            tenantId,
            contractId,
        }: GetContractsByContractIdReportingSettingsByTenantIdArgs): OverloadApiResponse<ReportHubDto.ContractReportingSettings> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/contracts/${contractId}/reporting-settings`,
                decoder: reportHubDecoders.contractReportingSettingsDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        putContractsByContractIdByTenantId: async ({
            getAccessToken,
            tenantId,
            contractId,
            ...data
        }: PutContractsByContractIdByTenantIdArgs): OverloadApiResponse<ReportHubDto.BaseContract> => {
            const accessToken = await getAccessToken();
            return put({
                path: `/api/report-hub/${tenantId}/contracts/${contractId}`,
                decoder: reportHubDecoders.baseContractDecoder,
                data,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        putContractsByContractIdReportingSettingsByTenantId: async ({
            getAccessToken,
            tenantId,
            contractId,
            sourcingMethod,
        }: PutContractsByContractIdReportingSettingsByTenantIdArgs): OverloadApiResponse<ReportHubDto.ContractReportingSettings> => {
            const accessToken = await getAccessToken();

            return put({
                path: `/api/report-hub/${tenantId}/contracts/${contractId}/reporting-settings`,
                decoder: reportHubDecoders.contractReportingSettingsDecoder,
                data: {
                    sourcingMethod,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getContractIdsReceivingEacsByTenantId: async ({
            getAccessToken,
            tenantId,
        }: GetContractIdsReceivingEacsByTenantIdArgs): OverloadApiResponse<Array<string>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/contract-ids-receiving-eacs`,
                decoder: D.array(D.string),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getEacCodesByTenantId: async (
            getAccessToken: () => Promise<string>,
            tenantId: string,
        ): OverloadApiResponse<Array<ReportHubDto.EacCode>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/eac-codes`,
                decoder: D.array(reportHubDecoders.eacCodeDecoder),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAdminInspectIndicators: async () => Promise.reject("Not for internal or UI usage"),
        postAdminBackgroundWorkerTrigger: async () =>
            Promise.reject("Not for internal or UI usage"),
        getAdminTenantsByTenantId: async () => Promise.reject("Not for internal or UI usage"),
        getAdminSitesBySiteId: async () => Promise.reject("Not for internal or UI usage"),
        getAdminContractsByContractId: async () => Promise.reject("Not for internal or UI usage"),
        getTestsAttestations: async () => Promise.reject("Not for internal or UI usage"),
        getTestsEacCancellations: async () => Promise.reject("Not for internal or UI usage"),
        getTestsSitesBySiteIdSiteReadings: async () =>
            Promise.reject("Not for internal or UI usage"),
        getTestsConsumptionSiteGroupsByConsumptionSiteGroupId: async () =>
            Promise.reject("Not for internal or UI usage"),
        getTestsSitesBySiteIdSiteReadingsMonthly: async () =>
            Promise.reject("Not for internal or UI usage"),
        postAdminAssignmentByConsumptionGroups: async () =>
            Promise.reject("Not for internal or UI usage"),
        getAssignmentBiddingZoneAssignmentByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                    query,
                },
            },
        ): ReturnType<OverloadReportHubClient["getAssignmentBiddingZoneAssignmentByTenantId"]> => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/${tenantId}/assignment/bidding-zone-assignment`,

                decoder:
                    reportHubDecodersV2["/{tenantId}/assignment/bidding-zone-assignment"]["get"],
                params: { ...query, ...(query.group ? { group: query.group } : {}) },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAssignmentBiddingZoneAssignmentByBiddingZoneIdByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId, biddingZoneId },
                    query: params,
                },
            },
        ): ReturnType<
            OverloadReportHubClient["getAssignmentBiddingZoneAssignmentByBiddingZoneIdByTenantId"]
        > => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/${tenantId}/assignment/bidding-zone-assignment/${biddingZoneId}`,
                decoder:
                    reportHubDecodersV2[
                        "/{tenantId}/assignment/bidding-zone-assignment/{biddingZoneId}"
                    ]["get"],
                params,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAssignmentTrackingInstrumentAssignmentByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                    query: params,
                },
            },
        ): ReturnType<
            OverloadReportHubClient["getAssignmentTrackingInstrumentAssignmentByTenantId"]
        > => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/${tenantId}/assignment/tracking-instrument-assignment`,
                decoder:
                    reportHubDecodersV2["/{tenantId}/assignment/tracking-instrument-assignment"][
                        "get"
                    ],
                params,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAssignmentTrackingInstrumentStateByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                    query: params,
                },
            },
        ): ReturnType<
            OverloadReportHubClient["getAssignmentTrackingInstrumentStateByTenantId"]
        > => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/${tenantId}/assignment/tracking-instrument-state`,
                decoder:
                    reportHubDecodersV2["/{tenantId}/assignment/tracking-instrument-state"]["get"],
                params,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        postAssignmentTrackingInstrumentAssignmentByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                },
                body: { assignments, biddingZoneId, year, group },
            },
        ): ReturnType<
            OverloadReportHubClient["postAssignmentTrackingInstrumentAssignmentByTenantId"]
        > => {
            const accessToken = await getAccessToken();

            return await post({
                path: `/api/report-hub/${tenantId}/assignment/tracking-instrument-assignment`,
                decoder: postAssignmentResponse,
                data: {
                    assignments,
                    biddingZoneId,
                    year,
                    group,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAssignmentReportingPeriodByYearByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId, year },
                    query,
                },
            },
        ): ReturnType<OverloadReportHubClient["getAssignmentReportingPeriodByYearByTenantId"]> => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/${tenantId}/assignment/reporting-period/${year}`,
                params: query && query.group ? { group: query.group } : {},
                decoder:
                    reportHubDecodersV2["/{tenantId}/assignment/reporting-period/{year}"]["get"],
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getPublicCountries: async (
            getAccessToken: () => Promise<string>,
        ): ReturnType<OverloadReportHubClient["getPublicCountries"]> => {
            const accessToken = await getAccessToken();

            return await get({
                path: `/api/report-hub/public/countries`,
                decoder: D.array(reportHubDecoders.countryDecoder),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getPublicBiddingZones: async (
            getAccessToken: () => Promise<string>,
        ): OverloadApiResponse<Array<ReportHubDto.Country>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/public/countries`,
                decoder: D.array(reportHubDecoders.countryDecoder),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getPublicMarkets: async (
            getAccessToken: () => Promise<string>,
        ): OverloadApiResponse<Array<ReportHubDto.Market>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/public/markets`,
                decoder: D.array(reportHubDecoders.marketDecoder),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getPublicAnnualEmissionFactors: async (
            getAccessToken: () => Promise<string>,
        ): OverloadApiResponse<Array<ReportHubDto.AnnualEmissionFactor>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/public/annual-emission-factors`,
                decoder: D.array(reportHubDecoders.annualEmissionFactorDecoder),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        postAssignmentInitializeByYearByTenantId: async () => Promise.reject("Not yet implemented"),

        getAssignmentCountryAssignmentByTenantId: async ({
            getAccessToken,
            tenantId,
            countryId,
            year,
            page,
            sortField,
            sortOrder,
            groups,
        }): OverloadApiResponse<ReportHubDto.CountryAssignmentsResponse> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/assignment/country-assignment`,
                decoder: countryAssignmentResponseDecoder,
                params: {
                    countryId,
                    year,
                    ...(page && { page }),
                    ...(sortField && { sortField }),
                    ...(sortOrder && { sortOrder }),
                    groups,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getAssignmentKpisByTenantId: async ({
            getAccessToken,
            tenantId,
            year,
            groups,
        }): OverloadApiResponse<ReportHubDto.GetReportingKpisResponse> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/assignment/kpis`,
                decoder: reportingKpisDecoder,
                params: {
                    year,
                    groups,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getTestsYearlyIntensities: async () => Promise.reject("Not yet implemented"),
        getAllocationsTotalPendingAllocationsByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                    query: { year, regionIds },
                },
            },
        ): ReturnType<
            OverloadReportHubClient["getAllocationsTotalPendingAllocationsByTenantId"]
        > => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/allocations/total-pending-allocations`,
                decoder:
                    reportHubDecodersV2["/{tenantId}/allocations/total-pending-allocations"]["get"],
                params: {
                    year,
                    ...(regionIds ? { regionIds } : {}),
                } satisfies InferParams<paths, "/{tenantId}/allocations/total-pending-allocations">,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAssignmentTrackingInstrumentsAvailableFiltersByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                },
            },
        ): ReturnType<
            OverloadReportHubClient["getAssignmentTrackingInstrumentsAvailableFiltersByTenantId"]
        > => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/assignment/tracking-instruments/available-filters`,
                decoder:
                    reportHubDecodersV2[
                        "/{tenantId}/assignment/tracking-instruments/available-filters"
                    ].get,

                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAssignmentByConsumptionGroupsByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                },
            },
        ): ReturnType<OverloadReportHubClient["getAssignmentByConsumptionGroupsByTenantId"]> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/assignment-by-consumption-groups`,
                decoder: reportHubDecodersV2["/{tenantId}/assignment-by-consumption-groups"].get,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getPublicFeatureFlags: async (getAccessToken): OverloadApiResponse<Array<string>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/public/feature-flags`,
                decoder: featureFlagDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAllocationFiltersByTenantId: async ({
            getAccessToken,
            tenantId,
        }): OverloadApiResponse<ReportHubDto.AllocationFilters> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/allocation-filters`,
                decoder: allocationFiltersDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getConsumptionSiteGroupsByTenantId: async (
            getAccessToken,
            {
                params: {
                    path: { tenantId },
                },
            },
        ): ReturnType<OverloadReportHubClient["getConsumptionSiteGroupsByTenantId"]> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/report-hub/${tenantId}/consumption-site-groups`,
                decoder: reportHubDecodersV2["/{tenantId}/consumption-site-groups"].get,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getGlobalOverviewKpisByTenantId: async ({
            getAccessToken,
            tenantId,
            year,
            countryIds,
        }): OverloadApiResponse<ReportHubDto.GlobalOverviewKpis> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/global-overview/kpis`,
                decoder: reportHubDecoders.getGlobalOverviewKpisDecoder,
                params: filterObjectFieldsByUndefinedOrNull({ year, countryIds }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getGlobalOverviewCountriesCoverageByTenantId: async ({
            getAccessToken,
            tenantId,
            year,
            countryIds,
        }): OverloadApiResponse<ReportHubDto.GlobalOverviewCountriesCoverage> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/global-overview/countries-coverage`,
                decoder: reportHubDecoders.getGlobalOverviewCountriesCoverageDecoder,
                params: filterObjectFieldsByUndefinedOrNull({ year, countryIds }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getGlobalOverviewCountriesByTenantId: async ({
            getAccessToken,
            tenantId,
        }): OverloadApiResponse<ReportHubDto.GetCountries> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/global-overview/countries`,
                decoder: reportHubDecoders.getCountriesDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        getGlobalOverviewReportingPeriodsByTenantId: async ({
            getAccessToken,
            tenantId,
        }): OverloadApiResponse<ReportHubDto.GetReportingPeriods> => {
            const accessToken = await getAccessToken();

            return get({
                path: `/api/report-hub/${tenantId}/global-overview/reporting-periods`,
                decoder: reportHubDecoders.getReportingPeriodsDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
    };
}
