import { PaperProps } from "@mantine/core";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { HTMLPropsRef, MRT_ColumnDef, MRT_Icons, MRT_TableInstance } from "mantine-react-table";

export const mrtIcons: Partial<MRT_Icons> = {
    IconSortAscending: IconChevronUp,
    IconSortDescending: IconChevronDown,
    IconArrowsSort: IconChevronDown,
};

export const mrtMantinePaperProps:
    | (HTMLPropsRef<HTMLDivElement> & PaperProps)
    | (<T extends Record<string, any>>(props: {
          table: MRT_TableInstance<T>;
      }) => HTMLPropsRef<HTMLDivElement> & PaperProps) = {
    sx: {
        padding: "0 !important",
        boxShadow: "none",
        borderRadius: 0,
        position: "unset",

        "& > div:has(> table)": {
            position: "unset",
        },
    },
    withBorder: false,
};

export const addTableHeaderIds = <T extends Record<string, any>>(
    columns: Array<MRT_ColumnDef<T>>,
    label: string,
): Array<MRT_ColumnDef<T>> => {
    return columns.map((column) => {
        const { accessorKey, mantineTableHeadCellProps } = column;
        const id: string = `${label}-${accessorKey}-th`;

        if (!accessorKey) {
            return column;
        }

        return mantineTableHeadCellProps
            ? { ...column, mantineTableHeadCellProps: { ...mantineTableHeadCellProps, id } }
            : { ...column, mantineTableHeadCellProps: { id } };
    });
};

export const alignRightCol = <T extends Record<string, any>>(
    columnDefinition: MRT_ColumnDef<T>,
): MRT_ColumnDef<T> => ({
    ...columnDefinition,
    mantineTableBodyCellProps: {
        ...columnDefinition.mantineTableBodyCellProps,
        sx: {
            textAlign: "end !important" as "end",
        },
    },
    mantineTableHeadCellProps: {
        ...columnDefinition.mantineTableHeadCellProps,
        sx: {
            textAlign: "end !important" as "end",
            "> div": { justifyContent: "end" as const },
        },
    },
    enableSorting: columnDefinition.enableSorting ?? false,
    enableColumnFilter: columnDefinition.enableColumnFilter ?? false,
});
