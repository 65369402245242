import { useAppUser } from "./app-user";

export const useActiveTenantId = (): string => {
    const { user } = useAppUser();
    if (!user) {
        console.warn(
            "Using useActiveTenantId hook before user has been initialized. tenantId set to empty string.\nWrap your page with UserInitializedComponent",
        );
    }
    return user?.activeTenant.tenantId ?? "";
};
