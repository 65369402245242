import { formatNumber } from "./format-number";
import { ONE_BILLION, ONE_MILLION, ONE_THOUSAND, ONE_TRILLION } from "../consts";

export const physicalRoundForElectricity = (
    power: number | null,
    decimals: number = 0,
): [string, string] => {
    if (power == null || isNaN(power)) {
        return ["-", ""];
    }

    if (power >= ONE_TRILLION) {
        return [formatNumber(power / ONE_TRILLION, decimals), "T"];
    } else if (power >= ONE_BILLION) {
        return [formatNumber(power / ONE_BILLION, decimals), "G"];
    } else if (power >= ONE_MILLION) {
        return [formatNumber(power / ONE_MILLION, decimals), "M"];
    } else if (power >= ONE_THOUSAND) {
        return [formatNumber(power / ONE_THOUSAND, decimals), "k"];
    } else {
        return [formatNumber(power, decimals), ""];
    }
};
