import {
    EnergySource,
    reportHubPaths,
    TrackingInstrumentType,
    validateStrictDecoder,
} from "@flexidao/dto";
import * as D from "schemawax";
import { dateDecoder, energySourceDecoder, trackingInstrumentTypeDecoder } from "../decoders/misc";
import { components } from "../../../dto/src/schemas/report-hub-api-schema";
import { GetComponent } from "./dto";
import { PathsDecodersGet } from "./helpers";

const biddingZoneAssignmentResponseDecoder = validateStrictDecoder<
    GetComponent<components, "BiddingZoneAssignmentResponse">
>()(
    D.object({
        required: {
            market: D.nullable(D.string),
            countryName: D.string,
            countryId: D.string,
            biddingZoneName: D.string,
            biddingZoneId: D.string,
            carbonIntensity: D.number,
            billingConsumption: D.number,
            totalAssigned: D.number,
            coverage: D.nullable(D.number),
        },
    }) satisfies D.Decoder<GetComponent<components, "BiddingZoneAssignmentResponse">>,
);

const trackingInstrumentCommonDecoder = validateStrictDecoder<
    GetComponent<components, "TrackingInstrumentCommon">
>()(
    D.object({
        required: {
            trackingInstrumentId: D.string,
            consumptionPeriodStart: dateDecoder,
            consumptionPeriodEnd: dateDecoder,
            type: D.literalUnion(...Object.values(TrackingInstrumentType)),
            productionCountry: D.nullable(D.string),
            source: D.nullable(D.literalUnion(...Object.values(EnergySource))),
            productionSite: D.nullable(D.string),
            unassigned: D.number,
            assigned: D.number,

            attestationId: D.nullable(D.string),
            productionFrom: D.nullable(dateDecoder),
            productionTo: D.nullable(dateDecoder),
            transactionDate: D.nullable(dateDecoder),
            market: D.nullable(D.string),
            commissioningDate: D.nullable(dateDecoder),
            certificateIdFrom: D.nullable(D.string),
            certificateIdTo: D.nullable(D.string),
            documentId: D.number,
            scheme: D.nullable(D.string),

            registryId: D.nullable(D.string),
            registryName: D.nullable(D.string),
            transactionFromId: D.nullable(D.string),
            transactionFromName: D.nullable(D.string),
            consumptionOrganizationName: D.nullable(D.string),

            consumptionTimezone: D.string,
            productionTimezone: D.nullable(D.string),
        },
    }) satisfies D.Decoder<GetComponent<components, "TrackingInstrumentCommon">>,
);

const trackingInstrumentResponseDecoder = validateStrictDecoder<
    GetComponent<components, "TrackingInstrumentAssignmentResponse">
>()(
    D.object({
        required: {
            rows: D.array(trackingInstrumentCommonDecoder),
            totalNumRows: D.number,
        },
    }) satisfies D.Decoder<GetComponent<components, "TrackingInstrumentAssignmentResponse">>,
);

const trackingInstrumentStateResponseDecoder = validateStrictDecoder<
    GetComponent<components, "TrackingInstrumentStateResponse">
>()(
    D.object({
        required: {
            rows: D.array(trackingInstrumentCommonDecoder),
            totalNumRows: D.number,
            totalNumAssignments: D.number,
        },
    }) satisfies D.Decoder<GetComponent<components, "TrackingInstrumentStateResponse">>,
);

export const reportHubDecoders = {
    "/{tenantId}/assignment/bidding-zone-assignment/{biddingZoneId}": {
        get: biddingZoneAssignmentResponseDecoder,
    },
    "/{tenantId}/assignment/bidding-zone-assignment": {
        get: D.object({
            required: {
                rows: D.array(biddingZoneAssignmentResponseDecoder),
                totalNumRows: D.number,
            },
        }),
    },
    "/{tenantId}/assignment/tracking-instrument-assignment": {
        get: trackingInstrumentResponseDecoder,
    },
    "/{tenantId}/assignment/tracking-instrument-state": {
        get: trackingInstrumentStateResponseDecoder,
    },
    "/{tenantId}/assignment/reporting-period/{year}": {
        get: D.object({
            required: {
                startReportingPeriod: dateDecoder,
                endReportingPeriod: dateDecoder,
                totalAssigned: D.number,
                totalUnassigned: D.number,
                consumption: D.number,
            },
        }),
    },
    "/{tenantId}/allocations/total-pending-allocations": {
        get: D.object({
            required: {
                totalPendingAllocations: D.number,
            },
        }),
    },
    "/{tenantId}/assignment/tracking-instruments/available-filters": {
        get: D.object({
            required: {
                countries: D.array(
                    D.object({
                        required: {
                            name: D.string,
                            countryId: D.string,
                            marketId: D.nullable(D.string),
                            enabled: D.boolean,
                            passiveProcurement: D.boolean,
                        },
                    }),
                ),
                trackingInstrumentTypes: D.array(trackingInstrumentTypeDecoder),
                schemes: D.array(D.nullable(D.string)),
                sources: D.array(energySourceDecoder),
                markets: D.array(
                    D.object({
                        required: {
                            description: D.string,
                            marketId: D.string,
                            name: D.string,
                        },
                    }),
                ),
            },
        }),
    },
    "/{tenantId}/assignment-by-consumption-groups": {
        get: D.boolean,
    },
    "/{tenantId}/consumption-site-groups": {
        get: D.array(D.object({ required: { name: D.string, consumptionSiteGroupId: D.string } })),
    },
} satisfies Pick<
    PathsDecodersGet<reportHubPaths>,
    | "/{tenantId}/assignment/bidding-zone-assignment"
    | "/{tenantId}/assignment/tracking-instrument-assignment"
    | "/{tenantId}/assignment/tracking-instrument-state"
    | "/{tenantId}/assignment/reporting-period/{year}"
    | "/{tenantId}/assignment/bidding-zone-assignment/{biddingZoneId}"
    | "/{tenantId}/allocations/total-pending-allocations"
    | "/{tenantId}/assignment/tracking-instruments/available-filters"
    | "/{tenantId}/assignment-by-consumption-groups"
    | "/{tenantId}/consumption-site-groups"
>;
