import { ContractType, MatchingLogic } from "./";

export type IKind<T> = {
    kind: T;
};
export enum TwentyFourSevenFeatureFlags {}

export namespace TwentyFourSevenDto {
    export type SyncResponse<T> = {
        data: Array<T>;
        next_cursor: string | null;
        has_more: boolean;
    };
    export type Country = {
        countryId: string;
        name: string;
        enabled: boolean;
    };

    // countries and ISOs
    export type Region = {
        regionId: string;
        name: string;
    };

    export type Kpis = {
        consumption_Wh: number;
        contractedCfe_Wh: number;
        contractedCfe_Percentage: number;
        gridMixCfe_Wh: number;
        gridMixCfe_Percentage: number;
        gridMixNonCfe_Wh: number;
        gridMixNonCfe_Percentage: number;
        excessCfe_Wh: number;
        excessCfe_Percentage: number;
        locationBasedEmissions_gCO2: number;
        marketBasedEmissions_gCO2: number;
    };

    export type CfeCoverageValues = {
        contractedCfe_Wh: number;
        gridMixCfe_Wh: number;
        gridMixNonCfe_Wh: number;
        excess_Wh: number;
        consumption_Wh: number;
    };

    export type CfeCoverage = {
        startTime: string;
        endTime: string;
        values: CfeCoverageValues;
    };

    export type ContractPerformance = {
        startTime: string;
        endTime: string;
        name: string;
        contractType: ContractType;
        contractedCfe_Wh: number;
        excess_Wh: number;
        compliantCFE: boolean;
        region: string;
    };

    export type ContractsPerformanceResponse = {
        contracts: Array<ContractPerformance>;
    };

    export type ContractProductions = {
        contractId: string;
        startTime: Date;
        endTime: Date;
        contracted_Wh: number;
        // status: unknown
        created: Date;
        updated: Date;
        // deleted: Date
    };
    export type SyncContractedMeterDataResponse = SyncResponse<ContractProductions>;

    export type BaseContract = {
        contractId: string;
        tenantId: string;
        name: string;
        startTime: Date;
        endTime: Date;
        contractType: ContractType;
        matchingLogic: MatchingLogic;
        percentageCoverage: number;
        compliantCfe: boolean;
        regionId: string;
    };

    export type HistoricalFinancialPerformance = {
        year: number;
        periodData: Array<{
            period: Date;
            totalContractedCfeProductionWh: number | null;
            averageFixedPriceMwh: number | null;
            averageMarketPriceMwh: number | null;
            totalNetCashflow: number | null;
        }>;
    };
}
