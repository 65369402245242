import { FiltersState, labelToDataId } from "@flexidao/ui-lib";
import {
    MantineReactTable,
    MRT_ColumnDef,
    MRT_ColumnFiltersState,
    MRT_SortingState,
    MRT_VisibilityState,
    useMantineReactTable,
} from "mantine-react-table";
import { ReactElement, useMemo } from "react";
import { getMRTColumnsFromColumns } from "./columns/column-parser";
import { filtersToMrtFilters, mrtFiltersToFilters } from "./filtering/utils";
import { getExpandedRowModel } from "./hack";
import { BottomToolbarCustomActions } from "./pagination/bottom-toolbar-custom-actions";
import { PaginationState } from "./pagination/types";
import { RowActionColumnPosition } from "./row-actions/types";
import { rowActionsToMrtRowActions } from "./row-actions/utils";
import { SortingState } from "./sorting/types";
import { mrtSortingToSorting, sortingToMrtSorting } from "./sorting/utils";
import { getTableStyles } from "./styles";
import { ControlledProps, DataControllerType, OnChangeFn, RowData, TableProps } from "./types";
import {
    displayColumnFilters,
    displayTopToolbar,
    getColumnVisibility,
    getEnableExpanding,
    getRenderDetailPanel,
    getShouldDisplayPagination,
    getSubRowsGetter,
    getValueFromUpdater,
    parseData,
    tableStateToMrtTableState,
} from "./utils";

/**
 * Table is a generic component for displaying tabular data.
 *
 * @template T - A record type where keys are strings and values can be any type.
 * @param {TableProps<T>} props - The props for the Table component.
 * @param {DataControllerType} props.dataControllerType - The type of data controller. Controlled means that the parent component controls the data, while uncontrolled means that the Table component controls the data.
 * @param {PaginationState | undefined} props.pagination - The current pagination state.
 * @param {(_: PaginationState) => void | undefined} props.onPaginationChange - A function that updates the pagination state.
 * @param {SortingState<T> | null | undefined} props.sorting - The current sorting state.
 * @param {(_: SortingState<T> | null) => void | undefined} props.onSortingChange - A function that updates the sorting state.
 * @param {FiltersState<T> | null | undefined} props.filters - The current filters state.
 * @param {(_: FiltersState<T> | null) => void | undefined} props.onFiltersChange - A function that updates the filters state.
 * @param {string | undefined} props.globalFilter - The current global filter.
 * @param {(_: string) => void | undefined} props.onGlobalFilterChange - A function that updates the global filter.
 * @param {Array<number> | undefined} props.rowsPerPageOptions - An array of options for rows per page. If the array is defined, the rows per page dropdown will be displayed.
 * @param {number} props.rowCount - The total number of rows for the table.
 * @param {boolean | undefined} props.showLoadingOverlay - A boolean to show or hide the loading overlay.
 * @param {Array<Column<T>>} props.columns - An array of Column objects, which define the structure of the table.
 * @param {Array<T> | null} props.data - An array of data of type T, or null if no data wants to be displayed.
 * @param {boolean | undefined} props.showSkeletons - A boolean to show or hide the skeleton.
 * @param {(row: T) => ReactNode | undefined} props.detailPanel - A function that returns a ReactNode to display in the details panel.
 * @param {string | undefined} props.dataId - A string to identify the table.
 * @param {boolean | undefined} props.enableRowActions - A boolean to enable or disable row actions.
 * @param {RowActionColumnPosition} props.rowActionsColumnPosition - The position of the row actions column.
 * @param {RowActionsRenderer<T> | undefined} props.rowActionsCellRenderer - A function that returns a ReactNode to display in the row actions cell.
 * @param {TableState<T> | undefined} props.initialState - The initial state of the table.
 * @param {boolean | undefined} props.enableTableFooter - A boolean to enable or disable the table footer.
 * @param {string | undefined} props.actionsColumnTitle - The title of the actions column.
 *
 * @returns {ReactElement} A React element representing the Table component.
 *
 * @example
 * // A table skeleton
 * <Table
 *    dataControllerType={DataControllerType.Uncontrolled}
 *    columns={columns}
 *    data={null}
 *    rowCount={0}
 *    showSkeletons />
 *
 * @example
 * // A table with controlled pagination, sorting, and filtering
 * const [pagination, setPagination] = useState<PaginationState>(DEFAULT_PAGINATION);
 * const [sorting, setSorting] = useState<SortingState<T> | null>(null);
 * const [filters, setFilters] = useState<FiltersState<T> | null>(null);
 *
 * <Table
 *   dataControllerType={DataControllerType.Controlled}
 *   columns={columns}
 *   data={data}
 *   rowCount={rowCount}
 *   pagination={pagination}
 *   onPaginationChange={onPaginationChange}
 *   sorting={sorting}
 *   onSortingChange={onSortingChange}
 *   filters={filters}
 *   onFiltersChange={onFiltersChange} />
 *
 * @example
 * // A table with dynamic page size
 * const [pagination, setPagination] = useState<PaginationState>(DEFAULT_PAGINATION);
 *
 * <Table
 *   dataControllerType={DataControllerType.Uncontrolled}
 *   columns={columns}
 *   data={data}
 *   rowCount={rowCount}
 *   rowsPerPageOptions={[10, 50, 100]} />
 */
export const Table = <T extends RowData>({
    data: data_,
    columns,
    rowCount,
    rowsPerPageOptions,
    detailPanel,
    rowActionsCellRenderer,
    dataControllerType,
    initialState,
    showLoadingOverlay = false,
    showSkeletons = false,
    dataId = "table",
    enableRowActions = false,
    rowActionsColumnPosition = RowActionColumnPosition.Last,
    enableTableFooter = false,
    enableGlobalFilter = false,
    actionsColumnTitle = "Actions",
    ...controlledProps
}: TableProps<T>): ReactElement => {
    const isControlled: boolean = dataControllerType === DataControllerType.Controlled;

    // Controlled props
    const {
        pagination,
        onPaginationChange,
        disablePagination,
        sorting,
        onSortingChange,
        filters,
        globalFilter,
        onFiltersChange,
        onGlobalFilterChange,
    } = useMemo(
        () =>
            dataControllerType === DataControllerType.Controlled
                ? (controlledProps as ControlledProps<T>)
                : {
                      pagination: undefined,
                      onPaginationChange: undefined,
                      disablePagination: undefined,
                      sorting: undefined,
                      onSortingChange: undefined,
                      filters: undefined,
                      onFiltersChange: undefined,
                      globalFilter: undefined,
                      onGlobalFilterChange: undefined,
                  },
        [dataControllerType, controlledProps],
    );

    // Data props
    const data: Array<T> | null = useMemo(
        () =>
            parseData({
                data: data_,
            }),
        [data_],
    );

    // Details panel props
    const renderDetailPanel = useMemo(() => getRenderDetailPanel(detailPanel), [detailPanel]);

    // Sub-rows props
    const enableExpanding: boolean = useMemo(() => getEnableExpanding(data), [data]);
    const getSubRows: ((row: T) => Array<T> | undefined) | undefined = useMemo(
        () => getSubRowsGetter(enableExpanding),
        [enableExpanding],
    );

    // Columns props
    const mrtColumns: Array<MRT_ColumnDef<T>> = useMemo(
        () => getMRTColumnsFromColumns(columns),
        [columns],
    );
    const columnVisibility: MRT_VisibilityState = useMemo(
        () => getColumnVisibility(columns),
        [columns],
    );
    const enableColumnFilters: boolean = useMemo(() => displayColumnFilters(columns), [columns]);

    // Pagination props
    const showRowsPerPage: boolean = useMemo(
        () => rowsPerPageOptions != null && rowsPerPageOptions.length > 1,
        [rowsPerPageOptions],
    );
    const displayPagination: boolean = useMemo(
        () =>
            getShouldDisplayPagination({
                dataControllerType,
                disablePagination,
                rowCount,
                pagination,
                rowsPerPageOptions,
            }),
        [dataControllerType, disablePagination, rowCount, pagination, rowsPerPageOptions],
    );
    const onMrtPaginationChange: OnChangeFn<PaginationState> = (paginationUpdater): void => {
        if (isControlled && pagination != null) {
            const newPagination: PaginationState = getValueFromUpdater(
                paginationUpdater,
                pagination,
            );

            onPaginationChange?.(newPagination);
        }
    };
    const mrtRowsPerPageOptions: Array<string> | undefined = useMemo(() => {
        if (rowsPerPageOptions == null) {
            return undefined;
        }

        return rowsPerPageOptions.map((option: number): string => option.toString());
    }, [rowsPerPageOptions]);
    const manualPagination: boolean = isControlled;

    // Sorting props
    const mrtSortingState: MRT_SortingState = useMemo(
        () => sortingToMrtSorting(sorting),
        [sorting],
    );
    const onMrtSortingChange: OnChangeFn<MRT_SortingState> = (mrtSortingStateUpdater): void => {
        const newMrtSortingState: MRT_SortingState = getValueFromUpdater(
            mrtSortingStateUpdater,
            mrtSortingState,
        );

        const newSortingState: SortingState<T> | null = mrtSortingToSorting(newMrtSortingState);
        onSortingChange?.(newSortingState);
    };
    const manualSorting: boolean = isControlled;

    // Filtering props
    const mrtFiltersState: MRT_ColumnFiltersState = useMemo(
        () => filtersToMrtFilters(filters),
        [filters],
    );
    const onMrtFiltersChange: OnChangeFn<MRT_ColumnFiltersState> = (
        mrtFiltersStateUpdater,
    ): void => {
        const newMrtFiltersState: MRT_ColumnFiltersState = getValueFromUpdater(
            mrtFiltersStateUpdater,
            mrtFiltersState,
        );

        const newFiltersState: FiltersState<T> | null = mrtFiltersToFilters(newMrtFiltersState);
        onFiltersChange?.(newFiltersState);
    };
    const manualFiltering: boolean = isControlled;

    // Top toolbar props
    const enableTopToolbar: boolean = useMemo(() => displayTopToolbar(columns), [columns]);

    // Bottom toolbar props
    const renderBottomToolbarCustomActions: () => ReactElement | null = () => (
        <BottomToolbarCustomActions pagination={pagination} numRows={rowCount} dataId={dataId} />
    );

    // Row actions props
    const mrtRowActionsCellRenderer = useMemo(
        () => rowActionsToMrtRowActions(rowActionsCellRenderer),
        [rowActionsCellRenderer],
    );

    // Initial state props
    const mrtInitialState = useMemo(
        () =>
            tableStateToMrtTableState({
                tableState: initialState,
                columnVisibility,
                showGlobalFilter: enableGlobalFilter,
            }),
        [initialState, columnVisibility, enableGlobalFilter],
    );

    // Style props
    const tableStyles = useMemo(
        () =>
            getTableStyles<T>({
                dataIdPrefix: dataId,
                enableExpanding,
                actionsColumnTitle,
            }),
        [],
    );

    const table = useMantineReactTable({
        // Style props
        ...tableStyles,

        columns: mrtColumns,
        data: data ?? [],
        rowCount,

        // Detail panel props
        renderDetailPanel,

        // Sub-rows props
        enableExpanding,
        paginateExpandedRows: false,
        enableExpandAll: true,
        manualExpanding: false,
        filterFromLeafRows: false,
        maxLeafRowFilterDepth: 0,
        getSubRows,
        getExpandedRowModel,

        // Initial state and current state
        initialState: mrtInitialState as any,
        state: {
            ...(manualSorting && { sorting: mrtSortingState }),
            ...(manualFiltering && { columnFilters: mrtFiltersState }),
            ...(enableGlobalFilter && { globalFilter }),
            ...(manualPagination && { pagination }),
            showGlobalFilter: enableGlobalFilter,
            showProgressBars: showLoadingOverlay,
            showSkeletons,
        },

        // Generic props
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableColumnActions: false,

        // Toolbar props
        enableTopToolbar,

        // Sorting props
        enableSorting: true,
        enableMultiSort: false,
        manualSorting,
        ...(manualSorting && { onSortingChange: onMrtSortingChange }),

        // Filtering props
        enableColumnFilters,
        manualFiltering,
        ...(manualFiltering && { onColumnFiltersChange: onMrtFiltersChange }),
        enableFilterMatchHighlighting: false,
        columnFilterDisplayMode: "subheader",

        // Global filtering props
        enableGlobalFilter,
        enableGlobalFilterModes: false,
        ...(enableGlobalFilter && { onGlobalFilterChange }),

        // Pagination props
        enablePagination: true,
        enableBottomToolbar: displayPagination,
        manualPagination,
        ...(manualPagination && { onPaginationChange: onMrtPaginationChange }),
        paginationDisplayMode: "pages",
        mantinePaginationProps: {
            variant: "mantine",
            rowsPerPageOptions: mrtRowsPerPageOptions,
            showRowsPerPage,
            withEdges: true,
            withControls: true,
            className: "pagination",
            id: labelToDataId({
                prefix: dataId,
                label: "pagination",
            }),
        },
        renderBottomToolbarCustomActions,

        // Row actions props
        enableRowActions,
        renderRowActions: mrtRowActionsCellRenderer,
        positionActionsColumn: rowActionsColumnPosition,
    });

    return <MantineReactTable table={table} />;
};
