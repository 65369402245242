import { CSSObject, Flex, Group, MantineTheme } from "@mantine/core";
import { LegendOrdinal } from "@visx/legend";
import { ScaleOrdinal } from "d3-scale";
import { LegendDotWithLabel } from "../../utils";
import { ReactElement } from "react";
import { AreaChartCurveSeries } from "../types";

type AreaChartLegendProps = {
    colorScale: ScaleOrdinal<string, string>;
    series: AreaChartCurveSeries;
    justifyLegend: "start" | "center" | "end";
};

export const AreaChartLegend = ({
    colorScale,
    series,
    justifyLegend,
}: AreaChartLegendProps): ReactElement => {
    return (
        <Flex w="100%" justify={justifyLegend}>
            <LegendOrdinal scale={colorScale}>
                {(labels): ReactElement => (
                    <Group
                        sx={(theme: MantineTheme): CSSObject => ({
                            columnGap: theme.spacing.lg,
                            rowGap: "0.5rem",
                        })}
                    >
                        {labels.map((label, i) => {
                            const labelText: string = series.seriesLabel;

                            return (
                                <LegendDotWithLabel
                                    key={i}
                                    text={labelText}
                                    color={label.value ?? "black"}
                                />
                            );
                        })}
                    </Group>
                )}
            </LegendOrdinal>
        </Flex>
    );
};
