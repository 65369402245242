import { notifications } from "@mantine/notifications";
import { IconAlertCircle, IconUserCheck } from "@tabler/icons-react";
const LOGIN_NOTIFICATION_ID = "login-notification";

export const loginTokenNotification = (): void =>
    notifications.show({
        id: LOGIN_NOTIFICATION_ID,
        loading: true,
        message: "Got refresh token",
    });
export const loginDetailsNotification = (): void =>
    notifications.show({
        id: LOGIN_NOTIFICATION_ID,
        loading: true,
        message: "Fetching your details",
    });
export const loginCompleteNotification = (): void =>
    notifications.update({
        autoClose: 2000,
        id: LOGIN_NOTIFICATION_ID,
        message: "You're signed in!",
        icon: <IconUserCheck />,
    });
export const loginErrorNotification = (): void =>
    notifications.update({
        id: LOGIN_NOTIFICATION_ID,
        title: "Error signing in",
        message: "Please contact FlexiDAO if problems persist",
        color: "flexidaoRed",
        icon: <IconAlertCircle />,
    });
