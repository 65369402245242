import {
    bimComponents,
    bimPaths,
    CfeConnectNavPage,
    CfeConnectPage,
    CfeDiagnosticNavPage,
    CfeDiagnosticPage,
    CfeInventoryNavPage,
    CfeInventoryPage,
    ContractType,
    EnergySource as EnergySourceEnum,
    Module as ModuleEnum,
    Page as PageEnum,
    ResourceAction,
    Service,
    SettingsNavPage,
    SettingsPage,
    SiteType,
    ZoneDataAccessLevel,
} from "./";

export enum BimFeatureFlags {}

export namespace BimDto {
    export type SyncResponse<T> = {
        data: Array<T>;
        next_cursor: string | null;
        next_cursor_encoded: string | null;
        has_more: boolean;
    };
    // FLEXIDAO SERVICE

    export type FlexidaoService = {
        flexidaoServiceId: Service;
        name: string;
        description: string;
        tenants?: Array<Tenant>;
    };
    export type FlexidaoServicePopulateParam = Array<"tenants">;

    // TENANT

    export type Tenant = {
        tenantId: string;
        name: string;
        description?: string;
        status: TenantStatus;
        services?: Array<FlexidaoService> | Array<string>;
        users?: Array<User>;
        premium: boolean;
        contactEmail: string;
        procurementAccountId?: string | null;
    };

    export type CreateTenantPayload = {
        userEmail: string;
        status: TenantStatus;
        premium: boolean;
        procurementAccountId?: string;
        name: string;
    };

    export enum TenantStatus {
        Pending = "pending",
        Approved = "approved",
        Rejected = "rejected",
        Cancelled = "cancelled",
    }

    export type UpdateTenantPayload = {
        name?: string;
        description?: string;
        status?: TenantStatus;
        services?: Array<Service>;
    };

    // AUTHORIZATION

    export type AuthorizeActionResponse = {
        identity: "serviceAccountId" | "userId";
        id: string;
        permissionMatches: Array<{
            permissionId?: string;
            oAuthScope?: string;
        }>;
        tenantId?: string;
        roles?: Array<string>;
    };

    // USER

    export type User = {
        userId: string;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        enabled: boolean;
        auth0UserId: string;
        auth0UserEmail: string;
        roles: Array<RoleOnUser>;
    };

    export type RoleOnUser = {
        roleId: string;
        name: string;
    };

    export type GetUsersResponse = {
        totalPages: number;
        users: Array<User>;
    };

    export type UpdateUserPayload = {
        firstName?: string | null;
        lastName?: string | null;
        enabled?: boolean;
        email?: string | null;
        auth0UserId?: string;
        auth0UserEmail?: string;
    };

    // SELF

    export type AppUser = {
        userId: string;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        enabled: boolean;
        auth0UserId: string;
        auth0UserEmail: string;
        roleIds: Array<string>;
        activeTenant: ActiveTenant;
        tenants: Array<AppUserTenantOption>;
    };

    export type AppUserTenantOption = {
        tenantId: string;
        name: string;
        status: TenantStatus;
        premium: boolean;
    };

    export type UpdateAppUserPayload = Omit<UpdateUserPayload, "enabled"> & {
        activeTenantId?: string;
    };

    export const SelfPermission = {};

    export type ActiveTenant = {
        tenantId: string;
        name: string;
        status: TenantStatus;
        services: Array<FlexidaoService>;
        premium: boolean;
        displaySettings: TenantDisplay;
    };

    // Auth0 Actions

    export type Auth0PostLoginActionPayload = {
        auth0UserId: string;
        auth0UserEmail: string;
    };
    export type Auth0PostRegistrationActionPayload = Auth0PostLoginActionPayload & {
        isUserInitiated: boolean;
    };

    // PERMISSION

    export type Permission = {
        permissionId: string;
        flexidaoService: FlexidaoService | Service;
        resource: string;
        action: ResourceAction;
        roles: Array<Role> | Array<string>;
    };
    export type CreatePermissionPayload = {
        flexidaoServiceId: Service;
        resource: string;
        ownerTenantId: string;
        action: ResourceAction;
        roleId: string;
    };

    // ROLE

    export type Role = {
        roleId: string;
        name: string;
        tenant: Tenant | string;
        users?: Array<User>;
        permissions?: Array<Permission | string>;
    };
    export type CreateRolePayload = {
        tenantId: string;
        name: string;
    };

    // USER ROLE

    export type UserRole = {
        roleId: string;
        user: User;
    };
    export type CreateUserRolePayload = {
        userId: string;
        roleId: string;
        tenantId: string;
    };
    export type CreateUserRolesPayload = {
        userId: string;
        roleIds: Array<string>;
        tenantId: string;
    };

    export type UserRolePopulateParam = Array<"user">;

    // TENANT USER ROLE

    export type TenantUser = {
        userId: string;
        tenant: Tenant;
    };

    export type TenantUserPopulateParam = Array<"tenant">;

    // SITE

    export type Site = {
        siteId: string;
        name: string;
        tenantId: string;
        siteType: SiteType;
        energySource?: EnergySourceEnum;
        country: Country;
        biddingZone: BiddingZone;
        timezone: string;
        consumptionSiteGroupId: string | null;
        installedCapacityW: number | null;
    };

    export type SyncSite = {
        siteId: string;
        name: string;
        tenantId: string;
        siteType: string;
        energySource?: string;
        countryId: string;
        biddingZoneId: string;
        timezone: string;
        consumptionSiteGroupId: string | null;
        created: Date;
        updated: Date;
        deleted?: Date;
        installedCapacityW: number | null;
    };

    export type SiteSyncResponse =
        bimPaths["/{tenantId}/sync/sites"]["get"]["responses"]["200"]["content"]["application/json"];

    export type UpsertSitePayload =
        | {
              name: string;
              siteType: SiteType.Producer;
              energySourceId: EnergySourceEnum;
              countryId: string;
              biddingZoneId: string;
              timezone: string;
              consumptionSiteGroupId: null;
              installedCapacityW: number | null;
          }
        | {
              name: string;
              siteType: SiteType.Consumer;
              countryId: string;
              biddingZoneId: string;
              timezone: string;
              consumptionSiteGroupId: string | null;
              installedCapacityW: number | null;
          };

    export type BiddingZone = {
        zoneId: string;
        name: string;
        country: Country;
        timezones: Array<string>;
        enabled: boolean;
        dataAccess: ZoneDataAccessLevel;
        region: Region;
    };

    export type Country = {
        countryId: string;
        name: string;
        enabled: boolean;
    };

    export type Region = {
        regionId: string;
        name: string;
    };

    export type GetSitesByTenantIdResponse = {
        totalSites: number;
        sites: Array<Site>;
    };

    export type CountryOption = {
        countryId: string;
        name: string;
    };

    export type BiddingZoneOption = {
        zoneId: string;
        name: string;
    };

    export type GetSitesFiltersResponse = {
        siteTypes: Array<SiteType>;
        countries: Array<CountryOption>;
        biddingZones: Array<BiddingZoneOption>;
    };

    export type EnergySource = {
        energySourceId: EnergySourceEnum;
        isCfe: boolean;
        name: string;
    };

    export type Currency = {
        isoCode: string;
        name: string;
        order: number;
    };

    export type BiddingZoneInCountry = {
        zoneId: string;
        name: string;
        enabled: boolean;
        timezones: Array<string>;
    };

    export type CountryWithZones = {
        countryId: string;
        name: string;
        enabled: boolean;
        biddingZones: Array<BiddingZoneInCountry>;
    };

    //Contract

    export type ContractPPA = bimComponents["schemas"]["ContractPPA"];

    export type ContractGreenTariff = bimComponents["schemas"]["ContractGreenTariff"];

    export type ContractBundled = ContractPPA | ContractGreenTariff;

    export type ContractUnbundled = bimComponents["schemas"]["ContractUnbundled"];

    export type Contract = ContractBundled | ContractUnbundled;

    export const isUnbundledSyncContract = (
        contract: SyncContract,
    ): contract is SyncContractUnbundled => contract.contractType === ContractType.UnbundledEACs;

    export const isUnbundledContract = (contract: Contract): contract is ContractUnbundled =>
        contract.contractType === ContractType.UnbundledEACs;

    export type SyncContractBundled = bimComponents["schemas"]["SyncContractBundled"];

    export type SyncContractUnbundled = bimComponents["schemas"]["SyncContractUnbundled"];

    export type SyncContract = SyncContractBundled | SyncContractUnbundled;

    export type ContractSyncResponse =
        bimPaths["/{tenantId}/sync/contracts"]["get"]["responses"]["200"]["content"]["application/json"];

    export type ContractPPAPayload = bimComponents["schemas"]["ContractPPAPayload"];

    export type ContractGreenTariffPayload = bimComponents["schemas"]["ContractGreenTariffPayload"];

    export type ContractBundledPayload = bimComponents["schemas"]["ContractBundledPayload"];

    export type ContractUnbundledPayload = bimComponents["schemas"]["ContractUnbundledPayload"];

    export type ContractPayload = ContractBundledPayload | ContractUnbundledPayload;

    export const isUnbundledContractPayload = (
        contract:
            | ContractPayload
            // This is for the type from openapi yaml where contractType is not an enum member
            | (Omit<ContractPayload, "contractType"> & { contractType: string }),
    ): contract is ContractUnbundledPayload => contract.contractType === ContractType.UnbundledEACs;

    export const isBundledContractPayload = (
        contract:
            | ContractPayload
            // This is for the type from openapi yaml where contractType is not an enum member
            | (Omit<ContractPayload, "contractType"> & { contractType: string }),
    ): contract is ContractBundledPayload =>
        contract.contractType === ContractType.PPA ||
        contract.contractType === ContractType.GreenTariff;

    export type GetContractsResponse = {
        totalContracts: number;
        contracts: Array<Contract>;
    };

    export type GetContractsFiltersResponse = {
        regions: Array<Region>;
        contractTypes: Array<ContractType>;
        compliantCFE: Array<boolean>;
    };

    export enum SchemeKind {
        AuthorizationCode = "authorization-code",
        ClientCredentials = "client-credentials",
    }

    export type AuthorizeActionPayload = {
        tenantId?: string;
        jwt: string;
        schemes: Array<{
            kind: SchemeKind;
            scopeStrings: Array<string>;
        }>;
    };

    export type SiteSummary = {
        siteId: string;
        name: string;
        regionId: string;
    };

    export type ConsumptionSitesSummary = {
        regions: Array<Region>;
        consumptionSites: Array<SiteSummary>;
    };

    // Marketplace event

    export type CreateGoogleMarketplaceEventPayload = {
        eventId: string;
        tenantId?: string;
        procurementAccountId?: string;
        acknowledged: boolean;
        eventType: string;
        event: JSON;
    };

    // Consumption Site Group

    export type BaseConsumptionSiteGroup = {
        consumptionSiteGroupId: string;
        tenantId: string;
        name: string;
        siteIds: Array<string>;
    };

    export type ConsumptionSiteGroupDetail = {
        consumptionSiteGroupId: string;
        tenantId: string;
        name: string;
        sites: Array<ConsumptionSiteGroupDetailSite>;
    };
    export type ConsumptionSiteGroupDetailSite = {
        siteId: string;
        name: string;
        countryName: string;
    };

    // App and navigation

    export type Page = {
        pageId: PageEnum;
        name: string;
        moduleId: ModuleEnum;
        isNavItem: boolean;
    };

    type ModuleDisplay<L, P> = {
        isVisible: boolean;
        landingPageId: L | null;
        visibleNavigationPageIds: Array<P>;
    };

    export type TenantDisplay = {
        homepage: Page | null;
        modules: {
            [ModuleEnum.Settings]: ModuleDisplay<SettingsNavPage, SettingsPage>;
            [ModuleEnum.CfeConnect]: ModuleDisplay<CfeConnectNavPage, CfeConnectPage>;
            [ModuleEnum.CfeDiagnostic]: ModuleDisplay<CfeDiagnosticNavPage, CfeDiagnosticPage>;
            [ModuleEnum.CfeInventory]: ModuleDisplay<CfeInventoryNavPage, CfeInventoryPage>;
        };
    };
}
