import { RowData } from "../types";

export enum SortingDirection {
    Asc = "asc",
    Desc = "desc",
}

export type SortingState<T extends RowData> = {
    columnId: Extract<keyof Omit<T, "rowExtraData">, string>;
    direction: SortingDirection;
};

export type UnControlledSortingProps = {};

export type ControlledSortingProps<T extends RowData> = {
    sorting?: SortingState<T> | null;
    onSortingChange?: (newSortingState: SortingState<T> | null) => void;
};
