import { Service } from "@flexidao/dto";
import { ONE_SECOND_MS } from "@flexidao/helpers";
import { Anchor, Button, Code, Container, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { IconArrowLeft, IconExternalLink, IconReload } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const RELOAD_SECONDS = 30;

export const Err502Page = ({ service }: { service: Service }): JSX.Element => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState<number>(RELOAD_SECONDS);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, ONE_SECOND_MS);

        if (countdown === 0) {
            window.location.reload();
        }

        return () => clearInterval(intervalId);
    }, [countdown]);

    return (
        <Container size="lg" h="100%">
            <Stack align="center" justify="center" h="100%">
                <Paper>
                    <Stack>
                        <Title order={1} fz="2rem" color="red">
                            502 - Service Down
                        </Title>
                        <Text>
                            We aren't getting any response from <Code>{service}</Code> - the backend
                            service for this page.
                        </Text>
                        <Text>
                            Its possible that the service is being updated and will soon be back
                            online, so this page will automatically refresh in {countdown} seconds.
                        </Text>
                        <Text>If problems persist, please contact us or try again later.</Text>
                        <Group mt="md">
                            <Button
                                rightIcon={<IconReload size="1rem" />}
                                onClick={(): void => window.location.reload()}
                                uppercase
                            >
                                Refresh now
                            </Button>
                            <Anchor
                                href="https://flexidao.atlassian.net/servicedesk/customer/portal/1"
                                target="_blank"
                            >
                                <Button
                                    rightIcon={<IconExternalLink size="1rem" />}
                                    uppercase
                                    color="flexidaoBlue"
                                >
                                    Contact us
                                </Button>
                            </Anchor>
                            <Button
                                rightIcon={<IconArrowLeft size="1rem" />}
                                uppercase
                                variant="light"
                                onClick={(): void => navigate(-1)}
                            >
                                Back
                            </Button>
                        </Group>
                    </Stack>
                </Paper>
            </Stack>
        </Container>
    );
};
