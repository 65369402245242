import { MRT_ColumnDef } from "mantine-react-table";
import { ColumnAlign } from "../types";
import { RowData } from "../../types";

export const alignColumn = <T extends RowData>(
    columnAlign: ColumnAlign,
    columnDef: MRT_ColumnDef<T>,
): MRT_ColumnDef<T> => {
    const alignedColumn: MRT_ColumnDef<T> = {
        ...columnDef,
        mantineTableBodyCellProps: {
            ...columnDef.mantineTableBodyCellProps,
            sx: {
                textAlign: `${columnAlign} !important` as ColumnAlign,
            },
        },
        mantineTableHeadCellProps: {
            ...columnDef.mantineTableHeadCellProps,
            sx: {
                textAlign: `${columnAlign} !important` as ColumnAlign,
                "> div": {
                    justifyContent: `${columnAlign} !important` as ColumnAlign,
                },
            },
        },
    };

    return alignedColumn;
};
