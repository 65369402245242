import * as D from "schemawax";

export type TypesAreEqual<
    Left,
    Right,
    FailLeftExtendsRight = "The first type cannot be assigned to the second type",
    FailRightExtendsLeft = "The second type cannot be assigned to the first type",
> = [Left] extends [Right] //
    ? [Right] extends [Left]
        ? Left
        : FailRightExtendsLeft
    : FailLeftExtendsRight;

export type StrictDecoder<T, DI> =
    DI extends D.Decoder<infer U>
        ? D.Decoder<
              TypesAreEqual<
                  T,
                  U,
                  "The output of the decoder is not assignable to the given type",
                  "The given type is not assignable to the output of the decoder"
              >
          >
        : never;

export const validateStrictDecoder = <TypeToValidate>() => {
    return <DecoderImplementation>(
        v: StrictDecoder<DecoderImplementation, D.Decoder<TypeToValidate>>,
    ): StrictDecoder<DecoderImplementation, D.Decoder<TypeToValidate>> => v;
};
