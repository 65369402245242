/* eslint-disable no-magic-numbers */
import { MantineTheme, Stack, useMantineTheme } from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import { scaleBand, scaleLinear } from "@visx/scale";
import { useMemo } from "react";
import { TickFormatter } from "../axes";
import { PositiveNegativeBarChartTemplate } from "./template";

type PositiveNegativeBarChartProps<K extends Record<string, number | Date | null>> = {
    data: Array<K>;
    height: number;
    label: string;
    getXValue: (d: K) => number;
    getYValue: (d: K) => number | null;
    xTickFormatter: TickFormatter;
    yTickFormatter: TickFormatter;
    xNumTicks: number;
    fill?: string;
};

const Y_MARGIN = 36;
const X_MARGIN = 72;
const EMPTY_CHART_X_MARGIN = 32;

export const PositiveNegativeBarChart = <K extends Record<string, number | Date | null>>({
    data,
    fill = "rgba(125, 67, 249, 1)",
    height,
    getXValue,
    getYValue,
    xNumTicks,
    ...props
}: PositiveNegativeBarChartProps<K>): JSX.Element => {
    const theme: MantineTheme = useMantineTheme();
    const { ref, width } = useElementSize();
    const axisColor: string = theme.colors.flexidaoGrey[5];
    const numericYValues = data.map(getYValue).filter((x) => x !== null) as number[];
    const maxVal = Math.max(...numericYValues, 0);
    const minVal = Math.min(...numericYValues, 0);
    const range = maxVal - minVal;
    const xMax = width - (range === 0 ? EMPTY_CHART_X_MARGIN : X_MARGIN);
    const xScale = useMemo(
        () =>
            scaleBand<number>({
                range: [0, xMax],
                round: true,
                padding: 0.4,
                domain: data.map(getXValue),
            }),
        [xMax, data, getXValue],
    );

    const yMax = height - Y_MARGIN;
    const yMin = 0;
    const lowerHeight = (-minVal / range) * yMax;
    const upperHeight = yMax - lowerHeight;
    const yScale = useMemo(
        () =>
            scaleLinear<number>({
                range: [yMax, yMin],
                round: true,
                domain: [minVal, maxVal],
            }),
        [yMin, yMax, minVal, maxVal],
    );
    return (
        <Stack ref={ref} h={height} w="100%" px="md" mb={48} spacing={0}>
            <PositiveNegativeBarChartTemplate
                {...props}
                xNumTicks={xNumTicks}
                axisColor={axisColor}
                xScale={xScale}
                upperHeight={upperHeight}
                yScale={yScale}
                xMax={xMax}
                data={data}
                yMax={yMax}
                fill={fill}
                height={height}
                getXValue={getXValue}
                getYValue={getYValue}
            />
        </Stack>
    );
};
