import { Page } from "@flexidao/dto";
import { useLocation } from "@flexidao/ui-lib";
import { Anchor, Box, CSSObject, Group, Stack, Text } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { ReactNode } from "react";
import { Link, NavLink } from "react-router-dom";

export type NavLinkProps = {
    to: string;
    text: string;
    isInternal: boolean;
    subPages?: Array<Page>;
};

export const NavbarLink = ({ to, text, isInternal, subPages = [] }: NavLinkProps): JSX.Element => {
    const { pathname } = useLocation();
    return isInternal ? (
        <NavLink to={to} style={{ textDecoration: "none" }}>
            {({ isActive: isActiveRoot }): JSX.Element => {
                const pagePathSegment = pathname.split("/")[3];
                const isActive = subPages.some((page) => pagePathSegment === page) || isActiveRoot;
                return (
                    <Box
                        data-id={`navbar-link-${to.replace("/", "-")}`}
                        p="md"
                        pl="xl"
                        sx={(theme): CSSObject => ({
                            background: isActive ? theme.colors.green[0] : "none",
                            color: isActive ? theme.colors.green[5] : theme.black,
                            fontWeight: isActive ? "bold" : "normal",
                        })}
                    >
                        <Text>{text}</Text>
                    </Box>
                );
            }}
        </NavLink>
    ) : (
        <Anchor
            component={Link}
            to={to}
            style={{ textDecoration: "none", color: "inherit" }}
            target="_blank"
        >
            <Box
                p="md"
                pl="xl"
                sx={{
                    background: "none",
                    fontWeight: "normal",
                }}
            >
                <Group position="apart">
                    <Text>{text}</Text>
                    <IconExternalLink size={24} stroke={1.25} />
                </Group>
            </Box>
        </Anchor>
    );
};

type NavMenuProps = {
    title?: string;
    children: ReactNode;
};

export const NavMenu = ({ title, children }: NavMenuProps): JSX.Element => {
    return (
        <Stack spacing={0}>
            {title && (
                <Box>
                    <Text color="flexidaoGreen" p="md" weight="bold">
                        {title}
                    </Text>
                </Box>
            )}
            {children}
        </Stack>
    );
};
