import { BimDto, Module, Page } from "@flexidao/dto";
import { useAppUser } from "./app-user";

export const useModuleVisiblePages = (module: Module): Array<Page> => {
    const displaySettings = useTenantDisplay();
    return displaySettings.modules[module].visibleNavigationPageIds;
};

export const useModuleVisible = (module: Module): boolean => {
    const displaySettings = useTenantDisplay();
    return displaySettings.modules[module].isVisible;
};

export const useTenantDisplay = (): BimDto.TenantDisplay => {
    const { user } = useAppUser();
    if (!user) {
        console.warn(
            "Using useTenantDisplay hook before user has been initialized. display options set as empty.\nWrap your page with UserInitializedComponent",
        );
    }
    return (
        user?.activeTenant.displaySettings ?? {
            homepage: null,
            modules: {
                [Module.CfeInventory]: {
                    isVisible: false,
                    landingPageId: null,
                    visibleNavigationPageIds: [],
                },
                [Module.CfeDiagnostic]: {
                    isVisible: false,
                    landingPageId: null,
                    visibleNavigationPageIds: [],
                },
                [Module.CfeConnect]: {
                    isVisible: false,
                    landingPageId: null,
                    visibleNavigationPageIds: [],
                },
                [Module.Settings]: {
                    isVisible: false,
                    landingPageId: null,
                    visibleNavigationPageIds: [],
                },
            },
        }
    );
};
